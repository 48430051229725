import { Button } from "antd";
import { copyToClipboard } from "../../../../utils/globals";
import { PageRoutes } from "../../../../utils/pageRoutes";

interface Props {
	prId: number;
}
export default function CopyLink({ prId }: Props) {
	return (
		<Button onClick={() => copyToClipboard(`${process.env.REACT_APP_CLIENT_URL}${PageRoutes.goToProject(prId)}`)}>
			Copy Link
		</Button>
	);
}