import { Collapse, CollapseProps } from 'antd';
import AskTava from "./ask-tava"
import { useState } from 'react';
import CurrentResults from './current-results';
import Boosts from './boosts';
import { BoostInsightModel } from '../../../../models/api/boost_insights';
import { AskTavaComponent } from '../../models/props';
import { Panel } from '../../../../models/api/shared';

interface ProjectPanelCollapseProps {
    userData: AskTavaComponent;
    activePanel: Panel;
    onPanelChange: (key: Panel) => void;
    boosts: BoostInsightModel[];
    returnBoosts: (boosts: BoostInsightModel[]) => void;
}

export default function ProjectPanelCollapse({ userData, activePanel, onPanelChange, boosts, returnBoosts }: ProjectPanelCollapseProps) {
    const [newQuestion, setNewQuestion] = useState<string | null>(null);
    function fireNewQuestion(question: string) {
        onPanelChange("Discovery");
        userData.startStream = true;
        setNewQuestion(question)
    }

    const items: CollapseProps['items'] = [
        {
            key: 'Discovery',
            label: 'Discovery',
            children: userData ? <AskTava data={userData} returnBoosts={returnBoosts} newQuestion={newQuestion} /> : null,
            className: "project-pannel"
        },
        {
            key: 'BestSources',
            label: 'Best Sources',
            children: userData ? <CurrentResults data={userData.currentResults} prId={userData.id} /> : null,
            className: "project-pannel"
        },
        {
            key: 'BoostInsight',
            label: 'Boost Insight',
            children: boosts ? <Boosts data={boosts} makeNewQuestion={(e) => { fireNewQuestion(e) }} /> : null,
            className: "project-pannel"
        },
    ];

    return (
        <Collapse
            className="panel-collapse"
            style={{ border: "none", backgroundColor: "white" }}
            items={items}
            defaultActiveKey={['Discovery']}
            activeKey={activePanel}
            onChange={(key) => onPanelChange(key as unknown as Panel)}
        />
    )
};