import { useQuery } from '@tanstack/react-query';
import { callTestBlob } from '../../../../utils/axios-request';
import { Image } from "antd";
//todo.....img auth 
interface ImageWithBearerTokenModel {
	imageId: number;
	imageDate: string;
}

const fetchImage = async (id: number) => {
	const response = await callTestBlob("GET", `${process.env.REACT_APP_SERVER_API_URL}/images/${id}/0/0/${new Date().getTime()}`);
	const blob = await response.blob();
	const reader = new FileReader();
	return new Promise<string>((resolve) => {
		reader.onloadend = () => {
			resolve(reader.result as string);
		};
		reader.readAsDataURL(blob);
	});
};

export default function ImageWithBearerToken({ imageId, imageDate }: ImageWithBearerTokenModel) {
	const { data: imgSrc, isLoading, isError } = useQuery<string, Error>({
		queryKey: ['image-view', `${imageId + imageDate}/80`],
		queryFn: () => fetchImage(imageId),
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week in milliseconds
	});

	if (isError || isLoading) {
		return <Image
			key="123"
			src="/noimage.png"
			style={{ maxWidth: '100%' }}
			preview={false}
		/>;
	}

	return (
		<Image
			key="123"
			src={imgSrc}
			alt="img"
			style={{ maxWidth: '100%' }}
		/>
	);
};
