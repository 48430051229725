import { AiSources } from "../../../models/api/ask-tava";
import { AskQuestionSourceModel, ProjectDigDeepersWrapperModel } from "../../../models/api/shared";

const FormatData = {
	getDomainName(url: string) {
		const regex = new RegExp(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/);
		const match = regex.exec(url);
		const domainName = match?.[1]; // Optional chaining to safely access the property    

		return domainName ? domainName : "";
	},
	formatToAskTavaSources(openAiSources: AskQuestionSourceModel[]): AiSources[] {
		return openAiSources.map(s => {
			return {
				title: s.title,
				url: s.url,
				domain: this.getDomainName(s.url)
			}
		});
	},
	formatToTipicWrapper(topics: string[]): ProjectDigDeepersWrapperModel {
		return { topics: topics.map((t, index) => ({ topic: t, position: index })) }
	},
	formatTags(tags: string[]) {
		let formated = tags.map(v => v.toLowerCase());
		return formated;
	}
}

export default FormatData;
