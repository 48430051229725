import { GPTChatMessage, OpenAiRequestModel, OpenAiResult } from "../models/api/open-ai";

export class OpenAIRequestBuilder {

	static resultToString(model: OpenAiResult): string | null {
		if (model.succeeded && model.content)
			return model.content;
		throw new Error(model.errorMessage);
	}

	static createTagsModel(text: string) {
		let model: OpenAiRequestModel = {
			maxTokens: 100,
			choicesPerPrompt: 1,
			temperature: 0.0,
			frequencyPenalty: 2.0,
			presencePenalty: 1.75,
			messages: [
				{
					id: 0,
					role: "Assistant",
					content: `Avoid using symbols. Provide a numbered list of 5 tags appropriate for the following text: \r\n \r\n${this.getMaxChars(text)}`,
				}
			],
		};

		return model
	}

	static createKeywordsModel(text: string) {
		let model: OpenAiRequestModel = {
			maxTokens: 150,
			choicesPerPrompt: 1,
			temperature: 0.0,
			frequencyPenalty: 2.0,
			presencePenalty: 2.0,
			messages: [
				{
					id: 0,
					role: "Assistant",
					content: `Avoid using symbols. Suggest 4 simple and 4 comprehensive search key phrases about the following topic: \r\n \r\n${this.getMaxChars(text)}`,
				}
			],
		};

		return model;
	}

	//
	static createSummaryModel(text: string, keyword: string) {
		let promptText = "Exclude information about cookie policies, cookie usage, privacy policies, " +
			"advertising, terms of service and then provide a summary of the following text. ";

		if (keyword) promptText += "Make sure to emphasize on content regarding " + keyword;

		promptText += `\r\n\r\n${this.getMaxChars(text)}`;

		let model: OpenAiRequestModel = {
			maxTokens: 500,
			choicesPerPrompt: 1,
			temperature: 0.0,
			frequencyPenalty: 0.5,
			presencePenalty: -0.5,
			messages: [
				{
					id: 0,
					role: "Assistant",
					content: promptText,
				}
			],
		}

		return model;
	}

	static createNoteThemesModel(text: string) {
		let model: OpenAiRequestModel = {
			maxTokens: 300,
			choicesPerPrompt: 1,
			temperature: 0.2,
			frequencyPenalty: 0.75,
			presencePenalty: 1.5,
			messages: [
				{
					id: 0,
					role: "Assistant",
					content: `Provide a few short paragraphs with the main concepts of the following topic: "${this.getMaxChars(text)}"`,
				}
			],
		}

		return model;
	}

	static createSectionSummaryModel(text: string) {
		let model: OpenAiRequestModel = {
			maxTokens: 500,
			choicesPerPrompt: 1,
			temperature: 0.0,
			frequencyPenalty: 0.5,
			presencePenalty: -0.5,
			messages: [
				{
					id: 0,
					role: "Assistant",
					content: `Summarize the following text: ${this.getMaxChars(text)}`,
				}
			],
		};

		return model;
	}

	static suggestDiscoveryNameModel(text: string) {
		let model: OpenAiRequestModel = {
			maxTokens: 200,
			choicesPerPrompt: 1,
			temperature: 0.0,
			frequencyPenalty: 1.6,
			presencePenalty: -1.25,
			messages: [
				{
					id: 0,
					role: "Assistant",
					content: "Do not use quotes or quotation marks. Use short sentences."
						+ `Provide a list of 4 general topics based on the following keywords.  \r\n${this.getMaxChars(text)}`,
				}
			],
		};

		return model;
	}

	static suggestSectionNames(keyword: string) {
		let txt = "Do not use quotes or quotation marks."
			+ `Provide a list of 5 general topic based on the following keywords.  \r\n${keyword}.`;
		//if (keyword) txt += `Make sure to emphasize on content regarding ${discoveryName}.`;
		let model: OpenAiRequestModel = {
			//assistantMessage: txt,
			maxTokens: 100,
			choicesPerPrompt: 1,
			temperature: 0.0,
			frequencyPenalty: 1.6,
			presencePenalty: -1.25,
			messages: [
				{
					id: 0,
					role: "Assistant",
					content: txt,
				}
			],
		};

		return model;
	}
	//"temperature": 0.5,
	//"top_p": 0.95,
	//"frequency_penalty": 0,
	//"presence_penalty": 0,
	//"max_tokens": 800,
	//"stop": null
	static askTava() {
		let messages: GPTChatMessage[] = [{
			id: 0,
			role: "System",
			content: this.askTavaSystemMsg()
		}];
		let model: OpenAiRequestModel = {
			messages: messages,
			maxTokens: 4000,
			temperature: 0.9,
			//presencePenalty: 1,
			frequencyPenalty: 0 //DON'T USE!!!
		};
		return model;
	}

	static askTavaSystemMsg() {
		return `
		You are a helpful assistant. When responding, structure your response as only strict JSON with the following keys: 'aiResponse', 'five_tags', 'five_topics', 'five_sources'.
		-'aiResponse' should be your response to the user’s query. It should be structured into a maximum of 6 paragraphs, each separated with <div></div>.
		-'five_tags' is a string array. This array can contain up to a maximum of five relevant tags (if they exist). Each tag in this array is a string representing a relevant tag.
		You can add up to 5 such strings in the 'five_tags' array. If there are fewer than 5 tags, you can include fewer strings. If there are no tags, 'five_tags' can be an empty array.
		-'five_topics'  is a string array. This array can contain up to a maximum of five relevant topics (in 5-6 words). Each topic in this array is a string representing a relevant topic.
		You can add up to 5 such strings in the 'five_topics' array. If there are fewer than 5 topics, you can include fewer strings. If there are no topics, 'five_topics' can be an empty array.
		-'five_sources'  is an array. This array can contain up to a maximum of five relevant sources (if they exist).Each source in this array is an object that contains three properties:
		'url': The URL of the source.
		'title': The title of the source.
		'domain': The domain of the source.
		Please replace <URL of the source>, <Title of the source>, and <Domain of the source> with actual values. You can add up to 5 such objects in the 'five_sources' array. If there are fewer than 5 sources, you can include fewer objects. If there are no sources, 'five_sources' can be an empty array.

		Here's an example of your output format:
  "aiResponse": "<aiResponse>",
  "five_tags": [
	  "<tag>"
  ],
  "five_topics": [
	  "<topic>"
  ],
  "five_sources": [
    {
      "url": "<URL of the source>" ,
	  "title": "<Title of the source>" ,
	  "domain": "<Domain of the source>"
    }
  ]


   If you are not able to provide response, not sure or don't know, without any comment return ONLY this JSON: {
	   "aiResponse": "I'm sorry, but I don't understand what you are asking for. Could you please provide a valid question for me to search information about?",
	   "five_tags":[],
	   "five_topics":[],
	   "five_sources":[]
   }
]
`;
	}

	static suggestSpecifiedNumberKeywordsModel(keywords: string, suggestNumber: number) {
		let model: OpenAiRequestModel = {
			maxTokens: 150,
			choicesPerPrompt: 1,
			temperature: 0.5,
			frequencyPenalty: 2.0,
			presencePenalty: 2.0,
			messages: [
				{ //based on the following keywords //about the following topic
					id: 0,
					role: "Assistant",
					content: `Avoid using symbols or quotes. Suggest ${suggestNumber} simple search key phrases based on the following keywords: \r\n \r\n${this.getMaxChars(keywords)}`,
				}
			],
		};

		return model;
	}

	static suggestTopicsFromHtmlModel(text: string) {
		let model: OpenAiRequestModel = {
			choicesPerPrompt: 1,
			temperature: 0.2,
			frequencyPenalty: 2.0,
			presencePenalty: 1.75,
			messages: [
				{
					id: 0,
					role: "Assistant",
					content: `Do not use quotes. Suggest 5 simple search key phrases based on the following text: \r\n \r\n${this.getMaxChars(text)}`,
				}
			],
		};

		return model
	}

	static relatedTopicQuestionsModel(topics: string,) {
		let promptText = `From these topics: ${topics}.
			Synthesize the related topics and present 7 new related research topics.
			Then rephrase these 7 new topics in the form of questions. Do not use quotes.
			Without any comment Return only these new questions.`;

		let model: OpenAiRequestModel = {
			maxTokens: 4000,
			choicesPerPrompt: 1,
			temperature: 1,
			frequencyPenalty: 0.5,
			presencePenalty: -0.5,
			messages: [
				{
					id: 0,
					role: "Assistant",
					content: promptText,
				}
			],
		}

		return model;
	}

	private static getMaxChars(str: string) {
		return str;// todo...: globalHelper.format.getFirstNchars(str, 7000);
	}

	private static splitRegex(text: string, forTags: boolean): string[] {
		const regex = /\d.\s*(.+)/g; // /(\d+)\.\s(.+?)(\r?\n|$)/g;
		const result: string[] = [];
		let match: RegExpExecArray | null;

		while ((match = regex.exec(text)) !== null) {
			if (forTags) {
				result.push(match[1].toLowerCase().trim());
			} else {
				result.push(match[1].trim());
			}
		}

		return result;
	}

	static formatBoosts(text: string) {
		return this.splitRegex(text, false);
	}

	static formatTags(text: string) {
		return this.splitRegex(text, true);
	}

	static formatKeywords(text: string) {
		return this.splitRegex(text, false);
	}

	static formatDiscName(text: string) {
		return this.splitRegex(text, false);
	}

	static formatSectionNames(text: string) {
		return this.splitRegex(text, false);
	}

	//todo fix:

	//formatAskTavaModel(str: string): App.OpenAi.Models.AskTava.Model {
	//	let aiResponse: App.OpenAi.Models.AskTava.Model = {
	//		aiResponse: "<div>I'm sorry, but I don't understand what you are asking for. Could you please provide a valid question for me to search information about?</div>",
	//		sources: [],
	//		tags: [],
	//		topics: []
	//	};

	//	try {
	//		//.replace(/\\/g,'\\\\')
	//		//.replace(/"/g,'\\"')
	//		str = str.replace(/(\r\n|\n|\r)/gm, " ");
	//		let response: App.OpenAi.Models.AskTava.Model = JSON.parse(str);

	//		if (typeof response.aiResponse === 'string')
	//			aiResponse.aiResponse = response.aiResponse

	//		if (Array.isArray(response.sources)) {
	//			aiResponse.sources = response.sources;
	//		}

	//		if (Array.isArray(response.topics)) {
	//			aiResponse.topics = response.topics;
	//		}

	//		if (Array.isArray(response.tags)) {
	//			aiResponse.tags = response.tags.map(t => {
	//				return t.toLowerCase();
	//			});
	//		}

	//		return aiResponse;


	//	} catch (e) {
	//		return aiResponse
	//	}
	//}
}
