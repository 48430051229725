import { ProjectAccessModes } from "../../../../models/api/shared";

interface PopoverProps {
	name: string;
	email: string;
	accessMode: ProjectAccessModes;
}

export default function PopoverContent({ name, email, accessMode }: PopoverProps) {
	return (
		<div style={{ display: "flex", flexDirection: "row" }}>
			<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<div
					className="ficon ficon-user"
					style={{ fontSize: "3rem", backgroundColor: "#ddd", padding: "0.25rem 0.25rem 0 0.25rem" }}
				></div>
			</div>
			<div style={{ display: "flex", flexDirection: "column", marginLeft: "0.5rem" }}>
				<p className="m-0 p-0" style={{ fontSize: "0.95rem" }}>{name}</p>
				<p className="m-0 p-0" style={{ color: "gray", fontSize: "0.75rem" }}>{email}</p>
				<p
					style={{
						margin: "0",
						padding: "0",
						fontSize: "0.75rem",
						color: "white",
						width: "70px",
						borderRadius: "0.25rem",
						textAlign: "center",
						backgroundColor: accessMode === "Owner" ? "darkgreen" : accessMode === "Contributor" ? "darkorange" : "blue",
					}}
				>
					{accessMode}
				</p>
			</div>
		</div>
	);
}