import { Button, Spin, message, } from "antd";
import Modal from "antd/es/modal/Modal";
import { errorHandler } from "../../../utils/globals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiRequests from "../../../utils/api-requests";
import { useState } from "react";

interface Props {
	licenseName: string
	onClose: () => void;
}
export default function CancelLincese({
	licenseName,
	onClose,
}: Props) {
	const queryClient = useQueryClient();
	const [loading, setLoading] = useState(false);

	const mutation = useMutation({
		mutationFn: () => ApiRequests.Services.cancel(),
		onSuccess: (service) => {
			queryClient.invalidateQueries({ queryKey: ['services-my-license'] });
			message.success(`License ${licenseName} canceled successfully!`, 15);
			setLoading(false);
			onClose()
		},
		onError: (err) => {
			setLoading(false);
			message.error(errorHandler(err), 15);
		}
	});

	const fireCancel = () => {
		setLoading(true);
		mutation.mutate();


	};


	return (

		<>
			<Modal
				open={true}
				onCancel={onClose}
				maskClosable={false}
				destroyOnClose
				width={800}
				title={`Cancel ${licenseName} License!`}
				footer={[
					<Button
						onClick={() => fireCancel()}
						type="primary"
					>
						Ok
					</Button>,
					<Button key="cancel" onClick={onClose}>
						Cancel
					</Button>,
				]}
			>
				<Spin className='full-page-spinner' spinning={loading}>
					Are you sure you want to cancel {licenseName} license?
				</Spin>
			</Modal >


		</>
	);
}
