import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { jwtDecode } from "jwt-decode";
import { TokenClaims } from "../models";
import { errorHandler } from "./globals";

export async function getGroups(instance: IPublicClientApplication): Promise<string[]> {
	try {
		const accounts = instance.getActiveAccount();
		if (!accounts) {
			throw new Error("No account found");
		}

		const response = await instance.acquireTokenSilent({
			...loginRequest,
			account: accounts,
		});

		const accessToken = response.accessToken;
		if (accessToken) {
			const decodedToken = jwtDecode<TokenClaims>(accessToken);
			return decodedToken.groups || [];
		}
		return [];
	} catch (error) {
		console.error(errorHandler("Error aquiring token " + error));
		return [];
	}
}

export const checkAdminStatus = async (instance: IPublicClientApplication, setIsAdmin: (isAdmin: boolean) => void): Promise<void> => {
	const groups = await getGroups(instance);
	const adminGroupId = process.env.REACT_APP_ADMIN_GROUP_ID as string;
	setIsAdmin(groups.includes(adminGroupId));
};