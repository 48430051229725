import { Alert, Button, Col, Divider, Form, Radio, RadioChangeEvent, Row, Spin, Table, message, } from "antd";
import Modal from "antd/es/modal/Modal";
import { errorHandler, toCurrency, toDate, toStorage } from "../../../utils/globals";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApiRequests from "../../../utils/api-requests";
import { useState } from "react";
import { PaymentMethods, PaymentPeriodTypes, ServiceTypes } from "../../../models/api/shared";
import { PurchaseServiceCreateModel } from "../../../models/api/purchase";

interface Props {
	sessionId: string;
	catalogId: number;
	onClose: () => void;
}

interface IActiveOrderModel {
	id: number,
	rowVersion: string,
}

interface IItemModel {
	type?: string;
	storageText?: string;
	priceAsText?: string;
	isNew: boolean,
	position: number,
	questions: number,
	storage: number,
	isUpgrade: boolean,
	price: number,
	serviceCatalogName?: string,
}

interface IConditionsModel {
	period?: string;
	startRecurringDateText?: string;
	instantPaymentAsText?: string;
	instantDateText?: string;
	instantDate: string,
	instantPayment: number,
	startRecurringDate: string,
}

interface IServiceModel {
	id: string,
	residualValue: number,
	rowVersion: string,
}

interface PIPeriodModel {
	checked?: boolean;
	id: PaymentPeriodTypes,
	paymentPeriodName: string,
	enabled: boolean,
	price: number,
}

interface IPopupModel {
	total?: {
		storage: string;
		questions: number;
		priceAsText: string;
	};
	priceAsText?: string;
	catalogueId: number,
	catalogueName: string,
	provider: PaymentMethods,
	period: PaymentPeriodTypes,
	serviceType: ServiceTypes,
	price: number,
	activeOrder?: IActiveOrderModel,
	items: IItemModel[],
	conditions: IConditionsModel,
	service?: IServiceModel,
	providers: PaymentMethods[]
	periods: PIPeriodModel[],
	serviceTypeName: string,
}
interface FormValues {
	radioPeriod: PaymentPeriodTypes, radioProvider: PaymentMethods
}

function formatData(data: IPopupModel): IPopupModel {

	var totalStorage = 0, totalQuestions = 0;
	data.conditions.instantDateText = toDate(data.conditions.instantDate);
	data.conditions.instantPaymentAsText = toCurrency(data.conditions.instantPayment);
	data.conditions.startRecurringDateText = toDate(data.conditions.startRecurringDate);
	data.conditions.period = data.period === "Month" ? 'Monthly' : 'Annualy';

	for (var i = 0; i < data.items.length; i++) {
		totalStorage += data.items[i].storage;
		totalQuestions += data.items[i].questions
		data.items[i].priceAsText = toCurrency(data.items[i].price);

		data.items[i].storageText = toStorage(data.items[i].storage);
		data.items[i].type = data.items[i].isUpgrade ? data.items[i].serviceCatalogName
			+ " - Upgrade" : data.items[i].serviceCatalogName;
	}

	data.periods.forEach(x => x.checked = x.id === data.period);
	data.priceAsText = toCurrency(data.price);

	data.total = {
		storage: toStorage(totalStorage),
		questions: totalQuestions,
		priceAsText: data.priceAsText
	}

	return data;
}

export default function BuyLincese({
	sessionId,
	catalogId,
	onClose,
}: Props) {
	const queryClient = useQueryClient();
	const [form] = Form.useForm<FormValues>();
	const [loading, setLoading] = useState(false);


	const purchaseData = useQuery({
		queryKey: ['purchase-catalogue'],
		queryFn: () => ApiRequests.Purchase.start(sessionId, catalogId),
		select: (data) => {
			let newData: IPopupModel = formatData(data)

			return newData;
		},
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
	});

	const columns = [
		{
			title: "Type",
			dataIndex: "serviceCatalogName",
			key: "serviceCatalogName",
			width: 1000,
			render: (name: string) => {
				return <span>
					{name}
				</span>
			},

		},
		{
			title: "Storage",
			dataIndex: "storageText",
			key: "storageText",
			width: 200,
			render: (storage: number) => {
				return <>{storage}</>
			},
		},
		{
			title: "Questions",
			dataIndex: "questions",
			key: "questions",
			width: 200,
			render: (questions: number) => {
				return <>{questions}</>
			},
		},
		{
			title: "Price",
			dataIndex: "priceAsText",
			key: "priceAsText",
			width: 200,
			render: (price: number) => {
				return <>{price}</>
			},
		}
	];
	const onFinish = (values: FormValues) => {

		if (purchaseData.data) {
			setLoading(true);
			var model = {
				paymentPeriod: values.radioPeriod,
				paymentProvider: values.radioProvider,
				price: purchaseData.data.price,
				instantPayment: purchaseData.data.conditions.instantPayment
			}

			ApiRequests.Purchase.finalize(sessionId, model)
				.then(function (url) {
					if (values.radioProvider === 'PayPal')
						window.location.href = url;
					else {
						setLoading(false);
						message.success(`Your order #${url} has been placed. Please, specify your Order #${url} in your bank transfer or check details.`, 20)
						queryClient.invalidateQueries({ queryKey: ['services-my-license'] });
						onClose();
					}

				}).catch(function (err) {
					setLoading(false);
					message.error(errorHandler(err), 15);
				});
		}

	};

	const mutation = useMutation({
		mutationFn: (model: PurchaseServiceCreateModel) => ApiRequests.Purchase.calculate(sessionId, model),
		onSuccess: (data) => {
			queryClient.setQueryData(['purchase-catalogue'], formatData(data));
			setLoading(false);
		},
		onError: (err) => {
			setLoading(false);
			message.error(errorHandler(err), 15);
		}
	});

	const onChangePeriod = (e: RadioChangeEvent) => {
		if (purchaseData.data) {
			setLoading(true);
			var model = {
				paymentPeriod: e.target.value,
				paymentProvider: purchaseData.data.provider,
				price: purchaseData.data.price,
				instantPayment: purchaseData.data.conditions.instantPayment
			}
			mutation.mutate(model);
		}

	};

	return (

		<>
			<Modal
				open={!purchaseData.isFetching}
				onCancel={onClose}
				maskClosable={false}
				destroyOnClose
				width={800}
				title={`Buy License`}
				footer={[
					<Button
						key="submit"
						form="buy-license-form"
						htmlType="submit"
						type="primary"
					>
						Order
					</Button>,
					<Button key="cancel" onClick={onClose}>
						Cancel
					</Button>,
				]}
			>
				{purchaseData.error ? (
					<Alert
						message="Error"
						description={errorHandler(purchaseData.error.message)}
						type="error"
						showIcon
					/>
				) : (
					<>
						<Spin className='full-page-spinner' spinning={purchaseData.isFetching || loading}>
							<Form form={form}
								id="buy-license-form"
									onFinish={onFinish}
									initialValues={{ radioPeriod: purchaseData.data?.period, radioProvider: purchaseData.data?.provider } as FormValues} >
								<Form.Item name="radioPeriod" label="Subscription period:" rules={[{ required: true, message: 'Please select an option!' }]}>
									<Radio.Group
										onChange={(e: RadioChangeEvent) => {
											onChangePeriod(e);
										}}

									>
										{purchaseData.data?.periods.map(item => (
											<Radio key={item.id} value={item.id} disabled={!item.enabled}>{item.paymentPeriodName}</Radio>
										))}
									</Radio.Group>

								</Form.Item>
								<Divider />
								<Form.Item name="radioProvider" label="Payment Method:" rules={[{ required: true, message: 'Please select an option!' }]}>
									<Radio.Group>
										{purchaseData.data?.providers.map(item => (
											<Radio key={item} value={item}>
												{item === 'PayPal' ?
													'Credit Card / PayPal' :
													'Bank wire/Check (Include your order number within the payment method.)'}</Radio>
										))}
									</Radio.Group>
								</Form.Item>

							</Form>

							<h3>Items</h3>

							<Table
								locale={{ emptyText: 'No Licenses' }}
								columns={columns}
								dataSource={purchaseData.data?.items || []}
								loading={purchaseData.isFetching}
								pagination={false}
								rowKey={(record) => record.position}
								summary={() => (
									<Table.Summary.Row>
										<Table.Summary.Cell align={'right'} index={0}>
											<span style={{ fontWeight: 'bold' }}>Total:</span>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={1}>
											<span style={{ fontWeight: 'bold' }}>{purchaseData.data?.total?.storage}</span>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={2}>
											<span style={{ fontWeight: 'bold' }}>{purchaseData.data?.total?.questions}</span>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={3}>
											<span style={{ fontWeight: 'bold' }}>{purchaseData.data?.total?.priceAsText}</span>
										</Table.Summary.Cell>
									</Table.Summary.Row>
								)}
							/>


							{purchaseData.data ?
								<div className="mt-20">

									<Row gutter={[12, 12]}>
										<Col span={6}></Col>
										<Col span={6}>
											<div className="box-item text-bold">Initial Order Payment</div>
										</Col>
										<Col span={6}>
											<div className="box-item text-right">{purchaseData.data?.conditions.instantDateText}</div>
										</Col>

										<Col span={6}>
											<div className="box-item text-bold text-right">{purchaseData.data?.conditions.instantPaymentAsText}</div>
										</Col>

										<Col span={6}></Col>
										<Col span={6}>
											<div className="box-item text-bold">{purchaseData.data?.conditions.period} payment from:</div>
										</Col>
										<Col span={6}>
											<div className="box-item text-right">{purchaseData.data?.conditions.startRecurringDateText}</div>
										</Col>

										<Col span={6}>
											<div className="box-item text-bold text-right">{purchaseData.data?.priceAsText}</div>
										</Col>
									</Row>

								</div> : null
							}
						</Spin>
					</>
				)}
			</Modal >


		</>
	);
}
