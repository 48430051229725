import { ColumnsType } from "antd/es/table";
import { AdminOrdersResultModel } from "../../../models/api/orders";
import { toCurrency, toDate } from "../../../utils/globals";
import { Popconfirm, Tooltip } from "antd";

interface Props {
	handleViewOrderClick: (orderId: number) => void;
	handleCancelOrderClick: (orderId: number) => void;
}

const OrdersTableColumns = ({ handleViewOrderClick, handleCancelOrderClick }: Props): ColumnsType<AdminOrdersResultModel> => {
	return [
		{
			title: "Order #",
			dataIndex: "orderId",
			key: "orderId",
		},
		{
			title: "Date",
			dataIndex: "orderDate",
			key: "orderDate",
			render: (date: string) => <span>{toDate(date)}</span>
		},
		{
			title: "Service Type",
			dataIndex: "serviceType",
			key: "serviceType",
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
			render: (price: number) => (<span>{toCurrency(price)}</span>)
		},
		{
			title: "Status",
			dataIndex: "statusDisplayName",
			key: "statusDisplayName",
		},
		{
			title: "",
			dataIndex: "actionColumn",
			key: "actionColumn",
			render: (_, record: AdminOrdersResultModel) => (
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Tooltip title="View Order">
						<span
							className="ficon ficon-eye"
							style={{ marginRight: "1rem", fontSize: "1.5rem", cursor: "pointer" }}
							onClick={() => handleViewOrderClick(record.orderId)}
						/>
					</Tooltip>
					<Tooltip title="Cancel Order">
						<Popconfirm
							title={"Are you sure you want to cancel the order?"}
							onConfirm={() => handleCancelOrderClick(record.orderId)}
						>
							<span
								className="ficon ficon-close"
								style={{ fontSize: "1.5rem", cursor: "pointer" }}
							/>
						</Popconfirm>
					</Tooltip>
				</div>
			)
		}
	]
}
export default OrdersTableColumns;