import { TableProps, message } from "antd";
import { useContext, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ApiRequests from "../../../../../utils/api-requests";
import PersonalGridTableColumns from "./TableColumns";
import DeleteModal from "../../../components/Modals/DeleteModal";
import ArchiveModal from "../../../components/Modals/ArchiveModal";
import { ProjectsGridResultModel, ProjectsGridSortColumns } from "../../../../../models/api/projects/projects";
import { SortInfo, SortTypes } from "../../../../../models/api/shared";
import Table from "../../../../../components/antd-extensions/ExtendedTable";
import { errorHandler, handleTableSort } from "../../../../../utils/globals";
import { MobileContext } from "../../../../../components/PageLayout";
import ProjectsTabs from "../ProjectsTabs";
import PersonalList from "./PersonalList";
import StickyHelp from "../../../../../components/StyckyHelp";
import { PageRoutes } from "../../../../../utils/pageRoutes";

export default function Personal() {
	const [sortInfo, setSortInfo] = useState<SortInfo<ProjectsGridSortColumns>>({
		sortColumn: "ModifiedAt",
		sortType: SortTypes.Desc,
	});

	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [archiveModalVisible, setArchiveModalVisible] = useState(false);
	const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);

	const isMobile = useContext(MobileContext);
	const [drawerVisible, setDrawerVisible] = useState(false);
	const helpContent: React.ReactNode = (
		<div key="sticky-help-btn-content">
			These are your active <b>research projects</b>. To remove a project from your list, choose either <b>'Archive'</b> or <b>'Delete'</b>.
		</div>
	);

	const queryClient = useQueryClient();

	const projectsListQuery = useQuery({
		queryKey: ["projectsList", sortInfo],
		queryFn: () =>
			ApiRequests.Project.personalList({
				SortColumn: sortInfo.sortColumn,
				SortType: sortInfo.sortType,
				GridType: "Personal",
			}),
	});

	if (projectsListQuery.isError) {
		message.error(errorHandler(projectsListQuery.error.message));
	}

	const handleTableChange: TableProps<ProjectsGridResultModel>["onChange"] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const openArchiveModal = (id: number) => {
		setSelectedRecordId(id);
		setArchiveModalVisible(true);
	};

	const openDeleteModal = (id: number) => {
		setSelectedRecordId(id);
		setDeleteModalVisible(true);
	};

	const handleMutationSuccess = () => {
		queryClient.invalidateQueries({ queryKey: ["projectsList"] });
		setDrawerVisible(false);
		setSelectedRecordId(null);
	};

	const columns = PersonalGridTableColumns({ openArchiveModal, openDeleteModal, sortInfo });

	return (
		<>
			<ProjectsTabs activeTab={"Personal"} />
			{isMobile ? (
				<PersonalList
					query={projectsListQuery}
					openArchiveModal={openArchiveModal}
					openDeleteModal={openDeleteModal}
					drawerVisible={drawerVisible}
					setDrawerVisible={setDrawerVisible}
					setSelectedRecordId={setSelectedRecordId}
					selectedRecordId={selectedRecordId}
				/>
			) : (
				<Table
					columns={columns}
					dataSource={projectsListQuery.data?.records}
					pagination={false}
					onChange={handleTableChange}
					loading={projectsListQuery.isFetching}
					rowKey="id"
				/>
			)}
			{selectedRecordId && (
				<>
					<DeleteModal
						modalVisible={deleteModalVisible}
						setModalVisible={setDeleteModalVisible}
						recordId={selectedRecordId}
						handleMutationSuccess={handleMutationSuccess}
					/>
					<ArchiveModal
						modalVisible={archiveModalVisible}
						setModalVisible={setArchiveModalVisible}
						recordId={selectedRecordId}
						handleMutationSuccess={handleMutationSuccess}
					/>
				</>
			)}
			<StickyHelp goToHelpLink={PageRoutes.Overview()} popContent={helpContent} title={"Personal Projects"}></StickyHelp>
		</>
	);
}
