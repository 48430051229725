import { Button, Modal, Spin } from "antd";
import Title from "antd/es/typography/Title";
import { useArchvieProjectMutation } from "../../hooks/useArchvieProjectMutation";

interface Props {
	modalVisible: boolean;
	setModalVisible: (value: boolean) => void;
	recordId: number;
	handleMutationSuccess: () => void;
}

export default function ArchiveModal({
	modalVisible,
	setModalVisible,
	recordId,
	handleMutationSuccess,
}: Props) {

	const archiveMutation = useArchvieProjectMutation({
		onSuccess: handleMutationSuccess
	});

	const handleConfirmArchive = () => {
		const mutationModel = { Items: [recordId], Archive: true };
		archiveMutation.mutate(mutationModel);
		setModalVisible(false);
	};

	return (
		<>
			{archiveMutation.isPending && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        zIndex: 1000,
                    }}
                >
                    <Spin size="large" />
                </div>
			)}
			<Modal
				title=""
				open={modalVisible}
				onCancel={() => setModalVisible(false)}
				footer={null}
			>
				<div style={{ textAlign: "center" }}>
					<Title level={4}>Are you sure you want to archive this project?</Title>
					<Button type="primary" onClick={handleConfirmArchive}>
						Archive
					</Button>
					<Button
						style={{ marginLeft: "10px" }}
						onClick={() => setModalVisible(false)}
					>
						Cancel
					</Button>
				</div>
			</Modal>
		</>
		
	);
}
