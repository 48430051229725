import { useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, Divider, Spin, message } from 'antd';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from "../../../authConfig";
import ApiRequests from '../../../utils/api-requests';
import PanelButtons from '../components/PanelButtons';
import ProjectDocuments from '../docs/components/ProjectDocuments';
import { PageRoutes } from '../../../utils/pageRoutes';
import { errorHandler, getInitials, newLineToBr, toDateTime } from '../../../utils/globals';
import { Panel } from '../../../models/api/shared';
import ExportToDocument from '../notes/components/ExportToDocument';
import { ProjectNoteComponentListModel } from '../notes/components/ProjectNotes';
import ProjectHeader from '../components/ProjectHeader';
import useAddProjectToHistory from '../hooks/useAddProjectToHistory';
import StickyHelp from '../../../components/StyckyHelp';

const Docs = () => {
	const authRequest = {
		...loginRequest,
	};
	const navigate = useNavigate();
	const querryClient = useQueryClient();
	const [createDocNotes, setCreateDocNotes] = useState<ProjectNoteComponentListModel[] | null>(null);
	const [loadSpinnerForNotes, setLoadSpinnerForNotes] = useState<boolean>(false);
	const params = useParams<{ id: string }>();
	const incorrectProject = !params.id || !/^[0-9]*$/.test(params.id);
	const [projectId] = useState(parseInt(params.id!));
	const [activePanel] = useState<Panel>('Documents');
	const helpContent: React.ReactNode = (
		<div key="sticky-help-btn-content">
			Contains all <b>documents</b> generated for a project. Start with <b>'Create Document'</b> in Documents or Notes. Select notes to include and enhance with TAVA templates. Use 'Your Selected Notes' template to create without changes. Comment and collaborate with your team. Create additional notes from documents.
		</div>
	);
	const querryKey = ['api-project-docs', params.id];


	const projectDataQuery = useQuery({
		queryKey: querryKey,
		queryFn: async () => ApiRequests.Project.header(projectId),
		enabled: !incorrectProject,
		retry: false,
		refetchOnMount: true,
		refetchOnWindowFocus: false
	});

	useAddProjectToHistory(projectDataQuery.data);

	const handlePanelChange = useCallback((key: Panel) => {
		if (key === 'Notes')
			navigate(PageRoutes.goToProjectNotes(projectId));
		else if (key !== "Documents") navigate(PageRoutes.goToProject(projectId, key));

	}, [navigate, projectId]);

	if (incorrectProject) {
		return (
			<Alert
				message="Error"
				description="This project does not exist!"
				type="error"
				showIcon
			/>
		);
	}

	const handleExportShow = (prId: number) => {
		setLoadSpinnerForNotes(true);
		ApiRequests.Project.Notes.grid(prId).then((data) => {
			let notes: ProjectNoteComponentListModel[] = [...data.notes];
			notes.map((n) => {
				n.modifier.modifiedAt = toDateTime(n.modifier.modifiedAt);
				n.initials = getInitials(n.modifier.name);
				n.checked = false;
				n.readingMore = false;
				n.text = newLineToBr(n.text)
				return n
			});

			setCreateDocNotes(notes);
		}).catch((err) => {
			message.error(errorHandler(err), 15);
		}).then(() => {
			setLoadSpinnerForNotes(false);
		})

	}

	const handleCloseExport = () => {
		setCreateDocNotes(null)
	}

	const handleGoToDocumentsTab = () => {
		setCreateDocNotes(null)
	}

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest as any}
		>
			<Spin className='full-page-spinner' spinning={projectDataQuery.isFetching || loadSpinnerForNotes}>
				{projectDataQuery.error ? (
					<Alert
						message="Error"
						description={errorHandler(projectDataQuery.error)}
						type="error"
						showIcon
					/>
				) : null}
				{!incorrectProject && projectDataQuery.data && !projectDataQuery.isFetching ? (
					<>
						<ProjectHeader
							projectTitle={projectDataQuery.data.title}
							sharingInfo={projectDataQuery.data.sharing}
							id={projectId}
							onTitleUpdated={(newTitle) => {
								const newData = { ...projectDataQuery.data, title: newTitle };
								querryClient.setQueryData(querryKey, newData);
							}}
						/>

						<PanelButtons activePanel={activePanel} onPanelChange={handlePanelChange} />
						{!createDocNotes ?
							<>
								<div key="create-doc-btn" className="fbox vcbox force-link" onClick={() => handleExportShow(projectDataQuery.data.sharing.parentId)} >
									<span className="ficon ficon-plus-circle mr-05"></span>
									<span>Create Document</span>
								</div>

								<Divider />
								<ProjectDocuments
									prAccess={projectDataQuery.data.sharing.accessMode}
									projectId={projectDataQuery.data.sharing.parentId} />
							</>
							: <ExportToDocument
								key="export-to-doc"
								data={{
									notes: createDocNotes,
									prId: projectDataQuery.data.sharing.parentId,
									prName: projectDataQuery.data.title
								}}
								handleClose={handleCloseExport}
								handleCreateDocument={handleGoToDocumentsTab}
							/>}
						<StickyHelp goToHelpLink={PageRoutes.DocumentsHelp()} popContent={helpContent} title={"Documents"}></StickyHelp>
					</>
				) : null}

			</Spin>
		</MsalAuthenticationTemplate>
	);
};

export default Docs;
