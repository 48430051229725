import { useState, useEffect, useCallback } from "react";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import {
	getComments as getCommentsApi,
	createComment as createCommentApi,
	createReply as createReplyApi,
	updateComment as updateCommentApi,
	updateReply as updateReplyApi,
	deleteComment as deleteCommentApi,
	deleteCommentReply as deleteCommentReplyApi,
	apiMarkAsRead
} from "./api";
import { CommentReadBaseModel, ProjectAccessModes } from "../../../../models/api/shared";
import { CommentCreateModel, CommentMarkAsReadModel, CommentsWrapperModel, ICommentsController } from "../../../../models/api/comments";
import { errorHandler } from "../../../../utils/globals";
import { message, Spin } from "antd";

export interface CommentComponentModel extends CommentReadBaseModel {
	parentId?: number
}

interface CommentsProps {
	commentType: ICommentsController;
	currentUserId: string;
	itemId: number;
	prId: number;
	prAccess: ProjectAccessModes
	refreshCounter: (itemId: number, counter: number) => void;
}

export interface CommentActiveModel {
	id: number,
	type: string,
	parentId: number | undefined
}

const Comments: React.FC<CommentsProps> = ({ commentType, itemId,
	currentUserId, prId, prAccess, refreshCounter }) => {
	const [backendComments, setBackendComments] = useState<CommentComponentModel[]>([]);
	const [activeComment, setActiveComment] = useState<CommentActiveModel | null>(null);
	const [focusedCommentId, setFocusedCommentId] = useState<number | null>(null);
	const [loader, setLoader] = useState<boolean>(false);

	const rootComments = backendComments.filter(
		(el) => el.parentId === undefined
	);

	const getReplies = (focusedElId: number) =>
		[...backendComments]
			.filter((backendComment) => backendComment.parentId === focusedElId)
			.sort(
				(a, b) =>
					new Date(a.owner.createdAt).getTime() - new Date(b.owner.createdAt).getTime()
			);

	const refreshComments = useCallback((newCommentId?: number) => {
		function markAsRead(response: CommentsWrapperModel) {
			const model: CommentMarkAsReadModel = {
				comments: response.comments.map((c) => c.commentId),
				replies: response.replies.map(r => r.replyId)
			}
			
			apiMarkAsRead(commentType, prId, itemId, model)
				.then(() => {
					refreshCounter(itemId,
						model.comments.length 
						+ model.replies.length)

				}).finally(() => {
					if (newCommentId)
						setFocusedCommentId(newCommentId);
				})
		}
		setLoader(true);
		getCommentsApi(commentType, prId, itemId)
			.then((data) => {
				const combinedArray: CommentComponentModel[] = [
					...data.comments,
					...data.replies.map(reply => ({
						parentId: reply.commentId,
						commentId: reply.replyId,
						text: reply.text,
						modifier: reply.modifier,
						owner: reply.owner
					}))
				];
				setBackendComments([...combinedArray]);
				markAsRead({ ...data });
				
			}).catch((err) => {			
				message.error(errorHandler(err), 15);
			}).finally(() => {
				setLoader(false);
			});
	}, [commentType, prId, itemId, refreshCounter]);

	const addComment = (text: string, parentId?: number | null) => {
		const model: CommentCreateModel = {
			contacts: [],
			text: text
		};

		(parentId ?
			createReplyApi(commentType, prId, itemId, parentId, model) :
			createCommentApi(commentType, prId, itemId, model)
		).then((newCommentId) => {
			setActiveComment(null);
			refreshComments(newCommentId);
		}).catch((err) => {
			message.error(errorHandler(err), 15);
		});
	};

	const updateComment = (text: string, commentId: number, parentId?: number | undefined) => {
		const model: CommentCreateModel = {
			contacts: [],
			text: text
		};

		(parentId ?
			updateReplyApi(commentType, prId, itemId, parentId, commentId, model) :
			updateCommentApi(commentType, prId, itemId, commentId, model)
		).then(() => {
			setActiveComment(null);
			refreshComments(commentId);
		}).catch((err) => {
			message.error(errorHandler(err), 15);
		});
	};

	const deleteComment = (commentId: number, parentId?: number | undefined) => {
		(!parentId ? deleteCommentApi(commentType, prId, itemId, commentId) :
			deleteCommentReplyApi(commentType, prId, itemId, parentId, commentId)
		).then(() => {
			setActiveComment(null);
			setFocusedCommentId(null);
			refreshComments();
		}).catch((err) => {
			message.error(errorHandler(err), 15);
		});
	};

	useEffect(() => {
		refreshComments();
	}, [refreshComments]);

	return (
		<div>
			{(loader) && (
				<Spin className="full-page-spinner" fullscreen />
			)}
			<div className="comments-container pt-20">
				{rootComments.map((rootComment) => (
					<Comment
						key={rootComment.commentId}
						comment={rootComment}
						replies={getReplies(rootComment.commentId)}
						activeComment={activeComment}
						setActiveComment={setActiveComment}
						addComment={addComment}
						deleteComment={deleteComment}
						updateComment={updateComment}
						currentUserId={currentUserId}
						prAccess={prAccess}
						focusedEl={focusedCommentId}
					/>
				))}
			</div>
			<div>Write new comment:</div>
			<CommentForm submitLabel="Comment" handleSubmit={addComment} />
		</div>
	);
};

export default Comments;