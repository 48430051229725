import { Tooltip } from "antd";
import { toCurrency, toDate } from "../../../../../utils/globals";
import { ColumnsType } from "antd/es/table";
import { AdminOrdersResultModel, AdminOrdersSortColumns } from "../../../../../models/api/orders";
import { SortInfo, SortTypes } from "../../../../../models/api/shared";
import { SortOrder } from "antd/es/table/interface";

type Props = {
	handlePaymentClick: (orderId: number) => void;
	handleSubscriptionClick: (subscriptionId: string) => void;
	handleOrderDetailsClick: (orderId: number) => void;
	sortInfo: SortInfo<AdminOrdersSortColumns>;
};

const OrdersTableColumns = ({ handlePaymentClick, handleSubscriptionClick, handleOrderDetailsClick, sortInfo }: Props): ColumnsType<AdminOrdersResultModel> => {
	const handleMenuClick = (orderId: number) => {
		handleOrderDetailsClick(orderId);
	};

	return [
		{
			title: "Order Id",
			dataIndex: "orderId",
			key: "OrderId",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "OrderId" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: number) => (
				<Tooltip title="View Details">
					<span className="force-link" onClick={() => handleMenuClick(text)}>{text}</span>
				</Tooltip>
			),
		},
		{
			title: "Order Date",
			dataIndex: "orderDate",
			key: "OrderDate",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "OrderDate" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: string) => <span>{toDate(text)}</span>,
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "Price",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Price" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: number) => <span>{toCurrency(text)}</span>,
		},
		{
			title: "Total Paid",
			dataIndex: "totalPaid",
			key: "TotalPaid",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "TotalPaid" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: number) => <span>{toCurrency(text)}</span>,
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "Status",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Status" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (_, record: AdminOrdersResultModel) => <span>{record.statusDisplayName}</span>
		},
		{
			title: "Payment Provider",
			dataIndex: "paymentProvider",
			key: "PaymentProvider",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "PaymentProvider" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (_, record: AdminOrdersResultModel) => <span>{record.paymentProviderDisplayName}</span>
		},
		{
			title: "Administrative Contact",
			dataIndex: ['administrativeContact', 'email'],
			key: "AdministrativeContact",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "AdministrativeContact" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (email: string, record: AdminOrdersResultModel) => (
				<Tooltip title="Click here to view subscription info">
					<span
						className="force-link"
						onClick={() => handleSubscriptionClick(record.administrativeContact.id)}
					>
						{email}
					</span>
				</Tooltip>
			),
		},
		{
			title: "",
			dataIndex: "canEnterPayment",
			key: "canEnterPayment",
			render: (canEnterPayment: boolean, record: AdminOrdersResultModel) =>
				canEnterPayment ? (
					<Tooltip title="Click to confirm payment of order">
						<span
							className="force-link"
							onClick={() => handlePaymentClick(record.orderId)}
						>
							Payment
						</span>
					</Tooltip>
				) : null,
		},
	];
};

export default OrdersTableColumns;
