import { Button, Divider, Tooltip, Image, Typography, Row, Col} from "antd";
import { useState } from "react";
import ImageWithBearerToken from "../discovery/img-requests";
import DeleteTavaPick from "./delete-tava-pick";
import { CurrentResultModel } from "../../../../models/api/projects/projects";
import { toDateTime } from "../../../../utils/globals";
import TavaPicksSummaryRead from "./picks-summary";

interface SourcesModel {
	data: CurrentResultModel[];
	prId: number;
}

function removeByDataId(arr: CurrentResultModel[], idToRemove: number): CurrentResultModel[] {
	return arr.filter(item => item.myDataId !== idToRemove);
}

export default function CurrentResults(componentModel: SourcesModel) {
	const [resultsModel, setResultsModel] = useState<CurrentResultModel[] | []>(componentModel.data);
	const [summary, setSummary] = useState<number | null>(null);
	const maxSymbols = 600;

	const onDelete = (myDataId: number) => {
		setResultsModel(removeByDataId(resultsModel, myDataId));
	};


	return (
		<>
			{resultsModel.map((source, i) => (
				<div key={i}>
					<div className="note-unit" key={i}>
						<Row gutter={16} wrap={false}>
							<Col className="gutter-row" flex="140px">
								<div className="text-center overflow-hidden" style={{ maxHeight: `80px` }}>
									{source.metaImage ? (
										<Image
											key="123"
											src={source.metaImage}
											alt="img"
											style={{ maxWidth: '100%' }}
										/>
									) : (
										<ImageWithBearerToken imageId={source.imageId} imageDate={source.imageDate} />
									)}
								</div>
							</Col>
							<Col className="gutter-row" flex="auto">
								<div className="txt-ellipsis txt-nowrap">{source.title}</div>
								<div className="text-light-gray txt-small txt-ellipsis txt-nowrap">
									<Typography.Link onClick={() => window.open(`${source.url}`, "_blank")}>{source.url}</Typography.Link>
								</div>
								<div className="flex-item">
									<span className="text-light-gray txt-small">
										{toDateTime(source.createdAt)}
									</span>
									<Tooltip title={`Relevancy Score: ${source.starsWidth}%`}>
										<span className="ficon stars-outer ml-10">
											<span className="ficon stars-inner" style={{ width: `${source.starsWidth}%` }}></span>
										</span>
									</Tooltip>
								</div>
							</Col>
							<Col className="gutter-row" flex="none">
								<div className='hbox endbox topbox'>
									<Tooltip title="View Summary">
										<Button
											type="text"
											className="action-btn"
											onClick={() => setSummary(source.myDataId)}
										>
											<span className="ficon ficon-content"></span>
										</Button>
									</Tooltip>
									<DeleteTavaPick
										myDataId={source.myDataId}
										projectId={componentModel.prId}
										onDeleteToggle={(myId) => { onDelete(myId) }}
									/>
								</div>
							</Col>
						</Row>
						<div className="pt-10 summary">
							{source.summary
								? (source.summary.length <= maxSymbols ? source.summary : `${source.summary.substring(0, maxSymbols)}...`)
								: (source.strippedHtml
									? (source.strippedHtml.length <= maxSymbols ? source.strippedHtml : `${source.strippedHtml.substring(0, maxSymbols)}...`)
									: "No summary available.")
							}
						</div>
					</div>
					<Divider />
				</div>
			))}

			{summary ? (
				<TavaPicksSummaryRead
					key={0}
					data={resultsModel.find(item => item.myDataId === summary)}
					onCancel={() => setSummary(null)}
					projectId={componentModel.prId}
				/>
			) : null}
		</>
	);
}
