import Title from "antd/es/typography/Title";
import { Tabs } from "antd";
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from "../../../utils/pageRoutes";
import { AccountTabsType } from "../models/tabs";

interface AccountTabsModel {
	activePanel: AccountTabsType;
}

export default function AccountTabs({ activePanel }: AccountTabsModel) {
	const navigate = useNavigate();

	const items: { key: AccountTabsType; label: React.ReactNode }[] = [
		{
			key: "account",
			label: "Account",
		},
		{
			key: "license",
			label: "License",
		},
		{
			key: "billing",
			label: "Billing",
		},
	];
	const handleTabChange = (key: AccountTabsType) => {
		if (key === 'account')
			navigate(PageRoutes.account());
		else if (key === 'license')
			navigate(PageRoutes.license());
		else if (key === 'billing')
			navigate(PageRoutes.accountBilling());
	};
	return (
		<>
			<div>
				<Title className='page-title no-sep' level={1}>
					<span className="ficon ficon-setting" style={{ marginRight: "1rem" }}></span>
					Account Settings
				</Title>
			</div>
			<div>
				<Tabs items={items} defaultActiveKey={activePanel} onChange={(key) => handleTabChange(key as AccountTabsType)} />
			</div>
		</>
	);
}