import { Menu, type MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../../utils/pageRoutes';
import { HelpTabs } from '../../../models/api/shared';

type MenuItem = Required<MenuProps>['items'][number];

interface Props {
	activeTab: HelpTabs;
}

export default function HelpSider({ activeTab }: Props) {
	const navigate = useNavigate();

	const projectChildrenKeys = ['Overview', 'Discovery', 'Notes', 'Documents', 'ShareProjects'];
	const defaultOpenKeys = projectChildrenKeys.includes(activeTab) ? ['Projects'] : [];

	const items: MenuItem[] = [
		{
			key: 'GettingStarted',
			label: <span className="force-link">Getting Started</span>,
			onClick: () => navigate(PageRoutes.HelpHome())
		},
		{
			key: 'Projects',
			label: <span className="force-link">Projects</span>,
			children: [
				{
					key: 'Overview',
					label: <span className="force-link">Overview</span>,
					onClick: () => navigate(PageRoutes.Overview())
				},
				{
					key: 'Discovery',
					label: <span className="force-link">Discovery</span>,
					onClick: () => navigate(PageRoutes.DiscoveryHelp())
				},
				{
					key: 'Notes',
					label: <span className="force-link">Notes</span>,
					onClick: () => navigate(PageRoutes.NotesHelp())
				},
				{
					key: 'Documents',
					label: <span className="force-link">Documents</span>,
					onClick: () => navigate(PageRoutes.DocumentsHelp())
				},
				{
					key: 'ShareProjects',
					label: <span className="force-link">Share Project</span>,
					onClick: () => navigate(PageRoutes.ShareProjectHelp())
				},
			]
		},
		{
			key: 'AccountSettings',
			label: <span className="force-link">Account Settings</span>,
			onClick: () => navigate(PageRoutes.AccountSettingsHelp())
		},
	];

	return (
		<Sider
			breakpoint="sm"
			collapsedWidth="0"
			theme="light"
			className='vmenu-aside'
		>
			<Menu
				mode="inline"
				items={items}
				selectedKeys={[activeTab]}
				defaultOpenKeys={defaultOpenKeys}
			/>
		</Sider>
	);
}
