import { useContext, useEffect, useState } from 'react';
import { message, TableProps } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ApiRequests from '../../../../utils/api-requests';
import EditDocument from './EditDocument';
import TableColumns from './TableColumns';
import { DocumentsGridResultModel, DocumentsGridSortColumns } from '../../../../models/api/documents';
import Table from '../../../../components/antd-extensions/ExtendedTable';
import { ProjectAccessModes, SortInfo, SortTypes } from '../../../../models/api/shared';
import { errorHandler, handleTableSort } from '../../../../utils/globals';
import CommentsPopup from '../../components/comments/CommentsPopup';
import { MobileContext } from '../../../../components/PageLayout';
import DocumentsList from './DocumentsList';

interface Props {
	projectId?: number;
	prAccess: ProjectAccessModes
}

interface commentsModel {
	id: number,
	title: string
}

export default function ProjectDocuments({ projectId, prAccess }: Props) {
	const [sortInfo, setSortInfo] = useState<SortInfo<DocumentsGridSortColumns>>({
		sortColumn: "ModifiedAt",
		sortType: SortTypes.Desc,
	});
	const [readDocId, setReadDocId] = useState<number | null>(null);
	const [selectedDocument, setSelectedDocument] = useState<number | null>(null);

	const [editing, setEditing] = useState(false);
	const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
	const queryClient = useQueryClient();
	const [comment, setCommentNote] = useState<commentsModel | null>(null);

	const isMobile = useContext(MobileContext);
	const [drawerVisible, setDrawerVisible] = useState(false);

	const handleTableChange: TableProps<DocumentsGridResultModel>['onChange'] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const fetchDocuments = async () => {
		if (projectId === undefined) {
			throw new Error('Project ID is required');
		}
		return await ApiRequests.Project.Documents.grid(projectId, sortInfo);
	};

	const documentQuery = useQuery<DocumentsGridResultModel[]>({
		queryKey: ['documentsList', projectId, sortInfo],
		queryFn: fetchDocuments,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
		refetchInterval,
	});

	const deleteDocument = useMutation<number, Error, { projectId: number; documentId: number }>({
		mutationFn: ({ projectId, documentId }) => ApiRequests.Project.Documents.delete(projectId, documentId),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['documentsList'] });
			message.success('Document deleted successfully', 5);
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
	});

	const handleDelete = (projectId: number, documentId: number) => {
		deleteDocument.mutate({ projectId, documentId });
		setDrawerVisible(false);
	};

	useEffect(() => {
		if (documentQuery.isFetched && documentQuery.data) {

			const hasNotFinishedDocuments = documentQuery.data.some(doc => !doc.isReady);

			hasNotFinishedDocuments ? setRefetchInterval(20000) : setRefetchInterval(false);
		}
	}, [documentQuery.data]);

	const handleRead = (docId: number) => {
		setReadDocId(docId);
	};

	const handleEditButtonClick = (docId: number) => {
		setReadDocId(docId);
		setEditing(true);
		setDrawerVisible(false);
	};

	const handleCommentToggle = (noteId: number, title: string) => {
		setCommentNote({ id: noteId, title: title });
		setDrawerVisible(false);
	};

	const handleCloseView = () => {
		setReadDocId(null);
		setEditing(false);
	};

	const handleEditSubmit = () => {
		queryClient.invalidateQueries({ queryKey: ['documentsList'] });
		handleCloseView();
	};

	const tableColumns = TableColumns({
		handleRead,
		handleEditButtonClick,
		sortInfo,
		projectId,
		handleCommentToggle,
		handleDelete
	});

	const resetCommentCounter = useMutation({
		mutationFn: async ({ docId, readCount }:
			{ docId: number, readCount: number }) => {
			const updatedNotes = documentQuery.data?.map((doc) =>
				doc.id === docId ? {
					...doc,
					commentsCount: readCount,
					readCommentsCount: readCount
				} : doc
			);
			return updatedNotes;
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
		onSuccess: (data) => {
			if (data) {
				queryClient.setQueryData(['documentsList', projectId, sortInfo], data);
			}

		},
	});

	if (projectId === undefined) {
		return <div>No project selected.</div>;
	}

	if (documentQuery.error) {
		message.error((documentQuery.error as Error).message);
	}

	return (
		<>
			{isMobile ?
				<DocumentsList
					query={documentQuery}
					drawerState={{
						visible: drawerVisible,
						setVisible: setDrawerVisible,
						selectedDocumentId: selectedDocument,
						setSelected: setSelectedDocument,
					}}
					actions={{
						read: handleRead,
						edit: handleEditButtonClick,
						commentToggle: handleCommentToggle,
						delete: (documentId) => handleDelete(projectId!, documentId),
					}}
					projectId={projectId!}
				/>
				:
				<Table
					locale={{ emptyText: 'No Documents' }}
					columns={tableColumns}
					dataSource={documentQuery.data || []}
					loading={documentQuery.isFetching}
					onChange={handleTableChange}
					pagination={false}
					rowKey={(record) => record.id.toString()}
				/>
			}
			{readDocId && (
				<EditDocument
					documentId={readDocId}
					projectId={projectId}
					viewMode={!editing}
					handleEditSubmit={handleEditSubmit}
					onClose={handleCloseView}
				/>
			)}

			{comment &&
				<CommentsPopup
					onClose={(docId, counter) => {
						setCommentNote(null);
						if (counter) {
							resetCommentCounter.mutate({
								docId: docId,
								readCount: counter
							})
						}
					}}
					key={"comment-pop"}
					props={{
						type: ApiRequests.Comments.Documents,
						itemId: comment.id,
						prId: projectId,
						title: comment.title,
						prAccess: prAccess
					}}></CommentsPopup>
			}
		</>
	);
}
