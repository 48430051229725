import { ProjectTabsType } from "../models/models";

interface MutationVariables {
	Items: number[];
	Archive?: boolean;
	Delete?: boolean;
}

export const handleRecover = (
	id: number,
	activeTab: ProjectTabsType,
	recoverMutation: any,
	deleteMutation:any,
	setModalVisible?: (value: boolean) => void
) => {
	const mutationModel: MutationVariables = { Items: [id] };

	if (activeTab === "Archived") {
		recoverMutation.mutate(
			{ ...mutationModel, Archive: false },
			{
				onSettled: () => setModalVisible?.(false),
			}
		);
	} else if (activeTab === "Trash") {
		deleteMutation.mutate(
			{ ...mutationModel, Delete: false },
			{
				onSettled: () => setModalVisible?.(false),
			}
		);
	}
};
