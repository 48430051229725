import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { InteractionType } from "@azure/msal-browser";
import AccountTabs from "../components/Tabs";
import { useQuery } from "@tanstack/react-query";
import ApiRequests from "../../../utils/api-requests";
import { PaymentResultModel, PaymentsSortColumns } from "../../../models/api/payments";
import { useContext, useState } from "react";
import { Spin, TableProps } from "antd";
import { SortInfo, SortTypes } from "../../../models/api/shared";
import BillingsTableColumns from "./components/BillingsTableColumns";
import Orders from "../../../popups/Orders/Orders";
import Table from "../../../components/antd-extensions/ExtendedTable";
import { handleTableSort } from "../../../utils/globals";
import { MobileContext } from "../../../components/PageLayout";
import BillingsList from "./components/BillingsList";
import StickyHelp from "../../../components/StyckyHelp";
import { PageRoutes } from "../../../utils/pageRoutes";
import { getHelpContent } from "../utils/funcUtils";

export default function AccountBilling() {
	const authRequest = {
		...loginRequest,
	};
	const [sortInfo, setSortInfo] = useState<SortInfo<PaymentsSortColumns>>({
		sortColumn: "Id",
		sortType: SortTypes.Asc,
	});
	const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
	const isMobile = useContext(MobileContext);
	const [drawerVisible, setDrawerVisible] = useState(false);

	const handleTableChange: TableProps<PaymentResultModel>['onChange'] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const myPaymentsQuery = useQuery({
		queryKey: ["billings", sortInfo],
		queryFn: () => ApiRequests.Payments.myPayments({
			sortColumn: sortInfo.sortColumn,
			sortType: sortInfo.sortType,
		}),
	});

	const handleDetailsClick = (orderId: number) => {
		setSelectedOrderId(orderId);
	};

	const handleDetailsClose = () => {
		setSelectedOrderId(null);
	};
	if (myPaymentsQuery.isFetching) {
		return <Spin spinning={true} fullscreen />;
	}

	const columns = BillingsTableColumns({ handleDetailsClick, sortInfo });
	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest as any}
		>
			<AccountTabs activePanel="billing"></AccountTabs>
			{isMobile ?
				<BillingsList
					query={myPaymentsQuery}
					drawerVisible={drawerVisible}
					setDrawerVisible={setDrawerVisible}
					selectedOrderId={selectedOrderId}
					handleDetailsClick={handleDetailsClick}

				/>
				:
				<Table
					columns={columns}
					dataSource={myPaymentsQuery.data}
					rowKey="id"
					onChange={handleTableChange}
					pagination={false}
				/>}
			{selectedOrderId && <Orders model={{ orderId: selectedOrderId }} onClose={handleDetailsClose}></Orders>}
			<StickyHelp goToHelpLink={PageRoutes.AccountSettingsHelp()} popContent={getHelpContent()} title={"Account Settings and Licensing"}></StickyHelp>

		</MsalAuthenticationTemplate>
	);
}