import { Button, Checkbox, Form, Input, Modal, Radio, InputNumber, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import ApiRequests from "../../../../../utils/api-requests";
import { errorHandler } from "../../../../../utils/globals";
import { ServiceTypes } from "../../../../../models/api/shared";
import { ServiceCatalogCreateModel } from "../../../../../models/api/service-catalogs";

interface Props {
	open: boolean;
	onCancel: () => void;
	footer: boolean;
	afterClose: () => void;
	editingMode: boolean;
	idForEditing: number | null;
}
interface FormValues {
	licenseName: string;
	type: ServiceTypes | 'Upgrade';
	storageLimit: number;
	monthlyPrice: number;
	annualPrice: number;
	isActive: boolean;
	questions: number;
}

export default function LicenseModal({
	open,
	onCancel,
	footer,
	afterClose,
	editingMode,
	idForEditing,
}: Props) {
	const queryClient = useQueryClient();
	const [form] = Form.useForm();

	useEffect(() => {
		if (editingMode && idForEditing !== null) {
			getLicense.mutate(idForEditing);
		}
	}, [editingMode, idForEditing]);

	const createLicense = useMutation({
		mutationKey: ["createLicense"],
		mutationFn: async (model: ServiceCatalogCreateModel) => {
			return ApiRequests.ServiceCatalog.create(model);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['licenseGridQuery'] });
			handleCancelClick();
		},
		onError: (error) => {
			message.error(errorHandler(error));
		}
	});

	const editLicense = useMutation({
		mutationKey: ["editLicense"],
		mutationFn: async ({ id, model }: { id: number; model: ServiceCatalogCreateModel }) => {
			return ApiRequests.ServiceCatalog.update(id, model);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['licenseGridQuery'] });
			handleCancelClick();
		},
		onError: (error) => {
			message.error(errorHandler(error));
		}
	});

	const getLicense = useMutation({
		mutationKey: ["getLicense"],
		mutationFn: async (id: number) => {
			return ApiRequests.ServiceCatalog.read(id);
		},
		onSuccess: (data) => {
			form.setFieldsValue({
				licenseName: data.name,
				type: data.serviceType === null ? "Upgrade" : data.serviceType,
				storageLimit: data.diskSpace / 1073741824,
				monthlyPrice: data.monthPrice,
				annualPrice: data.yearPrice,
				isActive: data.isActive,
				questions: data.questions,
			});
		},
		onError: (error) => {
			message.error(errorHandler(error));
		}
	});

	const handleSubmit = (values: FormValues) => {
		const licenseModel: ServiceCatalogCreateModel = {
			name: values.licenseName,
			isUpgrade: values.type === 'Upgrade',
			diskSpace: Math.round(values.storageLimit * 1073741824),
			monthPrice: values.monthlyPrice,
			yearPrice: values.annualPrice,
			isActive: values.isActive || false,
			questions: values.questions,
		};
		if (values.type !== "Upgrade") {
			licenseModel.serviceType = values.type;
		}
		if (editingMode && idForEditing !== null) {
			editLicense.mutate({ id: idForEditing, model: licenseModel });
		} else {
			createLicense.mutate(licenseModel);
		}
	};

	const handleCancelClick = () => {
		form.resetFields();
		onCancel();
	};

	return (
		<Modal
			open={open}
			onCancel={handleCancelClick}
			footer={footer}
			afterClose={afterClose}
			title={editingMode ? "Edit License" : "Create License"}
		>
			<Form
				form={form}
				name="licenseForm"
				onFinish={handleSubmit}
				initialValues={{ isActive: false }}
				layout="vertical"
				style={{ width: "100%" }}
			>
				<Form.Item
					name="licenseName"
					label="License name"
					rules={[{ required: true, message: 'License Name is required' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="type"
					label="Type"
					rules={[{ required: true, message: 'Type is required' }]}
				>
					<Radio.Group>
						<Radio value="Free">Free</Radio>
						<Radio value="Professional">Professional</Radio>
						<Radio value="Upgrade">Upgrade</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item
					name="storageLimit"
					label="Storage Limit (GB)"
					rules={[{ required: true, message: 'Storage Limit is required' }]}
				>
					<InputNumber
						style={{ width: '100%' }}
						step={0.01}
						min={0}
					/>
				</Form.Item>

				<Form.Item
					name="monthlyPrice"
					label="Monthly Price"
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								const licenseType = getFieldValue("type");

								if (licenseType === "Free" && parseFloat(value) !== 0) {
									return Promise.reject(
										"The free license can't have a price. The value must be 0."
									);
								}

								if (licenseType !== "Free" && value && isNaN(parseFloat(value))) {
									return Promise.reject("Has to be a number");
								}

								return Promise.resolve();
							},
						}),
					]}
				>
					<InputNumber
						formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
						parser={value => value!.replace(/\s/g, '')}
						style={{ width: '100%' }}
					/>
				</Form.Item>

				<Form.Item
					name="annualPrice"
					label="Annual Price"
					rules={[
						({ getFieldValue }) => ({
							validator(_, value) {
								const licenseType = getFieldValue("type");

								if (licenseType === "Free" && parseFloat(value) !== 0) {
									return Promise.reject(
										"The free license can't have a price. The value must be 0."
									);
								}

								if (licenseType !== "Free" && value && isNaN(parseFloat(value))) {
									return Promise.reject("Has to be a number");
								}

								return Promise.resolve();
							},
						}),
					]}
				>
					<InputNumber
						formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
						parser={value => value!.replace(/\s/g, '')}
						style={{ width: '100%' }}
					/>
				</Form.Item>

				<Form.Item
					name="questions"
					label="Questions (Per day)"
					rules={[
						{ required: true, message: 'Questions per day is required' },
						() => ({
							validator(_, value) {


								if (value && !Number.isInteger(value)) {
									return Promise.reject('Questions need to be whole numbers');
								}
								return Promise.resolve();
							},
						}),
					]}
				>
					<InputNumber
						step={1}
						min={0}
						formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
						style={{ width: '100%' }}
					/>
				</Form.Item>

				<Form.Item name="isActive" valuePropName="checked">
					<Checkbox>Active</Checkbox>
				</Form.Item>

				<Form.Item>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button type="primary" htmlType="submit" style={{ marginRight: "1rem" }}>
							{editingMode ? 'Save Changes' : 'Create'}
						</Button>
						<Button style={{ marginRight: '8px' }} onClick={handleCancelClick}>
							Cancel
						</Button>
					</div>
				</Form.Item>
			</Form>
		</Modal>
	);
}
