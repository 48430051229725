import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
        authority: process.env.REACT_APP_AUTH_SERVER_AUTHORITY as string,
        redirectUri: process.env.REACT_APP_CLIENT_URL as string,
        postLogoutRedirectUri: process.env.REACT_APP_CLIENT_URL as string
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    //case LogLevel.Info:
                    //    console.info(message);
                    //    return;
                    //case LogLevel.Verbose:
                    //    console.debug(message);
                    //    return;
                    //case LogLevel.Warning:
                    //    console.warn(message);
                    //    return;
                    default:
                        return;
                }
            },
        },
    },
};

export const protectedResources = {
    toDoListAPI: {  //todo fix and remove
        endpoint: `${process.env.REACT_APP_SERVER_API_URL}/todolist`,
        scopes: {
            read: [`api://${process.env.REACT_APP_SERVER_API_ID}/ToDoList.Read`],
            write: [`api://${process.env.REACT_APP_SERVER_API_ID}/ToDoList.ReadWrite`],
        },
    },
};

export const loginRequest = {
    scopes: [...protectedResources.toDoListAPI.scopes.read, ...protectedResources.toDoListAPI.scopes.write],
};  
