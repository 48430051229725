import ApiRequests from "../../../../utils/api-requests";
import { useEffect, useState } from "react";
import { Alert, Col, Row, Select, Spin, message } from "antd";
import { ProjectAccessModes } from "../../../../models/api/shared";
import { errorHandler } from "../../../../utils/globals";
import { GeneralAccessModel } from "../../../../models/api/sharings";
import { useQuery} from "@tanstack/react-query";

interface Props {
	prId: number;
}

type GeneralAccess = 'All' | "Restricted"

interface GlobalAccessModes {
	value: GeneralAccess;
	label: string,
}

interface UserAccessModes {
	value: ProjectAccessModes;
	label: string,
}

export default function GeneralAcces({ prId }: Props) {
	const [loader, setLoader] = useState<boolean>(false);
	const [globalAccessOptions] = useState<GlobalAccessModes[]>([{
		value: "Restricted",
		label: "Rescricted"
	},
	{
		value: "All",
		label: "Anyone with the Link"
	}]);
	const [userAccessOptions] = useState<UserAccessModes[]>([{
		value: "Reader",
		label: "Reader"
	},
	{
		value: "Contributor",
		label: "Contributor"
	}]);
	const [selectedGlobalAccess, setSelectedGlobalAccess] = useState<GeneralAccess>();
	const [selectedUserAccess, setSelectedUserAccess] = useState<ProjectAccessModes>();

	const getGeneralAccess = useQuery({
		queryKey: ['shar-general-access', prId],
		queryFn: () => ApiRequests.Project.Sharings.GeneralAccess.read(prId),
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: true,

	});

	useEffect(() => {
		if (getGeneralAccess.isSuccess) {
			setSelectedGlobalAccess(getGeneralAccess.data === 'Restricted' ? 'Restricted' : 'All')
			setSelectedUserAccess(getGeneralAccess.data)
		}

	}, [getGeneralAccess.data, getGeneralAccess.isSuccess])

	const handleGlobalChange = (value: GeneralAccess) => {
		setLoader(true);
		if (value === 'Restricted') {
			ApiRequests.Project.Sharings.GeneralAccess.delete(prId)
				.then(() => {
					setSelectedUserAccess('Reader');
					setSelectedGlobalAccess(value);		
					setLoader(false);
				})
				.catch((err) => {
					message.error(errorHandler(err), 25);
				}).finally(() => {
					setLoader(false);
				})

		} else {
			const model: GeneralAccessModel = {
				accessMode: "Reader"
			}
			ApiRequests.Project.Sharings.GeneralAccess.update(prId, model)
				.then(() => {
					setSelectedUserAccess('Reader');
					setSelectedGlobalAccess(value);
					setLoader(false);
				})
				.catch((err) => {
					message.error(errorHandler(err), 25);
				}).finally(() => {
					setLoader(false);
				})
		}

	};

	const handleUserChange = (value: ProjectAccessModes) => {
		const model: GeneralAccessModel = {
			accessMode: value
		}
		setLoader(true);
		ApiRequests.Project.Sharings.GeneralAccess.update(prId, model)
			.then(() => {
				setSelectedUserAccess(value);
				setLoader(false);
			})
			.catch((err) => {
				message.error(errorHandler(err), 25);
			}).finally(() => {
				setLoader(false);
			})
	};

	return (
		<>
			{(loader || getGeneralAccess.isFetching) && (
				<Spin className="full-page-spinner" fullscreen />
			)}

			<div className="mt-15 mb-10 text-semibold" key={'label-people-access'}>General Access:</div>
			{!loader && selectedUserAccess ?
				<div className={"mb-10"} key={'comp-gen-acces'}>
					<Row gutter={16} wrap={false}>
						<Col className="gutter-row" flex="none">
							<div
								className={`ficon ${selectedGlobalAccess === 'Restricted' ? 'ficon-lock' : 'ficon-globe'}`}
								style={{ fontSize: "3rem", backgroundColor: "#ddd", padding: "0.25rem 0.25rem 0 0.25rem" }}
							></div>
						</Col>
						<Col className="gutter-row" flex="auto">
							<Select
								options={globalAccessOptions}
								value={selectedGlobalAccess}
								style={{ width: 220 }}
								onChange={handleGlobalChange}
							/>
							{selectedGlobalAccess === 'Restricted' ? <div>Only people with access can open with the link</div>
								: <div>Anyone on the Internet with the Link can view</div>}
						</Col>
						<Col className="gutter-row" flex="none">
							<div className='hbox endbox topbox'>
								{selectedGlobalAccess === 'All' ? <Select
									options={userAccessOptions}
									value={selectedUserAccess}
									style={{ width: 160 }}
									onChange={handleUserChange}
								/> : null}
							</div>
						</Col>
					</Row>
				</div> :
				null}

			{(getGeneralAccess.isError) ? (
				<Alert
					message="Error"
					description={errorHandler(getGeneralAccess.isError)}
					type="error"
					showIcon
				/>)
				: null}
		</>
	);
}
