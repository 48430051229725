import { Alert, Col, Modal, Row, Image, Typography, Tooltip, Menu, message } from "antd";
import { copyToClipboard, errorHandler, newLineToBr, toDateTime } from "../../../../utils/globals";
import parse from 'html-react-parser';
import { CurrentResultModel } from "../../../../models/api/projects/projects";
import ImageWithBearerToken from "./img-requests";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import ApiRequests from "../../../../utils/api-requests";
import { ProjectNoteCreateModel } from "../../../../models/api/notes";

interface SummaryReadModel {
	data: CurrentResultModel | undefined;
	projectId: number;
	onCancel: () => void;
}

export default function TavaPicksSummaryRead({ data, onCancel, projectId }: SummaryReadModel): JSX.Element {

	const [contextMenu, setContextMenu] = useState<{ visible: boolean, x: number | null, y: number | null, text: string }>({ visible: false, x: 0, y: 0, text: '' });

	const handleCopyToClipboard = () => {
		copyToClipboard(contextMenu.text);
		setContextMenu({ ...contextMenu, visible: false });
	};

	const createNoteMutation = useMutation({
		mutationFn: (model: ProjectNoteCreateModel) => {
			return ApiRequests.Project.Notes.create(projectId, model);
		},
		onError: (error) => {
			message.error(errorHandler(error));
		}
	});

	const handleCreateNote = () => {
		const model: ProjectNoteCreateModel = {
			text: contextMenu.text,
			images: [],
			topicsWrapper: { topics: [] },
			isOpenAi: false,
			gallery: { images: [] },
			tags: []
		};
		createNoteMutation.mutate(model, {
			onSuccess: () => {
				message.success('Note created successfully');
				setContextMenu({ ...contextMenu, visible: false });
			},
			onError: (error) => {
				message.error(errorHandler(error), 15);
			},
		});
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (contextMenu.visible) {
				let n = event.target as Element;
				if (!n.closest(".summary")) {
					window.getSelection()?.empty();
					setContextMenu({ ...contextMenu, visible: false });
				}
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [contextMenu]);

	const checkForSelect = (event: React.MouseEvent<HTMLDivElement>) => {
		let selected = window.getSelection()?.toString().trim();
		if (selected) {
			setContextMenu({
				visible: true,
				x: event.nativeEvent.offsetX,
				y: event.nativeEvent.offsetY + 150,
				text: selected,
			});
		}
	};

	const clearSelect = () => {
		setContextMenu({ ...contextMenu, visible: false });
	};

	const items = [

		{
			label: "Create note",
			key: 'create',
			icon: <span className="ficon ficon-note"></span>,
			onClick: handleCreateNote
		},
				{
			label: "Copy to clipboard",
			key: 'copy',
			icon: <span className="ficon ficon-copy"></span>,
			onClick: handleCopyToClipboard
		}
	];

	return (
		<Modal
			width={800}
			open={!!data}
			destroyOnClose
			onCancel={onCancel}
			cancelButtonProps={{ style: { display: 'none' } }}
			closable={true}
			onOk={onCancel}
			title={data ? (
				<Row gutter={16} wrap={false} style={{ paddingRight: '2rem' }}>
					<Col flex="140px">
						<div className="text-center overflow-hidden" style={{ maxHeight: `80px` }}>
							{data.metaImage ? (
								<Image
									src={data.metaImage}
									alt="img"
									style={{ maxWidth: '100%' }}
								/>
							) : (
								<ImageWithBearerToken imageId={data.imageId} imageDate={data.imageDate} />
							)}
						</div>
					</Col>
					<Col flex="auto">
						<Typography.Title level={4} ellipsis={{ tooltip: data.title }}>
							{data.title}
						</Typography.Title>
						<Typography.Link onClick={() => window.open(`${data.url}`, "_blank")}>{data.url}</Typography.Link>
						<div className="flex-item">
							<span className="text-light-gray txt-small">
								{toDateTime(data.createdAt)}
							</span>
							<Tooltip title={`Relevancy Score: ${data.starsWidth}%`}>
								<span className="ficon stars-outer ml-10">
									<span className="ficon stars-inner" style={{ width: `${data.starsWidth}%` }}></span>
								</span>
							</Tooltip>
						</div>
					</Col>
				</Row>
			) : "Summary"}
		>
			{data ? (
				<div
					className="summary"
					onMouseUp={checkForSelect}
					onMouseDown={clearSelect}
				>
					{parse(newLineToBr(data.strippedHtml))}
				</div>
			) : (
				<Alert
					message="Error"
					description={errorHandler("Error opening the document")}
					type="error"
					showIcon
				/>
			)}
			{contextMenu.visible && (
				<Menu items={items} style={{
					position: 'absolute',
					top: contextMenu.y ?? 0,
					left: contextMenu.x ?? 0,
					minWidth: 180
				}} />
			)}
		</Modal>
	);
}
