import { useState } from "react";
import { SubscriptionsResultModel, SubscriptionsSortColumns } from "../../../../../../models/api/services";
import { Spin, TableProps, message } from "antd";
import { SortInfo, SortTypes } from "../../../../../../models/api/shared";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ApiRequests from "../../../../../../utils/api-requests";
import ActiveSubscriptionsTableColumns from "./components/ActiveSubscriptionsTableColumns";
import SubscriptionManager from "../../../../components/SubscriptionManager";
import ApprovalModal from "../components/ApprovalModal";
import Table from "../../../../../../components/antd-extensions/ExtendedTable";
import { errorHandler, handleTableSort } from "../../../../../../utils/globals";

export default function ClientsWaitingForApproval() {
	const [sortInfo, setSortInfo] = useState<SortInfo<SubscriptionsSortColumns>>({
		sortColumn: "ExpirationDate",
		sortType: SortTypes.Asc,
	});

	const [selectedOrderMemberId, setSelectedOrderMemberId] = useState<string | null>(null);
	const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string | null>(null);
	const queryClient = useQueryClient();

	const handleTableChange: TableProps<SubscriptionsResultModel>['onChange'] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const activeSubscriptions = useQuery<SubscriptionsResultModel[]>({
		queryKey: ["activeSubscriptions", sortInfo],
		queryFn: () =>
			ApiRequests.Services.activeSubscriptions({
				sortColumn: sortInfo.sortColumn,
				sortType: sortInfo.sortType,
			}),
	});

	if (activeSubscriptions.isError) {
		message.error(errorHandler(activeSubscriptions.error.message));
	}

	const handlePaymentClick = (memberId: string) => {
		setSelectedOrderMemberId(memberId);
	};

	const handleSubscriptionClick = (subscriptionId: string) => {
		setSelectedSubscriptionId(subscriptionId);
	};
	const handleSuccsesfulPayment = () => {
		queryClient.invalidateQueries({ queryKey: ['activeSubscriptions'] });
	}

	if (activeSubscriptions.isFetching) {
		return <Spin spinning={true} fullscreen />;
	}
	const columns = ActiveSubscriptionsTableColumns({ handlePaymentClick, handleSubscriptionClick, sortInfo });

	return (
		<>
			{selectedOrderMemberId ? <ApprovalModal
				orderId={null}
				memberId={selectedOrderMemberId}
				setSelectedOrderId={() => { setSelectedOrderMemberId(null) }}
				onSuccsesfulPayment={handleSuccsesfulPayment}
			/> : null}

			<Table
				columns={columns}
				dataSource={activeSubscriptions.data}
				rowKey="id"
				onChange={handleTableChange}
				pagination={false}
			/>

			{selectedSubscriptionId ?
				<SubscriptionManager key="subscription-modal" onClose={() => { setSelectedSubscriptionId(null) }}
					subscriptionId={selectedSubscriptionId} memberId={selectedSubscriptionId}></SubscriptionManager> : null}
		</>
	);
}
