import {
	ProjectCreateModel,
	ProjectHeaderModel,
	ProjectUpdateModel,
	ProjectViewModel,
	ProjectsArchiveModel,
	ProjectsDeleteModel,
	SharedProjectsGridFilterModel,
	SharedProjectsGridWrapperModel,
} from "../models/api/projects/projects";
import { CallOpenAiStream, callAxios } from "./axios-request";
import {
	ProjectsGridFilterModel,
	ProjectsGridWrapperModel,
} from "../models/api/projects/projects";
import { MyDataSelectModel } from "../models/api/my-data";
import { AskQuestionCreateModel, ImageLinkDataModel, PaymentCreateServiceModel, ProjectAccessModes, SearchRequestModel, SearchResult, ViewResultsViewModel } from "../models/api/shared";
import { NewsFeedsLayoutViewModel, SelectItemAdministratorRoles, SelectItemDocumentTypeModel, SelectItemProjectAccessMode, SelectItemRepeatType, SelectItemResearchRuleActions, SelectItemSearchWordsModes, SelectItemServiceTypeModel, SelectItemTaskStatusModel } from "../models/api/enums";
import { CreateImagesModel } from "../models/api/images";
import { BoostInsightWrapperModel } from "../models/api/boost_insights";
import { ProjectNoteCreateModel, ProjectNoteUpdateModel, ProjectNoteViewModel, ProjectNotesGridModel } from "../models/api/notes";
import { DocumentCreateModel, DocumentUpdateModel, DocumentsGridFilterModel, DocumentsGridResultModel } from "../models/api/documents";
import { OpenAiRequestModel, OpenAiResult } from "../models/api/open-ai";
import { PaymentCreateModel, PaymentsFilterModel, PaymentResultModel } from "../models/api/payments";
import { PurchaseServiceCreateModel, PurchaseViewModel, ServiceCatalogViewModel } from "../models/api/purchase";
import { ServiceInfoModel, SubscriptionsFilterModel, SubscriptionsResultModel } from "../models/api/services";
import { ServiceCatalogCreateModel, ServiceCatalogFilterModel, ServiceCatalogResultModel } from "../models/api/service-catalogs";
import { AdminOrdersFilterModel, AdminOrdersResultModel, OrderViewModel } from "../models/api/orders";
//import { CompanyViewModel } from "../models/api/companies";
import { MemberDetailsModel } from "../models/api/members";
import { GeneralAccessModel, ProjectSharingViewModel, ProjectSharingUpdateModel, SharedInvitationsCreateModel, SharedInvitationsUpdateModel, SharedInvitationsDeleteModel } from "../models/api/sharings";
import { IAppComments } from "../models/api/comments";


function apiURL() {
	return process.env.REACT_APP_SERVER_API_URL as string;
}

function apiProjects(rid?: number) {
	return `${apiURL()}/projects${rid ? '/' + rid : ''}`;
}

function apiSharings(rid: number, mid?: string) {
	return `${apiProjects(rid)}/sharings${mid ? '/' + mid : ''}`;
}
function apiSharingInvitations(rid: number) {
	return `${apiProjects(rid)}/sharing-invitations`;
}

function apiSharingGeneralAccess(rid: number) {
	return `${apiProjects(rid)}/sharing-general-access`;
}



function apiNotes(rid?: number, noteId?: number) {
	return `${apiProjects(rid)}/notes${noteId ? '/' + noteId : ''}`;
}

const apiDocuments = (rid: number, documentId?: number) =>
	`${apiProjects(rid)}/documents${documentId ? '/' + documentId : ''}`;

function services(id?: string) {
	return `${apiURL()}/services` + ((id) ? "/" + id : '');
}


function serviceCatalog(id?: number) {
	return `${apiURL()}/service-catalog` + (id === undefined ? "" : "/" + id);
}

function purchases(sid?: string, cid?: number) {
	return `${apiURL()}/purchase`
		+ (sid === undefined ? "" : "/" + sid)
		+ (cid === undefined ? "" : "/" + cid);
}
function orders(id?: number) {
	return `${apiURL()}/orders` + (id ? '/' + id : '');
}
function apiChatGtpURL(rid: number) {
	return `${apiURL()}/open-ai/project/${rid}`;
}

function apiAskQuestions(rid: number, qid?: number) {
	return `${apiProjects()}/${rid}/ask-questions${qid ? ('/' + qid) : ''}`;
}

function apiSearchResults() {
	return `${apiURL()}/searches/results`;
}

function apiEnums() {
	return `${apiURL()}/enums`;
}

function apiPayPal() {
	return `${apiURL()}/paypal`;
}


//function apiCompanies(id?: number) { return `${apiURL()}/companies` + (id === undefined ? "" : "/" + id); }
function apiMembers(id?: string) {
	return `${apiURL()}/members${id === undefined ? "" : "/" + id}`;
}



function get(url: string) {
	return callAxios("GET", url)
}

function post(url: string, data?: any) {
	return callAxios("POST", url, data);
}

function put(url: string, data?: any) {
	return callAxios("PUT", url, data);
}

function remove(url: string) {
	return callAxios("DELETE", url);
}


interface MyDataXHR {
	delete(rid: number, model: MyDataSelectModel): Promise<number[]>,
	results(rid: number, id: number): Promise<ViewResultsViewModel[]>,
	tavaPicksSummary(rid: number, id: number): Promise<ViewResultsViewModel>,
	markRead(rid: number, model: MyDataSelectModel): Promise<number[]>,
	stopCrawling(rid: number, model: MyDataSelectModel): Promise<number[]>,
	keepCrawling(rid: number, model: MyDataSelectModel): Promise<number[]>,
	//importance(rid: number, model: ChangeImportanceModel): Promise<number[]>,
	gallery(rid: number, id: number): Promise<string[]>,
}

function myData(rid: number) { return `${apiURL()}/my-data/` + rid; }

const myDataXHR: MyDataXHR = {
	delete: (rid, model) => put(myData(rid) + '/delete', model),
	markRead: (rid, model) => put(myData(rid) + '/mark-read', model),
	results: (rid, id) => get(myData(rid) + '/results/' + id),
	tavaPicksSummary: (rid, id) => get(myData(rid) + '/tava-picks/' + id),
	stopCrawling: (rid, model) => put(myData(rid) + '/stop-crawling', model),
	keepCrawling: (rid, model) => put(myData(rid) + '/keep-crawling', model),
	//importance: (rid, model) => put(myData(rid) + '/importance', model),
	gallery: (rid, id) => get(myData(rid) + '/gallery/' + id),
}

function DocumentsComments(rid: number, iid: number, cid?: number) {
	return apiDocuments(rid, iid) + '/comments' + ((cid) ? '/' + cid : '');
}

function DocumentsReplies(rid: number, iid: number, cid: number, eid?: number) {
	return DocumentsComments(rid, iid, cid) + '/replies' + ((eid) ? '/' + eid : '');
}

function NoteComments(rid: number, iid: number, cid?: number) {
	return apiNotes(rid, iid) + '/comments' + ((cid) ? '/' + cid : '');
}


function NoteReplies(rid: number, iid: number, cid: number, eid?: number) {
	return NoteComments(rid, iid, cid) + '/replies' + ((eid) ? '/' + eid : '');
}

const AppComments : IAppComments = {
	Documents: {
		list: function (rid, iid) { return get(DocumentsComments(rid, iid)); },
		create: function (rid, iid, model) { return post(DocumentsComments(rid, iid), model); },
		//read: function (rid, iid, cid) { return get(DocumentsComments(rid, iid, cid)); },
		update: function (rid, iid, cid, model) { return put(DocumentsComments(rid, iid, cid), model); },
		delete: function (rid, iid, cid) { return remove(DocumentsComments(rid, iid, cid)); },
		markAsRead: function (rid, iid, model) { return post(DocumentsComments(rid, iid) + '/mark-read', model); },
		Replies: {
			create: function (rid, iid, cid, model) { return post(DocumentsReplies(rid, iid, cid), model); },
			//read: function (rid, iid, cid, eid) { return get(DocumentsReplies(rid, iid, cid, eid)); },
			update: function (rid, iid, cid, eid, model) { return put(DocumentsReplies(rid, iid, cid, eid), model); },
			delete: function (rid, iid, cid, eid) { return remove(DocumentsReplies(rid, iid, cid, eid)); },
		}
	},
	Notes: {
		list: function (rid, iid) { return get(NoteComments(rid, iid)); },
		create: function (rid, iid, model) { return post(NoteComments(rid, iid), model); },
		//read: function (rid, iid, cid) { return get(NoteComments(rid, iid, cid)); },
		update: function (rid, iid, cid, model) { return put(NoteComments(rid, iid, cid), model); },
		delete: function (rid, iid, cid) { return remove(NoteComments(rid, iid, cid)); },
		markAsRead: function (rid, iid, model) { return post(NoteComments(rid, iid) + '/mark-read', model); },
		Replies: {
			create: function (rid, iid, cid, model) { return post(NoteReplies(rid, iid, cid), model); },
			//read: function (rid, iid, cid, eid) { return get(NoteReplies(rid, iid, cid, eid)); },
			update: function (rid, iid, cid, eid, model) { return put(NoteReplies(rid, iid, cid, eid), model); },
			delete: function (rid, iid, cid, eid) { return remove(NoteReplies(rid, iid, cid, eid)); },
		}
	},
}


const ApiRequests = {
	AskQuestions: {
		create: (rid: number, model: AskQuestionCreateModel): Promise<number> =>
			post(apiAskQuestions(rid), model),
		delete: (rid: number, qid: number): Promise<number> =>
			remove(apiAskQuestions(rid, qid)),
	},
	Comments: AppComments,
	Enums: {
		administratorRoles: (): Promise<SelectItemAdministratorRoles[]> => get(apiEnums() + '/administrator-roles'),
		documentTypes: (): Promise<SelectItemDocumentTypeModel[]> =>
			get(apiEnums() + '/document-types'),
		layouts: (): Promise<NewsFeedsLayoutViewModel[]> => get(apiEnums() + '/layouts'),
		projectAccessModes: (): Promise<SelectItemProjectAccessMode[]> => get(apiEnums() + '/project-access-modes'),
		repeats: (): Promise<SelectItemRepeatType[]> => get(apiEnums() + '/repeats'),
		serviceTypes: (): Promise<SelectItemServiceTypeModel[]> => get(apiEnums() + '/service-types'),
		taskStatuses: (): Promise<SelectItemTaskStatusModel[]> => get(apiEnums() + '/task_statuses'),
		Rules: {
			actions: (): Promise<SelectItemResearchRuleActions[]> => get(apiEnums() + '/rule-actions'),
			modes: (): Promise<SelectItemSearchWordsModes[]> => get(apiEnums() + '/rule-modes'),
		}
	},
	Images: {
		href: (id: number, date: string | number | Date, width: number, height: number): string =>
			apiURL() + '/images/' + id + '/' + width + '/' + height + '/' + new Date(date).getTime(),
		create: (rid: number, model: CreateImagesModel)
			: Promise<ImageLinkDataModel[]> =>
			post('/api/images/projects/' + rid, model),
	},
	FollowUps: {
		create: (rid: number)
			: Promise<number> =>
			post(`${apiProjects(rid)}/follow-ups`),
	},
	MyData: myDataXHR,
	Members: {
		read: (id: string): Promise<MemberDetailsModel> => get(apiMembers(id)),
		findOrder: (id: string): Promise<number> => get(apiMembers(id) + '/orders'),
		tosAccepted: (): Promise<string> => put(apiMembers() + '/tos'),
	},
	Project: {
		create: (model: ProjectCreateModel): Promise<number> =>
			post(`${apiProjects()}`, model),
		personalList: (filter: ProjectsGridFilterModel): Promise<ProjectsGridWrapperModel> =>
			post(`${apiProjects()}/grid`, filter),
		sharedGrid: (filter: SharedProjectsGridFilterModel): Promise<SharedProjectsGridWrapperModel> =>
			post(`${apiProjects()}/shared-grid`, filter),
		view: (pId: number): Promise<ProjectViewModel> =>
			get(`${apiProjects()}/${pId}`),
		delete: (model: ProjectsDeleteModel) =>
			post(`${apiProjects()}/delete`, model),
		archive: (model: ProjectsArchiveModel) =>
			put(`${apiProjects()}/archive`, model),
		permanentDelete: (id: number): Promise<number> =>
			remove(`${apiProjects()}/${id}`),
		header: (pId: number): Promise<ProjectHeaderModel> =>
			get(`${apiProjects()}/${pId}/header`),
		renameGet: (pId: number): Promise<ProjectUpdateModel> =>
			get(`${apiProjects()}/${pId}/rename`),
		renamePut: (pId: number, model: ProjectUpdateModel): Promise<number> =>
			put(`${apiProjects()}/${pId}/rename`, model),
		BoostInsights: {
			update: (rid: number, model: BoostInsightWrapperModel): Promise<number> =>
				put(`${apiProjects(rid)}/boost-insights`, model),
		},
		Notes: {
			grid: async (rid: number): Promise<ProjectNotesGridModel> => {
				return get(apiNotes(rid) + "/grid");
			},
			create: (rid: number, model: ProjectNoteCreateModel): Promise<number> =>
				post(`${apiNotes(rid)}`, model),
			read: (rid: number, nid: number): Promise<ProjectNoteViewModel> =>
				get(`${apiNotes(rid, nid)}`),
			update: (rid: number, nid: number, model: ProjectNoteUpdateModel): Promise<number> =>
				put(`${apiNotes(rid, nid)}`, model),
			delete: (rid: number, nid: number): Promise<number> =>
				remove(`${apiNotes(rid, nid)}`),
		},
		Documents: {
			grid: (rid: number, model: DocumentsGridFilterModel)
				: Promise<DocumentsGridResultModel[]> =>
				post(apiDocuments(rid) + "/grid", model),
			create: (rid: number, model: DocumentCreateModel): Promise<number> =>
				post(apiDocuments(rid), model),
			read: (rid: number, did: number): Promise<DocumentUpdateModel> =>
				get(apiDocuments(rid, did)),
			update: (rid: number, did: number, model: DocumentUpdateModel): Promise<number> =>
				put(apiDocuments(rid, did), model),
			delete: (rid: number, did: number): Promise<number> =>
				remove(apiDocuments(rid, did)),
		},
		Sharings: {
			Actual: {
				list: (rid: number): Promise<ProjectSharingViewModel[]> =>
					get(apiSharings(rid)),
				update: (rid: number, mid: string, model: ProjectSharingUpdateModel): Promise<string> =>
					put(apiSharings(rid, mid), model),
				delete: (rid: number, mid: string): Promise<string> =>
					remove(apiSharings(rid, mid)),
			},
			Invitations: {
				list: (rid: number): Promise<ProjectSharingViewModel[]> =>
					get(apiSharingInvitations(rid)),
				create: (rid: number, model: SharedInvitationsCreateModel): Promise<number> =>
					post(apiSharingInvitations(rid), model),
				update: (rid: number, model: SharedInvitationsUpdateModel): Promise<string> =>
					put(apiSharingInvitations(rid), model),
				delete: (rid: number, model: SharedInvitationsDeleteModel): Promise<string> =>
					put(apiSharingInvitations(rid) + '/delete', model),
			},
			GeneralAccess: {
				read: (rid: number): Promise<ProjectAccessModes> =>
					get(apiSharingGeneralAccess(rid)),
				update: (rid: number, model: GeneralAccessModel): Promise<ProjectAccessModes> =>
					put(apiSharingGeneralAccess(rid), model),
				delete: (rid: number): Promise<ProjectAccessModes> =>
					remove(apiSharingGeneralAccess(rid)),

			}
		}
	},
	OpenAi: {
		projectLicenceStream: (
			rId: number,
			model: OpenAiRequestModel
		): Promise<any> => CallOpenAiStream("POST", apiChatGtpURL(rId) + '/stream1', model),
		boosts: (
			rId: number,
			model: OpenAiRequestModel
		): Promise<OpenAiResult> => post(apiChatGtpURL(rId), model),
	},
	Payments: {
		create: (model: PaymentCreateModel):
			Promise<number> => post(`${apiURL()}/payments`, model),
		myPayments: (model: PaymentsFilterModel):
			Promise<PaymentResultModel[]> => post(`${apiURL()}/payments/my-payments`, model),
		administrationPayments: (model: PaymentsFilterModel):
			Promise<PaymentResultModel[]> =>
			post(`${apiURL()}/payments/administration-payments`, model),
	},
	PayPal: {
		approved: (token: string):
			Promise<number> => put(`${apiPayPal()}/approved`, token),
		rejected: (token: string):
			Promise<number> => put(`${apiPayPal()}/rejected`, token),
	},
	Purchase: {
		catalogue: (): Promise<ServiceCatalogViewModel[]> => get(purchases()),
		start: (sesionId: string, catalogId: number): Promise<PurchaseViewModel> =>
			get(purchases(sesionId, catalogId)),
		calculate: (sesionId: string, model: PurchaseServiceCreateModel)
			: Promise<PurchaseViewModel> => put(purchases(sesionId), model),
		finalize: (sesionId: string, model: PurchaseServiceCreateModel): Promise<string> => post(purchases(sesionId), model)
	},
	Searches: {
		results: (model: SearchRequestModel): Promise<SearchResult[]> =>
			post(apiSearchResults(), model),
	},
	Services: {
		cancel: (): Promise<number> => put(services() + '/cancel'),
		read: (id: string): Promise<ServiceInfoModel> => get(services(id)),
		suspend: (id: string): Promise<boolean> => put(services(id) + '/suspend'),
		myLicence: (): Promise<ServiceInfoModel> => get(services() + '/my-license'),
		activeSubscriptions: (model: SubscriptionsFilterModel)
			: Promise<SubscriptionsResultModel[]> => post(services() + '/active-subscriptions', model),
		expiredSubscriptions: (model: SubscriptionsFilterModel)
			: Promise<SubscriptionsResultModel[]> => post(services() + '/expired-subscriptions', model),
	},
	ServiceCatalog: {
		grid: (model: ServiceCatalogFilterModel): Promise<ServiceCatalogResultModel[]> => post(serviceCatalog() + '/grid', model),
		read: (id: number): Promise<ServiceCatalogCreateModel> => get(serviceCatalog(id)),
		create: (model: ServiceCatalogCreateModel): Promise<number> => post(serviceCatalog(), model),
		update: (id: number, model: ServiceCatalogCreateModel): Promise<number> => put(serviceCatalog(id), model),
		delete: (id: number): Promise<number> => remove(serviceCatalog(id))
	},
	Orders: {
		administrationOrders: (model: AdminOrdersFilterModel): Promise<AdminOrdersResultModel[]> => post(orders() + '/administration-orders', model),
		cancel: (id: number): Promise<number> => remove(orders(id)),
		read: (id: number): Promise<OrderViewModel> => get(orders(id)),
		serviceInfo: (id: number): Promise<PaymentCreateServiceModel | null> => get(orders(id) + "/service"),
		waitingApproval: (filter: AdminOrdersFilterModel)
			: Promise<AdminOrdersResultModel[]> =>
			post(orders() + '/clients-waiting-approval', filter),
	}


};

export default ApiRequests;
