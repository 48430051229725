const SessionStorageMsg = {
	create(msg: string) {
		sessionStorage.setItem(getSSKey(), msg);
	},
	read() {
		return sessionStorage.getItem(getSSKey())
	},
	remove() {
		sessionStorage.removeItem(getSSKey());
	}

}

export default SessionStorageMsg;

function getSSKey(): string {
	return 'ss-provider-approved-rejected-msg'
}
