import { Table, Tooltip } from "antd";
import { IOrderPayment } from "../models/orders";
import { useState } from "react";
import PaymentsDetail from "./PaymentsDetail";


interface Props {
	data: IOrderPayment[],
	paymentId: number | null
}


export default function Payments({
	data, paymentId
}: Props) {
	const [selectedPaymentId, setSelectedPaymentId] = useState<number | null>(paymentId);
	const columns = [
		{
			title: "Payment #",
			dataIndex: "paymentId",
			key: "paymentId",
			width: 200,

		},
		{
			title: "Payment Date",
			dataIndex: "paymentDateAsText",
			key: "paymentDateAsText",
			width: 1000,
		},
		{
			title: "Transaction #",
			dataIndex: "transaction",
			key: "transaction",
			width: 200,
			sorter: false,
		},
		{
			title: "Check #",
			dataIndex: "check",
			key: "check",
			width: 200,
			sorter: false,
		},
		{
			title: "Amount",
			dataIndex: "ammountAsText",
			key: "ammountAsText",
			width: 200,
			sorter: false,
		},
		{
			title: "Actions",
			dataIndex: "actionColumn",
			key: "actionColumn",
			width: 0,
			render: (text: string, record: IOrderPayment) => {
				return <Tooltip title="Click to view details">
					<span
						className="force-link"
						onClick={() => {
							//setDetailsData(formatDetails(data, record.paymentId));
							setSelectedPaymentId(record.paymentId)
						}}
					>
						details
					</span>
				</Tooltip>
			},
		},
	];


	return (
		<>
			{selectedPaymentId ? (
				<PaymentsDetail data={{ data: data, paymentId: selectedPaymentId }} onClose={() => { setSelectedPaymentId(null)} } />
			) :
				<Table
					locale={{ emptyText: 'No Payments' }}
					columns={columns}
					rowClassName={(record) => (record.visible ? '' : 'hidden-row')}
					dataSource={data || []}
					loading={false}
					pagination={false}
					rowKey={(record) => record.paymentId}
				/>
			}
		</>


	);
}
