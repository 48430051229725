import ApiRequests from "../../../../utils/api-requests";
import { useState, useEffect } from "react";
import { SharedInvitationsDeleteModel, SharedInvitationsUpdateModel } from "../../../../models/api/sharings";
import { AccessModes } from "./ SharingManager";
import { Col, Row, Select, Spin, message } from "antd";
import { ProjectAccessModes } from "../../../../models/api/shared";
import { errorHandler } from "../../../../utils/globals";
import PopoverContent from "../sharings/SharingsPopover";
import useFetchQueries from "./fetchQueries";
import { User } from "../../models/sharings";

interface Props {
	prId: number;
	accesModes: AccessModes[];
}

type PeopleAccesModes = ProjectAccessModes | "Remove"

interface PeopleAccessModesSelect {
	value: PeopleAccesModes;
	label: string
}

function formatAccessModes(accesModes: AccessModes[]) {
	const modes: PeopleAccessModesSelect[] = accesModes.filter(item => item.value !== "Owner" && item.value !== "Restricted");
	modes.push({ value: "Remove", label: "Remove User" })
	return modes;
}

export default function PeopleAccess({ prId, accesModes }: Props) {
	const [loader, setLoader] = useState<boolean>(false);
	const [accessOptions] = useState<PeopleAccessModesSelect[]>(formatAccessModes([...accesModes]));
	const [sharingManager, setSharingManager] = useState<User[]>([]);
	const { data, error, isFetching } = useFetchQueries(prId);

	useEffect(() => {
		if (data)
			setSharingManager([...data]);
	}, [data])
	
	if (error) return <div>Error: {errorHandler(error.message)}</div>;

	const handleChangeAccess = (value: PeopleAccesModes, user: User) => {
		setLoader(true);
		if (value !== 'Remove') {
			let model: SharedInvitationsUpdateModel = {
				accessMode: value,
				email: user.email
			};

			(user.isActual ? ApiRequests.Project.Sharings.Actual.update(prId, user.id, model) :
				ApiRequests.Project.Sharings.Invitations.update(prId, model))
				.then(() => {
					const updatedModel = [...sharingManager].map((e) =>
						e.email === user.email ? { ...e, accessMode: value } : e
					);
					setSharingManager(updatedModel);
				}).catch((err) => {
					message.error(errorHandler(err), 25);
				}).finally(() => {
					setLoader(false);
				})
		} else {
			let model: SharedInvitationsDeleteModel = {
				email: user.email
			};

			(user.isActual ? ApiRequests.Project.Sharings.Actual.delete(prId, user.id) :
				ApiRequests.Project.Sharings.Invitations.delete(prId, model))
				.then(() => {
					const updatedData = [...sharingManager].filter(u => u.email !== user.email);
					setSharingManager(updatedData);
				}).catch((err) => {
					message.error(errorHandler(err), 25);
				}).finally(() => {
					setLoader(false);
				})
		}

	};

	return (
		<>
			{loader && (
				<Spin className="full-page-spinner" fullscreen />
			)}

			{(!error && sharingManager && !isFetching) ? (
				<>
					<div className="mt-15 mb-10 text-semibold" key={'label-people-access'}>People With Access:</div>

					{sharingManager.map((user) => (
						<div className={"mb-10"} key={user.email}>
							<Row gutter={16} wrap={false}>
								<Col className="gutter-row" flex="auto">
									<PopoverContent
										name={user.name}
										email={user.email}
										accessMode={user.accessMode}
									/>
								</Col>
								<Col className="gutter-row" flex="none">
									<div className='hbox endbox topbox'>
										<Select
											options={accessOptions}
											disabled={user.accessMode === 'Owner'}
											value={user.accessMode}
											style={{ width: 120 }}
											onChange={(x) => handleChangeAccess(x, user)}
										/>
									</div>
								</Col>
							</Row>
						</div>
					))}

				</>
			) : null}
		</>
	);
}
