import { Tabs } from "antd";
import { IPopupModel, OrdersModalTabsType } from "../models/orders";
import OrderDetailTab from "./OrderDetail";
import { useState } from "react";
import Payments from "./Payments";

interface Props {
	activePanel: OrdersModalTabsType;
	data: IPopupModel
	paymentId?: number
}

export default function OrdersTabs({ activePanel, data, paymentId }: Props) {
	const [selectedPaymentId, setSelectedPaymentId] = useState<number | null>(paymentId ?? null);
	const items: { key: OrdersModalTabsType; label: React.ReactNode, children: JSX.Element }[] = [
		{
			key: "details",
			label: "Order details",
			children: <OrderDetailTab data={data} />
		},
		{
			key: "payments",
			label: "Payments",
			children: <Payments data={data.payments} paymentId={selectedPaymentId} />
		}
	];

	return (
		<>

			<Tabs items={items} defaultActiveKey={activePanel} destroyInactiveTabPane={true} onChange={(key) => setSelectedPaymentId(null)} />

		</>
	);
}