import { useEffect, useRef, useState } from "react";
import { Modal, Tag, Input, message, Button } from "antd";
import { InputRef } from "antd/es/input/Input";
import { errorHandler, getFirstNchars } from "../../../../utils/globals";
import ApiRequests from "../../../../utils/api-requests";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ProjectNoteUpdateModel } from "../../../../models/api/notes";

interface Props {
	noteToUpdateTags: number;
	projectId: number;
	setNoteToUpdateTags: (value: number | null) => void;
}

export default function TagsModal({ noteToUpdateTags, projectId, setNoteToUpdateTags }: Props) {
	const inputRef = useRef<InputRef>(null);

	const [inputValue, setInputValue] = useState('');
	const [tags, setTags] = useState<string[]>();

	const queryClient = useQueryClient();

	const handleOnCloseModal = () => {
		setNoteToUpdateTags(null);
		setTags(undefined);

	}

	const readNote = useQuery({
		queryKey: ["readNote"],
		queryFn: () => {
			if (noteToUpdateTags) {
				return ApiRequests.Project.Notes.read(projectId, noteToUpdateTags);
			}
		},
		enabled: !!noteToUpdateTags
	});

	const updateTags = useMutation({
		mutationKey: ["updateTags"],
		mutationFn: async ({ rid, nid, model }: { rid: number; nid: number; model: ProjectNoteUpdateModel }) => {
			return ApiRequests.Project.Notes.update(rid, nid, model);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["projectNotes"] })
			handleOnCloseModal();
		},
		onError: (error) => {
			message.error(errorHandler(error.message));
		}
	});


	const handleUpdateTags = (tags: string[] | null) => {

		if (tags) {
			if (noteToUpdateTags && readNote.data) {
				const model: ProjectNoteUpdateModel =
				{
					...readNote.data,
					tags: tags.map((tag) => tag.toLowerCase())
				};

				updateTags.mutateAsync({
					rid: projectId,
					nid: noteToUpdateTags,
					model: model,
				});
			}
		}
	};

	const handleCloseTag = (removedTag: string) => {
		if (tags) {
			setTags(tags.filter((tag) => tag !== removedTag));
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value.toLowerCase());
	};

	const handleInputConfirm = () => {
		if (tags && inputValue && !tags.includes(inputValue)) {
			setTags([...tags, inputValue.toLowerCase()]);
		}
		setInputValue('');
	};

	useEffect(() => {
		setTags(readNote.data?.tags);
	}, [readNote.data]);

	return (
		<>
			{readNote.data && noteToUpdateTags &&
				<Modal
					destroyOnClose
					open={!!noteToUpdateTags}
					title={getFirstNchars(readNote.data?.text, 55) + "..."}
					onClose={handleOnCloseModal}
					onCancel={handleOnCloseModal}
					onOk={() => handleUpdateTags(tags ? tags : null)}
				>
					<div className="mb-15 mt-25" style={{ display: "flex", gap: "0.5rem" }}>
						<Input
							ref={inputRef}
							type="text"
							style={{ flexGrow: 1 }}
							value={inputValue}
							onChange={handleInputChange}
							onBlur={handleInputConfirm}
							onPressEnter={handleInputConfirm}
							placeholder="Add A New Tag"
						/>
						<Button
							icon={<span className="ficon ficon-plus txt-large"></span>}

						/>
					</div>
					{tags &&
						<div className="mb-25">
							{tags.map((tag) => (
								<Tag key={tag} closable onClose={() => handleCloseTag(tag)}>
									{tag}
								</Tag>
							))}
						</div>}

				</Modal>}
		</>
	);

}
