import React from "react";
import Title from "antd/es/typography/Title";
import Sharings from "../sharings/Sharings";
import { ProjectSharingInfo } from "../../../../models/api/sharings";
import EditableTitle from "../EditableTitle";

interface IsFollowUpModel {
    projectTitle: string;
    sharingInfo: ProjectSharingInfo;
    projectId: number;
    onTitleUpdated: (title: string) => void;
}
export default function IsFollowUp({ projectTitle, sharingInfo, projectId, onTitleUpdated,}: IsFollowUpModel) {
    return (
        <div
            style={{
                backgroundColor: "#e5e5e5",
                padding: "0.25rem 0.25rem 0 0.25rem",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    <span className="ficon ficon-share-2" style={{ marginRight: "0.75rem", fontSize: "1.25rem" }}/>
                    <span style={{ fontSize: "0.75rem", color: "#707070" }}>
                        Work by <span style={{ fontWeight: "600" }}>{sharingInfo.owner.name}</span> ({sharingInfo.owner.email}) on
                    </span>

                    <EditableTitle
                        projectTitle={projectTitle}
                        projectId={projectId}
                        isOwner={sharingInfo.isOwner}
                        onTitleUpdated={onTitleUpdated}
                    />
                </div>

                <Sharings projectId={sharingInfo.parentId} accessMode={sharingInfo.accessMode}
                />
            </div>
            <div
                style={{
                    borderBottom: "1px solid var(--extra-dark-gray)",
                    marginBottom: "1.25rem"
                }}
            />
        </div>
    );
}
