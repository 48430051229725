interface TagsListProps {
	tags: string[];
	title: string;
}

export default function TagsList({ tags, title }: TagsListProps): JSX.Element {

	return (
		<>
			{tags.length > 0 &&
				(
					<div className="txt-small">
						<span>{title} </span>
						{tags.map((tag, i) => (
							<span key={`tag-${tag}-${i}`}>
								<span className="text-light-gray" > {tag} </span> {tags.length !== 1 ? "|" : ""}
							</span>
						))}
					</div>
				)
			}
		</>
	);
}