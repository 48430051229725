import { Col, Row, Table } from "antd";
import { IPopupModel } from "../models/orders";


interface Props {
	data: IPopupModel
}

export default function OrderDetailTab({
	data,
}: Props) {
	const columns = [
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			width: 1000,

		},
		{
			title: "Storage",
			dataIndex: "storageAsText",
			key: "storageAsText",
			width: 200,
			sorter: false,
		},
		{
			title: "Questions",
			dataIndex: "questionsAsText",
			key: "questionsAsText",
			width: 200,
			sorter: false,
		},
		{
			title: "Price",
			dataIndex: "priceAsText",
			key: "priceAsText",
			width: 200,
			sorter: false,
		},
	];

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<strong>Order #:</strong>
					<span className="ml-10">{data.orderId}</span>

				</Col>
				<Col span={12}>
					<strong>Order Date:</strong>
					<span className="ml-10">{data.orderDateText}</span>
				</Col>

				<Col span={12}>
					<strong>Name:</strong>
					<span className="ml-10">{data.member.name}</span>

				</Col>
				<Col span={12}>
					<strong>Payment Method:</strong>
					<span className="ml-10">{data.payWithName}</span>
				</Col>
				<Col span={12}>
					<strong>Email:</strong>
					<span className="ml-10">{data.member.email}</span>

				</Col>
				<Col span={12}>
					<strong>Status:</strong>
					<span className="ml-10">{data.statusName}</span>
				</Col>
				<Col span={12}>
				</Col>
			</Row>

			<h3>Items</h3>
			<Table
				locale={{ emptyText: 'No Licenses' }}
				columns={columns}
				dataSource={data.items || []}
				pagination={false}
				rowKey={(record) => record.position}
				summary={() => (
					<Table.Summary.Row>
						<Table.Summary.Cell align={'right'} index={0}>
							<span style={{ fontWeight: 'bold' }}>Total:</span>
						</Table.Summary.Cell>
						<Table.Summary.Cell index={1}>
							<span style={{ fontWeight: 'bold' }}>{data.total?.storage}</span>
						</Table.Summary.Cell>
						<Table.Summary.Cell index={2}>
							<span style={{ fontWeight: 'bold' }}>{data.total?.questions}</span>
						</Table.Summary.Cell>
						<Table.Summary.Cell index={3}>
							<span style={{ fontWeight: 'bold' }}>{data.total?.price}</span>
						</Table.Summary.Cell>
					</Table.Summary.Row>
				)}
			/>


			{data ?
				<div className="mt-20">

					<Row gutter={[12, 12]}>
						<Col span={6}></Col>
						<Col span={6}>
							<div className="box-item text-bold">Initial Order Payment</div>
						</Col>
						<Col span={6}>
							<div className="box-item text-right">{data.orderConditionInitialDateText}</div>
						</Col>

						<Col span={6}>
							<div className="box-item text-bold text-right">{data.orderConditionInstantPaymentAsText}</div>
						</Col>

						<Col span={6}></Col>
						<Col span={6}>
							<div className="box-item text-bold">{data.recurrency} payment from:</div>
						</Col>
						<Col span={6}>
							<div className="box-item text-right">{data.orderConditionDateAsText}</div>
						</Col>

						<Col span={6}>
							<div className="box-item text-bold text-right">{data.priceAsText}</div>
						</Col>
					</Row>

				</div> : null
			}

		</>
	);

}
