import { Alert, Button, Col, Modal, Row } from "antd";
import { useState } from "react";
import InviteUser from "./InviteUser";
import { useQuery } from "@tanstack/react-query";
import ApiRequests from "../../../../utils/api-requests";
import { ProjectAccessModes } from "../../../../models/api/shared";
import PeopleAccess from "./PeopleAccess";
import { errorHandler } from "../../../../utils/globals";
import GeneralAccess from "./GeneralAccess";
import CopyLink from "./CopyLink";

interface Props {
	prId: number;
	onClose: () => void;
}

export interface AccessModes {
	value: ProjectAccessModes;
	label: string
}

export default function SharingManager({ prId, onClose }: Props) {
	const [showInviteUser, setShowInviteUser] = useState<boolean>(false);

	const getAccesModes = useQuery({
		queryKey: ['shar-access-modes', prId],
		queryFn: () => ApiRequests.Enums.projectAccessModes(),
		select: (data) => {
			const workingData: AccessModes[] = [...data].map(item => ({
				value: item.value,
				label: item.text,
			}))

			return workingData;
		},
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
	});

	return (
		<Modal
			open={true}
			onCancel={onClose}
			destroyOnClose
			width={800}
			//styles={{ body: { minHeight: '400px' } }}
			title={showInviteUser ? 'Invite User' : `Share this Project`}
			footer={null}
		>
			{(getAccesModes.isError) ? (
				<Alert
					message="Error"
					description={errorHandler(getAccesModes.isError)}
					type="error"
					showIcon
				/>)
				: null}

			{!getAccesModes.isFetching && getAccesModes.data && !showInviteUser ? (
				<>
					<Button onClick={() => { return setShowInviteUser(true) }} className='btn-h-large' type="primary">
						Add Users
						<span className='ficon ficon-user-plus fspace-l'></span>
					</Button>
					<PeopleAccess prId={prId} accesModes={[...getAccesModes.data]} key={'people-access-c'}></PeopleAccess>
					<GeneralAccess prId={prId} key={'general-access'}></GeneralAccess>

					<Row className={'mt-20'} wrap={false}>
						<Col className="gutter-row" flex="auto">
							<CopyLink prId={prId} key={'iu-copy-link'}></CopyLink>
						</Col>
						<Col className="gutter-row" flex="none">
							<div className='hbox endbox topbox'>
								<Button onClick={onClose}>
									Done
								</Button>
							</div>
						</Col>
					</Row>
				</>
			) : null}

			{getAccesModes.data && showInviteUser ? (
				<>
					<InviteUser key={'invite-users'} prId={prId}
						accesModes={[...getAccesModes.data]}
						onCancel={() => setShowInviteUser(false)}
						onCloseModal={onClose}></InviteUser>
				</>
			) : null
			}
		</Modal>
	);
}
