import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import { HelpTabs } from "../../models/api/shared";
import HelpSider from "./components/HelpSider";
import { useContext } from "react";
import { MobileContext } from "../../components/PageLayout";

interface Props {
	activeTab: HelpTabs;
	children: React.ReactNode;
}

export default function HelpLayout({ activeTab, children }: Props) {
	const isMobile = useContext(MobileContext);

	return (
		<div>
			<Title className="mb-30 page-title">TAVA Help Files</Title>
			<Layout hasSider>
				<HelpSider activeTab={activeTab} />
				<Content className={`${isMobile && 'pl-60'}`}>
					{children}
				</Content>
			</Layout>
		</div>
	);
}
