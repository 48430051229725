import { Dropdown, Popover, message } from "antd";
import PopoverContent from "./SharingsPopover";
import SharingsDropdown from "./SharingsDropdown";
import { getNameInitials } from "../../../../utils/globals";
import { User } from "../../models/sharings";
import { ProjectAccessModes } from "../../../../models/api/shared";
import { PageRoutes } from "../../../../utils/pageRoutes";

interface Props {
	index: number;
	sharedUser: User;
	accessMode: ProjectAccessModes;
}

export default function SharedUser({ index, sharedUser }: Props) {

	const handleUserClick = () => {

		if (sharedUser.projectId) {
			window.location.href = PageRoutes.goToProject(sharedUser.projectId);
		}
		else {
			message.warning("User doesn't have a discovery yet");
		}
	}

	return (
		<Popover
			content={
				<PopoverContent
					name={sharedUser.name}
					email={sharedUser.email}
					accessMode={sharedUser.accessMode}
				/>
			}
			key={sharedUser.id}
		>

			<div
				onClick={handleUserClick}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					padding: "1rem",
					height: "1.75rem",
					width: "1.75rem",
					borderRadius: "10rem",
					cursor: "pointer",
					zIndex: 10 - index,
					marginLeft: index > 0 ? "-0.5rem" : "0",
					boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
					backgroundColor:
						sharedUser.accessMode === "Owner"
							? "darkgreen"
							: sharedUser.accessMode === "Contributor"
								? "darkorange"
								: "blue",
					color: "white",
				}}
			>
				<p style={{ color: "white", fontWeight: "bold" }}>
					{getNameInitials(sharedUser.name || sharedUser.email)}
				</p>
			</div>

		</Popover>
	);
}
