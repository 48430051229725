import { HistoryProjectsContextModel } from "../../../components/HistoryProjectsContext";
import { getUserId } from "../../../utils/globals";

const ProjectLocalStorage = {
	createLastFive(data: HistoryProjectsContextModel[]) {
		localStorage.setItem(getSSKey(), JSON.stringify(data));
	},
	readLastFive() {
		const projects = localStorage.getItem(getSSKey());
		return projects ? JSON.parse(projects) as HistoryProjectsContextModel[] : [];
		
	},
}

export default ProjectLocalStorage;

function getSSKey(): string {
	return 'ss-last-seen-projs-' + getUserId();
}
