import { useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { DocumentsGridResultModel } from "../../../../models/api/documents";
import { Drawer, List, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


interface Props {
	query: UseQueryResult<DocumentsGridResultModel[]>;
	drawerState: {
		visible: boolean;
		setVisible: (value: boolean) => void;
		selectedDocumentId: number | null;
		setSelected: (id: number | null) => void;
	};
	actions: {
		read: (documentId: number) => void;
		edit: (documentId: number) => void;
		commentToggle: (noteId: number, title: string) => void;
		delete: (projectId: number, documentId: number) => void;
	};
	projectId: number;
}

export default function DocumentsList({ query, drawerState, actions, projectId }: Props) {
	const [documentTitle, setDocumentTitle] = useState("");

	const { visible, setVisible, selectedDocumentId, setSelected } = drawerState;
	const { read, edit, commentToggle, delete: deleteDocument } = actions;

	return (
		<div>
			<List loading={query.isFetching || query.isLoading}>
				{query.data?.map((document) => (
					<List.Item key={document.id} className="list-item-row">
						<div className="list-item-col">
							<span
								onClick={() => read(document.id)}
								className="force-link text-bold"
							>
								{document.name}
							</span>
							<div className="f-box gap-05 txt-small">
								<span className="text-gray">Created By:</span> {document.creator.name}
							</div>
							<div className="f-box gap-05 txt-small">
								<span className="text-gray">Type:</span> {document.documentTypeDisplayName}
							</div>
						</div>

						<div className="actions-wrap">
							{!document.isReady ? (
								<div className="f-box gap-05">
									<div className="txt-xsmall text-gray">Creating...</div>
									<Spin
										indicator={<LoadingOutlined spin />}
										size="small"
									/>
								</div>
							) : (
								<span
									className="ficon ficon-more-vertical link txt-large text-gray"							
									onClick={() => {
										setSelected(document.id);
										setDocumentTitle(document.name);
										setVisible(true);
									}}
								></span>
							)}
						</div>
					</List.Item>

				))}
			</List>

			{selectedDocumentId && (
				<Drawer
					open={visible}
					onClose={() => setVisible(false)}
					placement="bottom"
					className="mobile-context-wrap"
				>
					<div className="link" onClick={() => commentToggle(selectedDocumentId, documentTitle.slice(0, 60))}>
						<span className="ficon ficon-chat"></span> Comment
					</div>
					<div className="link" onClick={() => edit(selectedDocumentId)}>
						<span className="ficon ficon-edit"></span> Edit
					</div>
					<div className="link" onClick={() => deleteDocument(projectId, selectedDocumentId)}>
						<span className="ficon ficon-delete"></span> Delete
					</div>
				</Drawer>
			)}
		</div>
	);
}
