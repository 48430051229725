import { Button, Modal, Spin } from "antd";
import Title from "antd/es/typography/Title";
import { ProjectsGridTypes } from "../../../../models/api/projects/projects";
import { useArchvieProjectMutation } from "../../hooks/useArchvieProjectMutation";
import { useDeleteProjectMutation } from "../../hooks/useDeleteProjectMutation";
import { handleRecover } from "../../utils/handleRecover";

interface Props {
	modalVisible: boolean;
	setModalVisible: (value: boolean) => void;
	recordId: number;
	handleMutationSuccess: () => void;
	activeTab: ProjectsGridTypes;
}

export default function RecoverModal({
	modalVisible,
	setModalVisible,
	recordId,
	handleMutationSuccess,
	activeTab,
}: Props) {
	const recoverMutation = useArchvieProjectMutation({
		onSuccess: handleMutationSuccess,
	});

	const deleteMutation = useDeleteProjectMutation({
		onSuccess: handleMutationSuccess,
	});

	const handleRecoverClick = async () => {
		try {
			await handleRecover(recordId, activeTab, recoverMutation, deleteMutation);
			setModalVisible(false);
		} finally {
		}
	};

	return (
		<>
			{(recoverMutation.isPending || deleteMutation.isPending) && (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(255, 255, 255, 0.8)",
						zIndex: 1000,
					}}
				>
					<Spin size="large" />
				</div>
			)}
			<Modal
				title=""
				open={modalVisible}
				onCancel={() => setModalVisible(false)}
				footer={null}
			>
				<div style={{ textAlign: "center" }}>
					<Title level={4}>Are you sure you want to recover this project?</Title>
					<Button
						type="primary"
						onClick={handleRecoverClick}
					>
						Recover
					</Button>
					<Button
						style={{ marginLeft: "10px" }}
						onClick={() => setModalVisible(false)}
					>
						Cancel
					</Button>
				</div>
			</Modal>
		</>

	);
}
