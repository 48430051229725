import { Col, Row, Tooltip } from "antd";
import { IOrderPayment } from "../models/orders";
import { useState } from "react";

interface Props {
	data: {
		data: IOrderPayment[],
		paymentId: number
	},
	onClose: () => void;
}

interface IPaymentDetails {
	paymentId: number,
	paymentDateAsText: string,
	ammountAsText: string,
	transaction: string,
	check: string,
	payerName: string,
	payerEmail: string
}

function formatDetails(data: IOrderPayment[], paymentId: number): IPaymentDetails | null {
	var payment = data.find(x => x.paymentId === paymentId);
	if (!payment) return null;
	let detail: IPaymentDetails = {
		paymentId: payment.paymentId,
		paymentDateAsText: payment.paymentDateAsText ?? "",
		ammountAsText: payment.ammountAsText ?? "",
		transaction: payment.transaction,
		check: payment.check,
		payerName: payment.payerName,
		payerEmail: payment.payerEmail
	}
	return detail;
}

export default function PaymentsDetail({
	data, onClose
}: Props) {

	const [detailsData, setDetailsData] = useState<IPaymentDetails | null>(formatDetails(data.data, data.paymentId));

	return (
		<>

			{detailsData ? (
				<>

					<Tooltip title="Back to List">
						<div className="force-link" onClick={() => {
							setDetailsData(null);
							onClose()
						}}>
							<span className="ficon ficon-arrow-left-circle mr-05"></span>
							<span>Back to List</span>
						</div>
					</Tooltip>

					<Row className="mt-15 p-10" gutter={[16, 16]}>
						<Col span={12}>
							<strong>Payment  #:</strong>
							<span className="ml-10">{detailsData.paymentId}</span>

						</Col>
						<Col span={12}>
							<strong>Check #:</strong>
							<span className="ml-10">{detailsData.check}</span>
						</Col>
						<Col span={12}>
							<strong>Payment Date :</strong>
							<span className="ml-10">{detailsData.paymentDateAsText}</span>
						</Col>

						<Col span={12}>
							<strong>Payer name :</strong>
							<span className="ml-10">{detailsData.payerName}</span>

						</Col>
						<Col span={12}>
							<strong>Amount :</strong>
							<span className="ml-10">{detailsData.ammountAsText}</span>
						</Col>
						<Col span={12}>
							<strong>Payer email :</strong>
							<span className="ml-10">{detailsData.payerEmail}</span>

						</Col>
						<Col span={12}>
							<strong>Transaction #:</strong>
							<span className="ml-10">{detailsData.transaction}</span>
						</Col>
						<Col span={12}>
						</Col>
					</Row>
				</>
			) :
				null
			}
		</>


	);
}
