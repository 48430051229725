import { message } from "antd";
import { AxiosError } from "axios";
import { SortInfo, SortTypes } from "../models/api/shared";
import { SorterResult } from "antd/es/table/interface";

export const toDate = (value: Date | string): string => {
	return (value) ? new Date(value).toLocaleDateString(undefined, { dateStyle: 'short' } as Intl.DateTimeFormatOptions) : '';
}

export const toDateTime = (value: Date | string): string => {
	return (value) ? new Date(value).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' } as Intl.DateTimeFormatOptions) : '';
}

const toDecimal = (value: any, digits: number): string => {
	return (value)
		? parseFloat(value).toLocaleString(undefined,
			{
				minimumFractionDigits: digits,
				maximumFractionDigits: digits,
				style: 'decimal'
			})
		: '';
}

export const toStorage = (value: number, toFixed: number = 2): string => {
	if (value === 0) return '0 GB';
	return (value) ? toDecimal(value / 1073741824, toFixed) + ' GB' : '';
}

export const toCurrency = (value: number) => {
	return (value) ? parseFloat(value.toString()).toLocaleString(undefined,
		{
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			currency: 'USD',
			style: 'currency'
		}) : '';
}

export const newLineToBr = (value: string) => {
	return value.replace(/(\r\n|\r|\n)/g, '<br>')
}

export function getUserId() {
	return document.getElementById('root')?.getAttribute('userid') as string
}

export function getInitials(name: string) {
	let initial = name.match(/\b\w/g);
	return initial?.join("").substring(0, 2).toUpperCase();
}

function isAxiosError(error: any): error is AxiosError {
	return error.isAxiosError === true;
}

export const stripHTMLTags = (html: string) => {
	const tmp = document.createElement("div");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
};
function getBadrequestKeys(error: Object) {
	return Object.keys(error).map(x => `${Reflect.get(error, x)}`);
}
function getBadRequest(error: any) {
	if (error.errors)
		return getBadrequestKeys(error.errors);
	return getBadrequestKeys(error);
}

export const errorHandler = (error: Object): string => {
	let globalMsg = "An error occurred while processing your request,"
		+ " Please try again. If the error still persist please contact the support team.";

	if (isAxiosError(error) && error.response) {
		if (error.response?.status >= 500 && error.response?.status < 600)
			return globalMsg;

		switch (error.response?.status) {
			case 400:
				if (error.response.data) {
					if (typeof error.response.data === 'string') {
						return error.response.data;

					} else {
						return getBadRequest(error.response.data).join(' | ');
					}
				}

				return "Bad Request (HTTP code 400) response.";
			case 403:
				return "Access denied. You do not have access to this resource.";
			case 404:
				return "Not Found. The server can not find requested resource.";
			case 405:
				return "Method Not Allowed. The request method is known by the server but has been disabled and cannot be used. For example, an API may forbid DELETE-ing a resource.";
			case 409:
				if (typeof error.response.data === 'string')
					return error.response.data;
				else return "A conflict in your request has accured, ";
		}
	}
	return globalMsg;
}

export const fileToBase64 = (file: File): Promise<string> => {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => {
			reject(error);
			message.error("Failed to convert file to Base64");
		};
	});
};

export const copyToClipboard = (text: string | undefined) => {
	if (text) {
		navigator.clipboard.writeText(text).then(
			() => {
				message.success("Copied!");
			},
			() => {
				message.error("Failed to copy text");
			}
		);
	}
	else {
		message.error("Trouble copying text, try again");
		return;
	}
}


export const handleTableSort = <T>(
	componentSortInfo: SortInfo<T>,
	tableSorter: any,
	setComponentSortInfo: React.Dispatch<React.SetStateAction<SortInfo<T>>>
) => {
	const typedSorter = tableSorter as SorterResult<T>;
	let newSortType: SortTypes = SortTypes.Asc;

	if (componentSortInfo.sortColumn === typedSorter?.columnKey) {
		newSortType = componentSortInfo.sortType === SortTypes.Asc ? SortTypes.Desc : SortTypes.Asc;
	}

	setComponentSortInfo({
		sortColumn: typedSorter?.columnKey as T,
		sortType: newSortType,
	});
};

export function getFirstNchars(str: string, maxChars: number) {
	if (!str)
		return "";

	let txt = str.length <= maxChars ? str : str.substring(0, maxChars);
	return txt.trim();
}

export function getNameInitials(name: string) {
	const words = name.trim().split(" ");
	const initials = words.length > 1
		? `${words[0][0]}${words[1][0]}`
		: words[0][0];

	return initials.toUpperCase();
}

