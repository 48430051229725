import { useState } from 'react';
import { Button, Popconfirm, Spin, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import ApiRequests from '../../../../utils/api-requests';
import { errorHandler } from '../../../../utils/globals';
interface QuestionDelete {
	questionId: number,
	projectId: number,
	onDeleteToggle: (id: number) => void;
}

export default function DeleteQuestion({ questionId, projectId, onDeleteToggle }: QuestionDelete) {
	const [loading, setLoading] = useState(false);

	const deleteMutation = useMutation({
		mutationFn: async () => ApiRequests.AskQuestions.delete(projectId, questionId),
		onSuccess: async () => {
			setLoading(false)
			message.success('Question has been deleted.');
			onDeleteToggle(questionId)
		},
		onError: (error) => {
			setLoading(false)
			message.error(errorHandler(error), 15);
		},
	});

	const confirm = () => {
		setLoading(true)
		deleteMutation.mutate();
	}

	return (
		<Popconfirm
			title="Delete Question"
			description="Are you sure you want to delete this question?"
			onConfirm={confirm}
			okButtonProps={{ loading: loading }}
		>

			<Button type="text" className="action-btn">
				<span className='ficon ficon-close'></span>
			</Button>

			{loading && <Spin className='full-page-spinner' fullscreen />}
		</Popconfirm>

	);
};
