import React, { createContext, useContext, useState, ReactNode } from 'react';
import ProjectLocalStorage from '../pages/project/utils/local-storage';

export interface HistoryProjectsContextModel {
    prId: number,
    prName: string
}

interface HistoryProjContextProps {
    projects: HistoryProjectsContextModel[]
    setProjects: (
        projects: HistoryProjectsContextModel[] | ((prevProjects: HistoryProjectsContextModel[]) => HistoryProjectsContextModel[])
    ) => void;
}

interface HistoryProjectsProviderProps {
    children: ReactNode;
}

const HistoryProjectsContext = createContext<HistoryProjContextProps>({
    projects: [],
    setProjects: () => { },
});

export const HistoryProjectsProvider: React.FC<HistoryProjectsProviderProps> = ({ children }) => {
    const last3 = ProjectLocalStorage.readLastFive();
    const [projects, setProjects] = useState<HistoryProjectsContextModel[]>(last3)
    return (
        <HistoryProjectsContext.Provider value={{ projects, setProjects }}>
            {children}
        </HistoryProjectsContext.Provider>
    );
};

export const useHistoryProjects = () => {
    const context = useContext(HistoryProjectsContext);
    if (!context) {
        throw new Error('HistoryProjectsContext must be used within a HistoryProjectsProvider');
    }
    return context;
};