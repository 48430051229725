import { Col, Row } from "antd";
import { toCurrency, toDate } from "../../../../../../../utils/globals";

interface PaymentDetailsProps {
	instantPaymentAsText: number | undefined;
	initialOrderDate: string;
	priceAsText: number;
	recurrency: string;
	startDate: string | undefined;
}

export default function PaymentDetails({
	instantPaymentAsText,
	priceAsText,
	initialOrderDate,
	recurrency,
	startDate,
}: PaymentDetailsProps) {
	return (
		<div className="mt-20">
			<Row gutter={[12, 12]}>
				<Col span={6}></Col>
				<Col span={6}>
					<div className="box-item text-bold">Initial Order Payment</div>
				</Col>
				<Col span={6}>
					<div className="box-item text-right">{toDate(initialOrderDate)}</div>
				</Col>
				<Col span={6}>
					<div className="box-item text-bold text-right">{toCurrency(instantPaymentAsText ?? 0)}</div>
				</Col>

				<Col span={6}></Col>
				<Col span={6}>
					<div className="box-item text-bold">{recurrency === "Month" ? "Monthly" : "Annual"} Payments From:</div>
				</Col>
				<Col span={6}>
					<div className="box-item text-right">{toDate(startDate ?? "No date")}</div>
				</Col>
				<Col span={6}>
					<div className="box-item text-bold text-right">{toCurrency(priceAsText)}</div>
				</Col>
			</Row>
		</div>
	);
}
