import { Tooltip } from "antd";
import { toDateTime } from "../../../../../../../utils/globals";
import { AdminOrdersResultModel, AdminOrdersSortColumns } from "../../../../../../../models/api/orders";
import { ColumnsType } from "antd/es/table";
import { SortInfo, SortTypes } from "../../../../../../../models/api/shared";
import { SortOrder } from "antd/es/table/interface";

type Props = {
	handlePurchaseClick: (orderId: number) => void;
	handleSubscriptionClick: (subscriptionId: string) => void;
	sortInfo: SortInfo<AdminOrdersSortColumns>;
};

const WaitingApprovalTableColumns = ({ handlePurchaseClick, handleSubscriptionClick, sortInfo }: Props): ColumnsType<AdminOrdersResultModel> => {
	return [
		{
			title: "Order ID",
			dataIndex: "orderId",
			key: "OrderId",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "OrderId" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "Order Date",
			dataIndex: "orderDate",
			key: "OrderDate",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "OrderDate" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: string) => <span>{toDateTime(text)}</span>,
		},
		{
			title: "Administrative Contact",
			dataIndex: ['administrativeContact', 'email'],
			key: "AdministrativeContact",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "AdministrativeContact" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (email: string, record: AdminOrdersResultModel) => (
				<Tooltip title="Click here to view subscription info">
					<span
						className="force-link"
						onClick={() => handleSubscriptionClick(record.administrativeContact.id)}
					>
						{email}
					</span>
				</Tooltip>
			),
		},
		{
			title: "Service Type",
			dataIndex: "serviceType",
			key: "ServiceType",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "ServiceType" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "Price",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Price" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (price: number) => `$${price}`,
		},
		{
			title: "",
			dataIndex: "purchase",
			key: "purchase",
			render: (text: string, record: AdminOrdersResultModel) => (
				<Tooltip title="Click to confirm payment of order">
					<span
						className="force-link"
						onClick={() => handlePurchaseClick(record.orderId)}
					>
						Payment
					</span>
				</Tooltip>
			),
		},
	];
};

export default WaitingApprovalTableColumns;
