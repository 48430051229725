import { Affix, Avatar, Button, Checkbox, Col, Divider, Flex, Row, Spin, Tooltip, Typography, message } from "antd";
import NoteContent from "./NoteContent";
import { useState, useEffect } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useMutation, useQuery } from "@tanstack/react-query";
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import ApiRequests from "../../../../utils/api-requests";
import { errorHandler } from "../../../../utils/globals";
import { DocumentTypes } from "../../../../models/api/shared";
import { DocumentCreateModel } from "../../../../models/api/documents";
import { ProjectNoteComponentListModel } from "./ProjectNotes";
import dayjs from "dayjs";
import TagsList from "./TagsList";
import React from "react";
import RelatedImages from "../../components/discovery/related-images";
import ContentSourceGallery from "./ContentSourceGallery";

interface ExportToDocumentModal {
	data: {
		prId: number;
		notes: ProjectNoteComponentListModel[];
		prName: string;
	};
	handleClose: () => void;
	handleCreateDocument: () => void;
	selectedTags?: string[];
}

interface DocumentTypesMap {
	text: string,
	key: DocumentTypes,
	img: string
}

export default function ExportToDocument({ data, handleCreateDocument, handleClose, selectedTags }: ExportToDocumentModal) {
	const [notes, setNotes] = useState<ProjectNoteComponentListModel[]>(data.notes);
	const [checkAll, setCheckAll] = useState<boolean>(false);
	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [showFinishTemplate, setShowFinishTemplate] = useState<boolean>(false);
	const [activeTemplateButton, setActiveTemplateButton] = useState<DocumentTypes>('Summary');
	const [isCreatingDocument, setIsCreatingDocument] = useState<boolean>(false);
	const [documentTypeMappings, setDocumentTypeMappings] = useState<DocumentTypesMap[]>([]);
	const indeterminate = selectedIds.length > 0 && selectedIds.length < notes.length;

	const documentTypesQuery = useQuery({
		queryKey: ["documentTypes"],
		queryFn: ApiRequests.Enums.documentTypes
	});

	useEffect(() => {
		if (documentTypesQuery.isSuccess) {
			const mappings = documentTypesQuery.data.map((item) => ({
				key: item.value,
				text: item.text,
				img: "/document.png"
			}));
			setDocumentTypeMappings(mappings);
		}

		if (documentTypesQuery.isError) {
			message.error(documentTypesQuery.error.message, 10);
		}
	}, [documentTypesQuery.isSuccess, documentTypesQuery.isError, documentTypesQuery.data, documentTypesQuery.error]);

	const UpdateReadingMode = useMutation({
		mutationFn: async ({ noteId }: { noteId: number }) => {
			const updatedNotes = notes.map((e) =>
				e.id === noteId ? { ...e, readingMore: e.readingMore ? false : true } : e
			);
			return updatedNotes;
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
		onSuccess: (notes) => {
			setNotes(notes);
		}

	});

	const createDocumentMutation = useMutation({
		mutationFn: async (model: DocumentCreateModel) => {
			return ApiRequests.Project.Documents.create(data.prId, model);
		},
		onError: (error) => {
			setIsCreatingDocument(false);
			message.error(errorHandler(error), 15);
		},
		onSuccess: () => {
			message.success('Document has been created.');
			handleCreateDocument();
		},
	});

	const handleUpdateReading = (noteId: number) => {
		UpdateReadingMode.mutate({ noteId: noteId });
	};

	const UpdateSelected = useMutation({
		mutationFn: async ({ note }: { note: ProjectNoteComponentListModel }) => {
			let selectedNotes: number[] = [];

			notes.map(e => {
				if (e.id === note.id) {
					if (note.checked)
						selectedNotes.push(note.id)

					return { ...e, checked: note.checked }
				} else {
					if (e.checked)
						selectedNotes.push(e.id)

					return e
				}
			});

			setSelectedIds(selectedNotes)
			setCheckAll(selectedNotes.length ? true : false)
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
	});

	const UpdateSelectall = useMutation({
		mutationFn: async ({ checked }: { checked: boolean }) => {
			let selectedNotes: number[] = [];

			const updatedNotes = notes.map(e => {
				if (checked)
					selectedNotes.push(e.id)

				return { ...e, checked: checked }
			});

			setSelectedIds(selectedNotes)
			return updatedNotes
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
		onSuccess: (notes) => {
			setNotes(notes);
		}
	});

	const onChange = (note: ProjectNoteComponentListModel, evt: CheckboxChangeEvent) => {
		note.checked = evt.target.checked
		UpdateSelected.mutate({ note: note });
	};

	const onCheckAllChange = (evt: CheckboxChangeEvent) => {
		setCheckAll(evt.target.checked)
		UpdateSelectall.mutate({ checked: evt.target.checked });
	};

	const onNextClick = () => {
		setActiveTemplateButton(documentTypeMappings[0].key);
		setShowFinishTemplate(true);
	}

	const onBackClick = () => {
		setShowFinishTemplate(false);
	}

	const handleSelectTemplBtnClick = (displayName: DocumentTypes) => {
		setActiveTemplateButton(displayName);
	};

	const handleDocumentTempleteModalOnFinish = () => {
		const time = dayjs().format("MM/DD/YYYY HH:mm");
		setIsCreatingDocument(true);
		const model: DocumentCreateModel = {
			name: `${data.prName} - ${time}`,
			documentType: activeTemplateButton,
			notes: selectedIds,
		};
		createDocumentMutation.mutate(model);
	};

	return (
		<>
			{isCreatingDocument && <Spin className='full-page-spinner' fullscreen />}
			{!showFinishTemplate ? (
				<>
					<div
						style={{ display: "flex", alignItems: 'baseline' }}
						className="gap-20"
					>

						<div>
							<Title level={5} >Choose which notes you'd like to include when building the document:</Title>
							{selectedTags && <TagsList title={"Selected Tags:"} tags={selectedTags}></TagsList>}
						</div>


						<div style={{ display: 'flex', alignItems: 'center', marginLeft: "auto" }}>
							<Tooltip title="Select All">
								<Checkbox className="force-link"
									style={{ flexDirection: "row-reverse" }}
									key={"checkAllNotes"}
									indeterminate={indeterminate}
									checked={checkAll}
									onChange={onCheckAllChange}
								>
									Select All
								</Checkbox>
							</Tooltip>
						</div>
					</div>

					<Divider />
					{!notes.length ? <Title level={5}>There are currently no existing notes!</Title> : <>
						{notes.map((note, i) => (
							<React.Fragment key={'exp-to-doc-' + i}>
								<div
									className={(note.checked ? "highlighted " : "") + "note-unit"}
								>
									<Flex gap="middle">
										<div className="hbox gap-10">
											<Avatar
												style={{
													backgroundColor: "green",
													verticalAlign: "middle",
												}}
												size={30}
												gap={1}
											>
												{note.initials}
											</Avatar>
											<div className="flex-item">
												<span>{note.modifier.name}</span>{" "}
												<span className="text-light-gray txt-small">
													({note.modifier.email})
												</span>
												<div className="text-light-gray txt-small">
													{note.modifier.modifiedAt}
												</div>
											</div>
										</div>

										<div className="hbox gap-05 endbox topbox">
											<Tooltip title="Export to Document">
												<Checkbox
													key={note.id}
													checked={note.checked}
													onChange={(e) => onChange(note, e)}
												>
												</Checkbox>
											</Tooltip>

										</div>
									</Flex>

									<ContentSourceGallery key={"CSG-exp-component"} note={note} handleUpdateReading={handleUpdateReading}></ContentSourceGallery>

									<TagsList title="Tags: " tags={note.tags} />
								</div>
								<Divider />
							</React.Fragment>
						))}
					</>}
					<Affix
						className='fix-to-bottom-wrap'
						style={{ bottom: 'var(--sticky-to-bottom-bar)' }}
						offsetBottom={0}>
						<div className='f-box endbox gap-10 p-20'>
							<Button type="primary" onClick={() => handleClose()}>
								<span className='ficon ficon-chevron-left'></span>
								Back
							</Button>
							<Button type="primary" disabled={selectedIds.length ? false : true} onClick={() => onNextClick()}>
								Next
								<span className='ficon ficon-chevron-right'></span>
							</Button>
							<Button type="primary" onClick={() => handleClose()}>
								Cancel
							</Button>
						</div>
					</Affix>
				</>
			) : (
				<>
					<Title level={5}>Choose your document creation template</Title>
					<Divider />
					<Paragraph className="txt-bold txt-small">
						TAVA will create content based on the format you select. You can then continue to edit your formatted content and share your document.
					</Paragraph>

					<Row gutter={[6, 6]} style={{ width: "100%" }}>
						{documentTypeMappings.map((e, index) => (
							<Col span={12} key={index}>
								<Tooltip placement="bottom" title={`Create a ${e.text}`}>
									<Button
										onClick={() => handleSelectTemplBtnClick(e.key)}
										className={activeTemplateButton === e.key ? 'panel-btn selected' : 'panel-btn'}
									>
										<div className="thumb-wrap">
											<img
												src={e.img}
												alt="document icon"
											/>
										</div>
										<span className='text-wrap'>{e.text}</span>
									</Button>
								</Tooltip>
							</Col>
						))}
					</Row>
					<Divider />
					<Affix
						className='fix-to-bottom-wrap'
						style={{ bottom: 'var(--sticky-to-bottom-bar)' }}
						offsetBottom={0}>
						<div className='f-box endbox gap-10 p-20'>
							<Button type="primary" style={{ marginLeft: 10 }} onClick={() => onBackClick()}>
								<span className='ficon ficon-chevron-left'></span>
								Back
							</Button>
							<Button type="primary" onClick={() => handleDocumentTempleteModalOnFinish()}>
								Finish
							</Button>
							<Button type="primary" onClick={() => handleClose()}>
								Cancel
							</Button>
						</div>
					</Affix>
				</>
			)}
		</>
	);
}