import { Button, Flex, AutoComplete, Space, Tag, Tooltip } from 'antd';
import { useState } from 'react';

interface FilterProps {
    autocomplValues: string[];
    selectedTags: string[];
    returnValues: (values: string[]) => void;
}

export default function Filter({ autocomplValues, returnValues, selectedTags }: FilterProps): JSX.Element {
    const [tags, setTags] = useState<string[]>(selectedTags);
    const [inputValue, setInputValue] = useState('');

    const handleInputConfirm = (val: string) => {
        let newVal: string[] = [];
        if (val && !tags.includes(val)) {
            newVal = [...tags, val.trim().toLowerCase()];
            setTags(newVal);
            returnValues([...newVal]);
            setInputValue('');
        } else setInputValue('');
        
    };

    const handleClose = (tag: string) => {
        let currentTag = tag.toLowerCase();
        let newVal = [...tags].filter((item) => item !== currentTag);
        setTags(newVal);
        returnValues(newVal);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleInputConfirm(inputValue);
        }
    };

    return (
        <>
            <Space direction="horizontal">
                <AutoComplete
                    value={inputValue}
                    onChange={(value) => {
                        setInputValue(value)

                    }}
                    options={autocomplValues.map(value => ({ value }))}
                    placeholder="Add Tag..."
                    style={{ width: 400 }}
                    onKeyUp={handleKeyDown}
                    onSelect={(value) => {
                        setInputValue(value);
                    }}
                    filterOption={(inputValue, option) =>
                        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                />
                <Button type="primary" onClick={() => { handleInputConfirm(inputValue) }}>
                    Filter
                </Button>
            </Space>

            <Flex gap="4px 0" className={'mt-10'} wrap>
                {tags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                        <Tag
                            key={tag + index}
                            closable={true}
                            style={{ userSelect: 'none' }}
                            onClose={() => {
                                handleClose(tag);
                            }}
                        >
                            <span>{isLongTag ? `${tag.slice(0, 20)}...` : tag}</span>
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}
            </Flex>
        </>
    );
}