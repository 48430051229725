import HelpLayout from "../Home";

export default function GettingStarted() {
	return (
		<HelpLayout activeTab="GettingStarted">
			<div className='help-margin-left'>
				<h3>TAVA Discovery</h3>
				<p>
					<b>TAVA Discovery</b> is a research platform that leverages AI-driven Q&A elements, automated global search, and topic monitoring capabilities. It features note-taking and data
					tagging, along with both template-based and manual document generation.
				</p>
				<p>
					All these productivity-enhancing tools work seamlessly within team collaborative environment,
					facilitating data and information gathering, document generation, and sharing.
				</p>
				<p>
					The platform is ideal for creating, managing, and archiving research-based projects. It retains and
					archives all collected and created data, and supports the creation of documents and other output materials based on each specific project.
				</p>
			</div>
		</HelpLayout>
	);
}
