import { Alert, Button, Spin, Table, } from "antd";
import Modal from "antd/es/modal/Modal";
import { errorHandler, toCurrency, toStorage } from "../../../utils/globals";
import { useQuery } from "@tanstack/react-query";
import ApiRequests from "../../../utils/api-requests";
import { ServiceCatalogViewModel } from "../../../models/api/purchase";

interface Props {
	handleBuyLicense: (catalogId: number) => void;
	onClose: () => void;
}

export default function UpgradeLincese({
	handleBuyLicense,
	onClose,
}: Props) {


	const purchaseData = useQuery({
		queryKey: ['purchase-catalogue'],
		queryFn: () => ApiRequests.Purchase.catalogue(),
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
	});

	const columns = [
		{
			title: "License (title)",
			dataIndex: "name",
			key: "name",
			width: 1000,
			render: (name: string) => {
				return <span>
					{name}
				</span>
			}
		},
		{
			title: "Storage",
			dataIndex: "diskSpace",
			key: "diskSpace",
			render: (storage: number) => {
				return <>{toStorage(storage)}</>
			},
		},
		{
			title: "Questions",
			dataIndex: "questions",
			key: "questions",
			render: (questions: number) => {
				return <>{questions}</>
			},
		},
		{
			title: "Montly price",
			dataIndex: "monthPrice",
			key: "monthPrice",
			render: (monthPrice: number) => {
				return <>{toCurrency(monthPrice)}</>
			},
		},
		{
			title: "Year price",
			dataIndex: "yearPrice",
			key: "yearPrice",
			render: (yearPrice: number) => {
				return <>{toCurrency(yearPrice)}</>
			},
		},
		{
			title: "",
			dataIndex: "actionColumn",
			key: "actionColumn",
			render: (text: string, record: ServiceCatalogViewModel) => {
				return <div className="force-link" onClick={() => { handleBuyLicense(record.catalogId) }}>
					<span>subscribe</span>
				</div>
			},
		},
	];

	return (

		<>
			<Modal
				open={!purchaseData.isFetching}
				onCancel={onClose}
				destroyOnClose
				width={800}
				title={`Upgrade License`}
				footer={[
					<Button key="cancel" onClick={onClose}>
						Cancel
					</Button>,
				]}
			>
				{purchaseData.error ? (
					<Alert
						message="Error"
						description={errorHandler(purchaseData.error.message)}
						type="error"
						showIcon
					/>
				) : (

					<Table
						locale={{ emptyText: 'No Licenses' }}
						columns={columns}
						dataSource={purchaseData.data || []}
						loading={purchaseData.isFetching}
						//onChange={handleTableChange}
						pagination={false}
						rowKey={(record) => record.catalogId}
					/>

				)}
			</Modal>


			{purchaseData.isFetching && (
				<Spin className="full-page-spinner" fullscreen />
			)}
		</>
	);
}
