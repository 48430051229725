import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { Tabs } from "antd";
import ClientsWaitingForApproval from "../../tabs/subscriptions/tabs/waiting-for-approval/ClientsWaitingApproval";
import { useQueryClient } from "@tanstack/react-query";
import ActiveSubscriptions from "./tabs/active-subscriptions/ActiveSubscriptions";
import { loginRequest } from "../../../../authConfig";
import ExpiredSubscriptions from "./tabs/expired-subscriptions/ExpiredSubscriptions";
import AdministrationTabs from "../../components/Tabs";

export default function Subscriptions() {
    const { instance, inProgress } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const queryClient = useQueryClient();

    if (!activeAccount && inProgress === InteractionStatus.None) {
        instance.loginRedirect(loginRequest).catch(console.error);
    }

    const subscriptionTabitems = [
        {
            key: "1",
            label: "Active Subscriptions",
            children: <ActiveSubscriptions />,
        },
        {
            key: "2",
            label: "Clients Waiting For Approval",
            children: <ClientsWaitingForApproval />,
        },
        {
            key: "3",
            label: "Expired Subscriptions",
            children: <ExpiredSubscriptions />,
        },
    ];

    const handleTabChange = (key: string) => {
        switch (key) {
            case "1":
                queryClient.invalidateQueries({ queryKey: ['activeSubscriptions'] });
                break;
            case "2":
                queryClient.invalidateQueries({ queryKey: ['clientsWaitingForApproval'] });
                break;
            case "3":
                queryClient.invalidateQueries({ queryKey: ['expiredSubscriptions'] });
                break;
            default:
                break;
        }
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
        >
            <div>
                <AdministrationTabs activePanel={"subscriptions"} />
                <div>
                    <div>
                        <Tabs
                            items={subscriptionTabitems}
                            onChange={handleTabChange}
                        />
                    </div>
                </div>
            </div>
        </MsalAuthenticationTemplate>
    );
}
