import { useContext } from "react";
import StickyHelpBtn, { StickyHelpBtnProps } from "./StickyHelpBtn";
import { MobileContext } from "./PageLayout";

export default function StickyHelp({ popContent, title, goToHelpLink }: StickyHelpBtnProps) {

	const isMobile = useContext(MobileContext);

	return (
		<>
			{!isMobile && <StickyHelpBtn goToHelpLink={goToHelpLink} popContent={popContent} title={title}></StickyHelpBtn>}
		</>
	);
}