import { useContext, useState } from "react";
import { SharedProjectsGridResultModel, SharedProjectsGridSortColumns } from "../../../../../models/api/projects/projects";
import { SortInfo, SortTypes } from "../../../../../models/api/shared";
import { useQuery } from "@tanstack/react-query";
import ApiRequests from "../../../../../utils/api-requests";
import { TableProps, message } from "antd";
import { errorHandler, handleTableSort } from "../../../../../utils/globals";
import Table from "../../../../../components/antd-extensions/ExtendedTable";
import TableColumns from "./SharedTableColumns";
import { MobileContext } from "../../../../../components/PageLayout";
import ProjectsTabs from "../ProjectsTabs";
import SharedList from "./SharedList";
import StickyHelp from "../../../../../components/StyckyHelp";
import { PageRoutes } from "../../../../../utils/pageRoutes";

export default function Shared() {
	const [sortInfo, setSortInfo] = useState<SortInfo<SharedProjectsGridSortColumns>>({
		sortColumn: "ModifiedAt",
		sortType: SortTypes.Desc
	});
	const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);

	const isMobile = useContext(MobileContext);
	const [drawerVisible, setDrawerVisible] = useState(false);
	const helpContent: React.ReactNode = (
		<div key="sticky-help-btn-content">
			These are the projects shared with you by other TAVA users or team members. Once you start working on any shared project, your Q&A module will be separate and independent, although part of the same project. The notes and documents modules will be the same for all collaborating users.
		</div>
	);

	const sharedProjectsListQuery = useQuery({
		queryKey: ["sharedProjectsListQuery", sortInfo],
		queryFn: () =>
			ApiRequests.Project.sharedGrid({
				sortColumn: sortInfo.sortColumn,
				sortType: sortInfo.sortType,
			}),
	});

	if (sharedProjectsListQuery.isError) {
		message.error(errorHandler(sharedProjectsListQuery.error.message))
	}

	const handleTableChange: TableProps<SharedProjectsGridResultModel>['onChange'] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const columns = TableColumns({ sortInfo });

	return (
		<>
			<ProjectsTabs activeTab={"Shared"}></ProjectsTabs>
			{isMobile ?
				<SharedList
					query={sharedProjectsListQuery}
					drawerVisible={drawerVisible}
					setDrawerVisible={setDrawerVisible}
					setSelectedRecordId={setSelectedRecordId}
					selectedRecordId={selectedRecordId}
				/>
				:
				(
					<Table
						columns={columns}
						dataSource={sharedProjectsListQuery.data?.records}
						pagination={false}
						onChange={handleTableChange}
						loading={sharedProjectsListQuery.isFetching}
						rowKey="id"
					/>
				)}
			<StickyHelp goToHelpLink={PageRoutes.Overview()} popContent={helpContent} title={"Shared Projects"}></StickyHelp>
		</>
	);
}
