import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Spin, TableProps, message } from "antd";
import WaitingApprovalTableColumns from "./components/WaitingForApprovalColumns";
import ApiRequests from "../../../../../../utils/api-requests";
import { SortInfo, SortTypes } from "../../../../../../models/api/shared";
import { AdminOrdersResultModel, AdminOrdersSortColumns } from "../../../../../../models/api/orders";
import SubscriptionManager from "../../../../components/SubscriptionManager";
import ApprovalModal from "../components/ApprovalModal";
import Table from "../../../../../../components/antd-extensions/ExtendedTable";
import { errorHandler, handleTableSort } from "../../../../../../utils/globals";

export default function ClientsWaitingForApproval() {
	const [sortInfo, setSortInfo] = useState<SortInfo<AdminOrdersSortColumns>>({
		sortColumn: "OrderDate",
		sortType: SortTypes.Asc,
	});

	const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
	const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string | null>(null);
	const queryClient = useQueryClient();


	const handleTableChange: TableProps<AdminOrdersResultModel>['onChange'] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const waitingOrdersQuery = useQuery<AdminOrdersResultModel[]>({
		queryKey: ["clientsWaitingForApproval", sortInfo],
		queryFn: () =>
			ApiRequests.Orders.waitingApproval({
				sortColumn: sortInfo.sortColumn,
				sortType: sortInfo.sortType,
			}),
	});

	if (waitingOrdersQuery.isError) {
		message.error(errorHandler(waitingOrdersQuery.error.message));
	}

	const handlePurchaseClick = (orderId: number) => {
		setSelectedOrderId(orderId);
	};

	const handleSubscriptionClick = (subscriptionId: string) => {
		setSelectedSubscriptionId(subscriptionId);
	};

	const handleSuccsesfulPayment = () => {
		queryClient.invalidateQueries({ queryKey: ['clientsWaitingForApproval'] });
	}

	const columns = WaitingApprovalTableColumns({ handlePurchaseClick, handleSubscriptionClick, sortInfo });

	if (waitingOrdersQuery.isFetching) {
		return <Spin spinning={true} fullscreen />;
	}
	return (
		<>
			{selectedOrderId ? <ApprovalModal
				orderId={selectedOrderId}
				memberId={null}
				setSelectedOrderId={setSelectedOrderId}
				onSuccsesfulPayment={handleSuccsesfulPayment}
			/> : null}

			<Table
				columns={columns}
				dataSource={waitingOrdersQuery.data}
				rowKey="orderId"
				onChange={handleTableChange}
				pagination={false}
			/>

			{selectedSubscriptionId ?
				<SubscriptionManager key="subscription-modal" onClose={() => { setSelectedSubscriptionId(null) }}
					subscriptionId={selectedSubscriptionId} memberId={selectedSubscriptionId}></SubscriptionManager> : null}
		</>
	);
}
