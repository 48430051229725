import { Panel } from "../models/api/shared";

export class PageRoutes {

	static Home() {
		return "/";
	}

	static PersonalProjects() {
		return "/projects/personal";
	}
	static SharedProjects() {
		return "/projects/shared"
	}
	static ArchivedProjects() {
		return "/projects/archived"
	}
	static TrashedProjects() {
		return "/projects/trashed"
	}
	static Project() {
		return `/project/:id/:tab?`		
	}

	static ProjectNotes() {
		return `/project/:id/notes`
	}

	static ProjectDocs() {
		return `/project/:id/docs`
	}

	static goToProject(id: number, tab?: Panel) {
		return `/project/${id}` + (tab ? `?tab=${tab}` : '');
	}

	static goToProjectNotes(id: number) {
		return `/project/${id}/notes`;
	}

	static goToProjectDocs(id: number) {
		return `/project/${id}/docs`;
	}

	static projectsDocuments() {
		return `/projects/documents`;
	}

	private static settings: string = `/settings`;
	private static provider: string = `${this.settings}/provider`;
	private static administration: string = '/administration';
	private static help: string = '/help';

	static account() {
		return `${this.settings}/account`;
	}

	static license() {
		return `${this.settings}/license`;
	}

	static accountBilling() {
		return `${this.settings}/billing`;
	}

	static ProviderRejected() {
		return `${this.provider}/rejected`;
	}

	static ProviderApproved() {
		return `${this.provider}/approved`;
	}

	static subscriptions() {
		return `${this.administration}/subscriptions`;
	}

	static licenses() {
		return `${this.administration}/licenses`;
	}
	static payments() {
		return `${this.administration}/payments`;
	}
	static orders() {
		return `${this.administration}/orders`;
	}

	static HelpHome() {
		return `${this.help}`;
	}
	static AccountSettingsHelp() {
		return `${this.help}/account-settings`;
	}
	static DiscoveryHelp() {
		return `${this.help}/discovery`;
	}
	static NotesHelp() {
		return `${this.help}/notes`;
	}
	static DocumentsHelp() {
		return `${this.help}/documents`;
	}
	static ShareProjectHelp() {
		return `${this.help}/share-project`;
	}
	static Overview() {
		return `${this.help}/projects/overview`;
	}

}