import { Button, Checkbox, Col, Row, Select, Space, Spin, message } from "antd";
import React, { useRef, useState } from 'react';
import type { CheckboxProps, InputRef } from 'antd';
import { Flex, Input, Tag, Tooltip } from 'antd';
import { ProjectAccessModes } from "../../../../models/api/shared";
import ApiRequests from "../../../../utils/api-requests";
import TextArea from "antd/es/input/TextArea";
import { errorHandler } from "../../../../utils/globals";
import { AccessModes } from "./ SharingManager";
import { SharedInvitationsCreateModel } from "../../../../models/api/sharings";
import CopyLink from "./CopyLink";

interface Props {
	prId: number;
	accesModes: AccessModes[];
	onCancel: () => void;
	onCloseModal: () => void;
}

function formatAccessModes(accesModes: AccessModes[]) {
	const modes = accesModes.filter(item => item.value !== "Owner" && item.value !== "Restricted");
	return modes;
}

export default function InviteUser({ prId, onCancel, onCloseModal, accesModes }: Props) {
	const [loader, setLoader] = useState<boolean>(false);
	const [tags, setTags] = useState<string[]>([]);
	const [selectedAccess, setSelectedAccess] = useState<ProjectAccessModes>("Reader");
	const [inputValue, setInputValue] = useState('')
	const inputRef = useRef<InputRef>(null);
	const [notifyCbx, setNotifyCbx] = useState(true);
	const [msg, setMsg] = useState('');
	const [accessOptions] = useState<AccessModes[]>(formatAccessModes(accesModes));

	const handleClose = (removedTag: string) => {
		const newTags = tags.filter((tag) => tag !== removedTag);
		setTags(newTags);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleInputConfirm = () => {
		setLoader(true);

		if (inputValue && !tags.includes(inputValue)) {
			setTags([...tags, inputValue]);
		}
		setInputValue('');
		setLoader(false);
	};

	const handleChange = (value: ProjectAccessModes) => {
		setSelectedAccess(value)
	};

	const onChange: CheckboxProps['onChange'] = (e) => {
		setNotifyCbx(e.target.checked);
	};

	const handleSend = () => {
		setLoader(true);
		const model: SharedInvitationsCreateModel = {
			accessMode: selectedAccess,
			notifyPeople: notifyCbx,
			emails: tags.map(email => ({ email })),
			message: msg
		}

		ApiRequests.Project.Sharings.Invitations.create(prId, model)
			.then(() => {
				message.success('You have successfully updated your invitations', 25);
				onCloseModal();
				setLoader(false);
			})
			.catch((err) => {
				setLoader(false);
				message.error(errorHandler(err), 25);
			})

	};

	return (
		<>
			{loader && (
				<Spin className="full-page-spinner" fullscreen />
			)}

			{accessOptions.length ? (
				<>
					<Space direction="horizontal">
						<Input
							ref={inputRef}
							type="text"
							placeholder="Add people email"
							style={{ width: 400 }}
							//width={350}
							value={inputValue}
							onChange={handleInputChange}
							onBlur={handleInputConfirm}
							onPressEnter={handleInputConfirm}
						/>

						<Select
							value={selectedAccess}
							style={{ width: 120 }}
							onChange={handleChange}
							options={accessOptions}
						/>
					</Space>

					<Flex gap="4px 0" className={'mt-10'} wrap>

						{tags.map<React.ReactNode>((tag, index) => {
							const isLongTag = tag.length > 20;
							const tagElem = (
								<Tag
									key={tag}
									closable={true}
									style={{ userSelect: 'none' }}
									onClose={() => handleClose(tag)}
								>
									<span>
										{isLongTag ? `${tag.slice(0, 20)}...` : tag}
									</span>
								</Tag>
							);
							return isLongTag ? (
								<Tooltip title={tag} key={tag}>
									{tagElem}
								</Tooltip>
							) : (
								tagElem
							);
						})}
					</Flex>

					<div className={'mt-20'}>
						<Checkbox checked={notifyCbx} onChange={onChange}>
							Notify People
						</Checkbox>
						{notifyCbx ?
							<TextArea
								maxLength={500}
								hidden={!notifyCbx}
								value={msg}
								onChange={(e) => setMsg(e.target.value)}
								placeholder="Message"
								autoSize={{ minRows: 3, maxRows: 5 }}
							/> : null}
					</div>

					<Row className={'mt-20'} wrap={false}>
						<Col className="gutter-row" flex="auto">
							<CopyLink prId={prId} key={'iu-copy-link'}></CopyLink>
						</Col>
						<Col className="gutter-row" flex="none">
							<div className='hbox endbox topbox'>
								<Button disabled={!tags.length} type="primary" onClick={handleSend}>
									Send
								</Button>
								<Button
									style={{ marginLeft: '10px' }}
									onClick={onCancel}
								>
									Cancel
								</Button>
							</div>
						</Col>
					</Row>

				</>) : null
			}
		</>
	);
}
