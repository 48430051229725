import { TableProps, message } from "antd";
import { useContext, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ApiRequests from "../../../../../utils/api-requests";
import TrashedGridTableColumns from "./TableColumns";
import RecoverModal from "../../../components/Modals/RecoverModal";
import PermanentlyDeleteModal from "../../../components/Modals/PermamentlyDeleteModal";
import { ProjectsGridResultModel, ProjectsGridSortColumns } from "../../../../../models/api/projects/projects";
import { SortInfo, SortTypes } from "../../../../../models/api/shared";
import Table from "../../../../../components/antd-extensions/ExtendedTable";
import { errorHandler, handleTableSort } from "../../../../../utils/globals";
import { MobileContext } from "../../../../../components/PageLayout";
import ProjectsTabs from "../ProjectsTabs";
import TrashList from "./TrashList";
import StickyHelp from "../../../../../components/StyckyHelp";
import { PageRoutes } from "../../../../../utils/pageRoutes";
export default function Trashed() {
	const [sortInfo, setSortInfo] = useState<SortInfo<ProjectsGridSortColumns>>({
		sortColumn: "ModifiedAt",
		sortType: SortTypes.Desc
	});

	const [recoverModalVisible, setRecoverModalVisible] = useState(false);
	const [permanentDeleteModalVisible, setPermanentDeleteModalVisible] = useState(false);
	const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);

	const isMobile = useContext(MobileContext);
	const [drawerVisible, setDrawerVisible] = useState(false);
	const helpContent: React.ReactNode = (
		<div key="sticky-help-btn-content">
			"Projects in the <b>Trash</b> Bin can't be permanently deleted without a <b>30-days</b> safety period. You can recover a deleted project anytime during this period or before permanently deleting it. Once permanently deleted, a project cannot be recovered."
		</div>
	);

	const queryClient = useQueryClient();

	const projectsListQuery = useQuery({
		queryKey: ["trashedProjects", sortInfo],
		queryFn: () =>
			ApiRequests.Project.personalList({
				SortColumn: sortInfo.sortColumn,
				SortType: sortInfo.sortType,
				GridType: "Trash",
			}),
	});

	if (projectsListQuery.isError) {
		message.error(errorHandler(projectsListQuery.error.message))
	}

	const handleTableChange: TableProps<ProjectsGridResultModel>['onChange'] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const openRecoverModal = (id: number) => {
		setSelectedRecordId(id);
		setRecoverModalVisible(true);
	};

	const openPermanentDeleteModal = (id: number) => {
		setSelectedRecordId(id);
		setPermanentDeleteModalVisible(true);
	};

	const handleMutationSuccess = () => {
		queryClient.invalidateQueries({ queryKey: ['trashedProjects'] });
		setDrawerVisible(false);
		setSelectedRecordId(null);
	};

	const columns = TrashedGridTableColumns({ openRecoverModal, openPermanentDeleteModal, sortInfo });

	return (
		<>
			<ProjectsTabs activeTab={"Trash"}></ProjectsTabs>
			{isMobile ?
				<TrashList
					query={projectsListQuery}
					openRecoverModal={openRecoverModal}
					openPermanentDeleteModal={openPermanentDeleteModal}
					drawerVisible={drawerVisible}
					setDrawerVisible={setDrawerVisible}
					setSelectedRecordId={setSelectedRecordId}
					selectedRecordId={selectedRecordId}
				/>
				:
				<Table
					columns={columns}
					dataSource={projectsListQuery.data?.records}
					pagination={false}
					onChange={handleTableChange}
					loading={projectsListQuery.isFetching}
					rowKey="id"
				/>}

			{selectedRecordId && (
				<>
					<RecoverModal
						modalVisible={recoverModalVisible}
						setModalVisible={setRecoverModalVisible}
						recordId={selectedRecordId}
						handleMutationSuccess={handleMutationSuccess}
						activeTab={"Trash"}
					/>
					<PermanentlyDeleteModal
						modalVisible={permanentDeleteModalVisible}
						setModalVisible={setPermanentDeleteModalVisible}
						recordId={selectedRecordId}
						handleMutationSuccess={handleMutationSuccess}
					/>
				</>
			)}
			<StickyHelp goToHelpLink={PageRoutes.Overview()} popContent={helpContent} title={"Trashed Projects"}></StickyHelp>
		</>
	);
}
