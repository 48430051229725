import { Modal } from "antd";
import Comments from "./Comments";
import { ICommentsController } from "../../../../models/api/comments";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { ProjectAccessModes } from "../../../../models/api/shared";

interface ModalProps {
	type: ICommentsController;
	itemId: number;
	prId: number;
	title: string;
	prAccess: ProjectAccessModes;
}

interface Props {
	props: ModalProps
	onClose: (itemId: number, counter: number| null) => void;
}

export default function CommentsPopup({ props, onClose}: Props) {
	const { instance, inProgress } = useMsal();
	const [userId, setUserId] = useState<string | null>(null);
	const activeAccount = instance.getActiveAccount();
	let counter: number|null = null;
	useEffect(() => {
		if (inProgress === InteractionStatus.None && activeAccount) {
			setUserId(activeAccount.localAccountId as string);
		}
	}, [activeAccount, inProgress]);

	return (
		<Modal
			open={true}
			onCancel={() => { onClose(props.itemId, counter) }}
			destroyOnClose
			width={800}
			//styles={{ body: { minHeight: '400px' } }}
			title={`Comments - ${props.title}`}
			footer={null}
		>
			{userId &&
				<Comments
				commentType={props.type}
				itemId={props.itemId}
				prId={props.prId}
				currentUserId={userId}
				prAccess={props.prAccess}
				refreshCounter={(itemId, newCounter) => {
					counter = newCounter;
				}}
				/>}
			
		</Modal>
	);
}
