import { useContext, useEffect, useState } from "react";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { NavLink } from "react-router-dom";
import { Dropdown, Space, Layout } from "antd";
import { PageRoutes } from "../utils/pageRoutes";
import { InteractionStatus } from "@azure/msal-browser";
import { checkAdminStatus } from "../utils/claimUtils";
import Tos from "./Tos";
import ScrollToTop from "./ScrollToTop";
import { MobileContext } from "./PageLayout";
import MobileFooter from "./MobileFooter";
import { ItemType } from "antd/es/menu/interface";

const { Header } = Layout;

export default function HeaderBar() {
    const [isAdmin, setIsAdmin] = useState(false);
    const { inProgress, instance } = useMsal();
    const [tosOpenId, setTosOpenId] = useState<string | undefined>(undefined);
    const isMobile = useContext(MobileContext);
    const menuItems: ItemType[] = [];

    const handleLogoutRedirect = () => {
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
        });
    };

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            checkAdminStatus(instance, setIsAdmin);
            if (
                !localStorage.getItem("t-o-s") ||
                localStorage.getItem("t-o-s") !== activeAccount?.localAccountId
            ) {
                setTosOpenId(activeAccount?.localAccountId);
            }
        }
    }, [inProgress, instance]);

    const activeAccount = instance.getActiveAccount();

    if (!activeAccount && inProgress === InteractionStatus.None) {
        instance
            .loginRedirect(loginRequest)
            .then(() => { })
            .catch((error: any) => console.log(error));
    } else {
        const UserItems: ItemType[] = [
            {
                label: (
                    <div className="hbox vcbox gap-15">
                        <div className="user-thumb">
                            <span className="ficon ficon-user ficon-circle"></span>
                        </div>
                        <div>
                            <div>{activeAccount?.name || "Unknown"}</div>
                            <div>{activeAccount?.username || "Unknown"}</div>
                        </div>
                    </div>
                ),
                key: "0",
            },
            { type: "divider" },
            {
                label: (
                    <NavLink to={PageRoutes.account()} className="menu-link">
                        <Space size="middle">
                            <span className="ficon ficon-setting"></span>
                            <span>Account settings</span>
                        </Space>
                    </NavLink>
                ),
                key: "1",
            },
        ];
        menuItems.push(...UserItems);

        if (isAdmin  && !isMobile) {
            menuItems.push({
                label: (
                    <NavLink to={PageRoutes.subscriptions()} className="menu-link">
                        <Space size="middle">
                            <span className="ficon ficon-setting"></span>
                            <span>Administration</span>
                        </Space>
                    </NavLink>
                ),
                key: "2",
            });
        }
    }

    menuItems.push({
        label: (
            <NavLink to={PageRoutes.HelpHome()} className="menu-link">
                <Space size="middle">
                    <span className="ficon ficon-help-circle"></span>
                    <span>Help Files</span>
                </Space>
            </NavLink>
        ),
        key: "3",
    });

    menuItems.push({
        label: (
            <div onClick={handleLogoutRedirect}>
                <Space size="middle">
                    <span className="ficon ficon-exit"></span>
                    <span>Log Out</span>
                </Space>
            </div>
        ),
        key: "4",
    });

    return (
        <AuthenticatedTemplate>
            {tosOpenId && (
                <Tos close={() => setTosOpenId(undefined)} userId={tosOpenId}></Tos>
            )}

            {isMobile ? (
                <MobileFooter menuItems={menuItems} />
            ) : (
                <Header className="app-header hbox vcbox gap-20">
                    <NavLink className="app-logo flex-item" to={PageRoutes.Home()}>
                        <img src="/app-logo.svg" alt="Tava Discovery Application Logo" />
                    </NavLink>

                    <div className="user-nav">
                        <Dropdown
                            className="dropdown-trigger link"
                            menu={{ items: menuItems }}
                            trigger={["click"]}
                        >
                            <span className="ficon ficon-menu"></span>
                        </Dropdown>
                    </div>
                    <ScrollToTop></ScrollToTop>
                </Header>
            )}
        </AuthenticatedTemplate>
    );
}
