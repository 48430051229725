import { Tabs } from 'antd';
import { Panel } from '../../../models/api/shared';
import { MobileContext } from "../../../components/PageLayout";
import { useContext } from 'react';

interface PanelTabsProps {
	onPanelChange: (key: Panel) => void;
	activePanel: string;
}

export default function PanelButtons({ onPanelChange, activePanel }: PanelTabsProps) {

    const isMobile = useContext(MobileContext);

    const handleTabChange = (key: string) => {
        onPanelChange(key as Panel);
    };

    const tabItems = [
        {
            label: (
                <span className="tab-text-wrap">
                    <span className="ficon ficon-tava"></span>
                    Discovery
                </span>
                
            ),
            key: 'Discovery',
        },
        ...(!isMobile
            ? [
                {
                    label: (
                        <span className="tab-text-wrap">
                            <span className="ficon ficon-current-results"></span>
                            Best Sources
                        </span>
                    ),
                    key: 'BestSources',
                },
                {
                    label: (
                        <span className="tab-text-wrap">
                            <span className="ficon ficon-common-themes"></span>
                            Boost Insight
                        </span>
                    ),
                    key: 'BoostInsight',
                },
            ]
            : []),
        {
            label: (
                <span className="tab-text-wrap">
                    <span className="ficon ficon-note"></span>
                    Notes
                </span>
            ),
            key: 'Notes',
        },
        {
            label: (
                <span className="tab-text-wrap">
                    <span className="ficon ficon-data"></span>
                    Documents
                </span>
            ),
            key: 'Documents',
        },
    ];

    return (
        <div className="content-tabs-wrap">
            <Tabs
                activeKey={activePanel}
                onChange={handleTabChange}
                tabPosition={"top"}
                items={tabItems}
            />
        </div>
    );
}
