import { Menu, MenuProps, message } from "antd";
import { useMutation } from '@tanstack/react-query';
import { ContextMenuType } from "../models/api/shared";
import { ProjectNoteCreateModel } from "../models/api/notes";
import ApiRequests from "../utils/api-requests";
import { errorHandler } from "../utils/globals";

interface Props {
	setContextMenu: (values: ContextMenuType) => void;
	contextMenu: ContextMenuType;
	projectId: number;
	handleStartQuestion?: () => void;
}

export default function ContextMenu({
	contextMenu,
	setContextMenu,
	projectId,
	handleStartQuestion
}: Props) {
	type MenuItem = Required<MenuProps>['items'][number];

	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText(contextMenu.text);
		message.success('Copied to clipboard');
		setContextMenu({ ...contextMenu, visible: false });
	};

	const createNoteMutation = useMutation({
		mutationFn: (model: ProjectNoteCreateModel) => {
			return ApiRequests.Project.Notes.create(projectId, model);
		},
		onSuccess: () => {
			message.success('Note created successfully');
			setContextMenu({
				...contextMenu,
				visible: false
			});
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
	});

	const handleCreateNote = () => {
		const model: ProjectNoteCreateModel = {
			text: contextMenu.text.replace(/(\r\n|\n|\r)/g, '\n\n'),
			images: [],
			topicsWrapper: {
				topics: []
			},
			isOpenAi: false,
			gallery: {
				images: []
			},
			tags: []
		};
		createNoteMutation.mutate(model);
	};

	const items: MenuItem[] = [
		{
			label: "Create note",
			key: 'create',
			icon: <span className="ficon ficon-note"></span>,
			onClick: handleCreateNote
		},
		{
			label: "Copy to clipboard",
			key: 'copy',
			icon: <span className="ficon ficon-copy"></span>,
			onClick: handleCopyToClipboard
		}
	];

	if (handleStartQuestion) {
		items.unshift({
			label: "Ask Tava",
			key: 'ask-tava',
			icon: <span className="ficon ficon-tava-go"></span>,
			onClick: handleStartQuestion
		});
	}

	return (
		<>
			<Menu
				items={items}
				style={{
					position: 'absolute',
					top: contextMenu.y ?? 0,
					left: contextMenu.x ?? 0,
					minWidth: 180,
					zIndex: 9999,

				}}
			/>
		</>

	);
}
