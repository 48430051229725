import HelpLayout from "../Home";

export default function NotesHelp() {
	return (
		<HelpLayout activeTab="Notes">
			<div className='help-margin-left'>
				<h3>Notes:</h3>

				<p>
					<b>Notes Module: </b>
					This module is one of the most important in TAVA. It allows users to create, maintain, and manage all Notes within each project in a collaborative environment.
				</p>

				<h4>Creating Notes:</h4>
				<ul>
					<li>
						Use the features in the Discovery Module or create notes manually by clicking the <b>Add a Note</b> button. Write or paste any text into the simple editor and save the note.
					</li>
					<li>
						Create a note in the Q&A or Best Sources section of the Discovery Module by highlighting text (whether it's a few words, a sentence, a paragraph, or more) and using the short Context menu that appears next to the highlighted text. You can also create a note from the entire answer by selecting the <b>Create Note</b> button in the upper right corner of each answer.
					</li>
				</ul>

				<p>
					<b>Editing and Deleting Notes: </b>
					Each note, whether created manually or automatically, can be edited, expanded, or deleted. The user-friendly editor and interface allow for quick and easy content creation and editing. Don't forget to save all changes after editing. Notes are crucial for each research project, serving as the building blocks for all future documents related to the project.
				</p>

				<p>
					<b>Note Listing and Collaboration: </b>
					Notes are listed chronologically, containing a timestamp and the author. You can edit or delete any note you created. You, along with the team members you've appointed to collaborate on the project, can comment on any note.
				</p>

				<p>
					<b>Notes and Document Generation: </b>
					Notes serve as the foundation for creating all documents within each project.
				</p>
			</div>

		</HelpLayout>
	);
}
