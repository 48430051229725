import { Button, Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import { PageRoutes } from "../../../../utils/pageRoutes";

interface Props {
	recordId: number;
}

export default function DocumentsButton({ recordId }: Props) {
	return (
		<Tooltip title="Documents">
			<NavLink to={PageRoutes.goToProjectDocs(recordId)} className="menu-link">
				<Button className="action-button">
					<span className="ficon ficon-data"></span>
				</Button>
			</NavLink>
		</Tooltip>
	)
}