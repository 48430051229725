import { ColumnsType } from "antd/es/table";
import { SharedProjectsGridResultModel, SharedProjectsGridSortColumns } from "../../../../../models/api/projects/projects";
import { toDate } from "../../../../../utils/globals";
import { SortInfo, SortTypes } from "../../../../../models/api/shared";
import DocumentsButton from "../../../components/ActionButtons/DocumentsButton";
import { PageRoutes } from "../../../../../utils/pageRoutes";
import { Link } from "react-router-dom";

interface Props {
	sortInfo: SortInfo<SharedProjectsGridSortColumns>;
}

const TableColumns = ({ sortInfo }: Props): ColumnsType<SharedProjectsGridResultModel> => {
	return [
		{
			title: "Project Name",
			dataIndex: "name",
			key: "Name",
			width: 1000,
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Name" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' : 'descend') : undefined,
			render: (text: string, record: SharedProjectsGridResultModel) => (
				<Link to={PageRoutes.goToProject(record.id)} style={{ textDecoration: "none" }}>
					{text}
				</Link>
			),
		},
		{
			title: "Owner",
			dataIndex: "owner",
			key: "Owner",
			width: 500,
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Name" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' : 'descend') : undefined,
			render: (owner: SharedProjectsGridResultModel) => (
				<span>{owner.name}</span>
			),
		},
		{
			title: "Access Mode",
			dataIndex: "projectAccessMode",
			key: "AccessMode",
			width: 300,
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Name" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' : 'descend') : undefined,
			render: (text: string) => (
				<span>{text}</span>
			),
		},
		{
			title: "Modified At",
			dataIndex: "modifiedAt",
			key: "ModifiedAt",
			sorter: true,
			width: 300,
			sortOrder: sortInfo.sortColumn === "ModifiedAt" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' : 'descend') : undefined,
			render: (date: Date | string) => toDate(date),
		},
		{
			width: 0,
			key: "actionColumn",
			render: (record: SharedProjectsGridResultModel) => (
				<div style={{ display: "flex", flexDirection: "row" }}>
					<DocumentsButton recordId={record.id} />
				</div>
			),
		},
	];
};

export default TableColumns;
