import { NavLink, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { PageRoutes } from "../utils/pageRoutes";
import { useHistoryProjects } from "./HistoryProjectsContext";
const { Sider } = Layout;

export default function NavigationBar() {
	const { projects } = useHistoryProjects();
	const location = useLocation();
	const getSelectedKey = () => {
		const path = location.pathname;
		if (path === PageRoutes.PersonalProjects() || path.startsWith("/projects/")) {
			return PageRoutes.PersonalProjects();
		}
		return path;
	};
	const menuItems = [
		{
			key: PageRoutes.Home(),
			label: (
				<NavLink to={PageRoutes.Home()} className="menu-link">
					<span className="menu-link-icon ficon ficon-search" />
					<span className="menu-link-text">Start Research Project</span>
				</NavLink>
			),
		},
		{
			key: PageRoutes.PersonalProjects(),
			label: (
				<NavLink to={PageRoutes.PersonalProjects()} className="menu-link">
					<span className="menu-link-icon ficon ficon-proj" />
					<span className="menu-link-text">Projects</span>
				</NavLink>
			),
		},
	];

	return (
		<>
			<Sider
				className="app-aside"
			>
				<section className="app-aside-inner">
					<Menu
						theme="light"
						selectedKeys={[getSelectedKey()]}
						items={menuItems}
						className="main-menu"
					/>
					<div className='quick-nav-aside' key={'pr-history-comp'}>
						{/* <div className="text-gray group-title">Recently viewed</div> */}
						{projects && projects.map((pr) => (
							<div className='quick-nav-link' key={`pr-his-link-${pr.prId}`}>
								<NavLink to={PageRoutes.goToProject(pr.prId, 'Discovery')}>
									<span className="menu-link-text">{pr.prName}</span>
								</NavLink>
							</div>
						))}
						<div className="quick-nav-link txt-small">
							<NavLink className={'link-color2'} to={PageRoutes.PersonalProjects()}>
								<span className="menu-link-text">see more ...</span>
							</NavLink>
						</div>
					</div>
				</section>
			</Sider>
		</>
	);
}
