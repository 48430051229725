import { Alert, Button, Form, FormProps, Input, Spin, Tooltip, message } from "antd";
import Modal from "antd/es/modal/Modal";
import Title from "antd/es/typography/Title";
import JoditEditor from "jodit-react";
import { useRef, useState, useCallback, useMemo, useEffect } from "react";
import ApiRequests from "../../../../utils/api-requests";
import { useMutation, useQuery } from "@tanstack/react-query";
import { errorHandler, fileToBase64, stripHTMLTags } from "../../../../utils/globals";
import parse from "html-react-parser";
import CopyToClipboard from "../../../../components/CopyToClipboard";
import { DocumentUpdateModel } from "../../../../models/api/documents";
import useContextMenu from "../../../../hooks/useContextMenu";
import ContextMenu from "../../../../components/ContextMenu";

interface Props {
	documentId: number;
	projectId: number;
	handleEditSubmit: () => void;
	onClose: () => void;
	viewMode: boolean;
}

type FieldType = {
	title: string
};

export default function EditDocument({
	handleEditSubmit,
	documentId,
	projectId,
	viewMode,
	onClose,
}: Props) {
	const editor = useRef<any>(null);
	const [content, setContent] = useState("");
	const { contextMenu, checkForSelect, clearSelect, setContextMenu } = useContextMenu();

	const config = useMemo(
		() => ({
			readonly: false,
			toolbarSticky: false,
			toolbarAdaptive: false,
			showPlaceholder: false,
			toolbarButtonSize: "middle" as const,
			buttons: [
				"bold",
				"|",
				"italic",
				"|",
				"underline",
				"|",
				"font",
				"|",
				"fontsize",
				"|",
				"ul",
				"|",
				"ol",
				"|",
				"align",
				"|",
				"link",
				"|",
				"image",
				"|",
			],
			uploader: {
				insertImageAsBase64URI: true,
				url: "",
			},
			imageUploader: {
				insertImageAsBase64URI: false,
				handler: (files: FileList) => {
					handleImageUpload(files);
				},
			},
		}),
		[]
	);

	const getDocument = useQuery({
		queryKey: ['documentContent', documentId],
		queryFn: () => ApiRequests.Project.Documents.read(projectId, documentId),
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
	});

	const updateDocument = useMutation({
		mutationFn: (updatedDocument: DocumentUpdateModel) =>
			ApiRequests.Project.Documents.update(
				projectId,
				documentId,
				updatedDocument
			),
		onSuccess: () => {
			handleEditSubmit();
			message.success('Document updated successfully', 5);
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
	});

	useEffect(() => {
		if (getDocument.data) {
			setContent(getDocument.data.htmlContent);
		}
	}, [getDocument.data]);

	const handleContentChange = useCallback((newContent: string) => {
		setContent(newContent);
	}, []);

	const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
		if (getDocument.isError) {
			message.error(errorHandler(getDocument.error.message));
			return;
		}
		const plainText = stripHTMLTags(content).trim();
		if (!plainText) {
			message.warning("Text area cannot be empty");
			return;
		}

		const model: DocumentUpdateModel = {
			name: values.title,
			htmlContent: content
		};
		updateDocument.mutate(model);
	};

	const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const createImageMutation = useMutation({
		mutationFn: (imageData: { images: { fileName: string; dataUrl: string; }[]; }) =>
			ApiRequests.Images.create(projectId, imageData),
		onSuccess: (response) => {
			const imageUrl = ApiRequests.Images.href(
				response[0].id,
				response[0].date,
				800,
				800
			);

			const editorCursorLocation = editor.current;
			editorCursorLocation?.s.insertImage(imageUrl);
		},
		onError: (error: Error) => {
			message.error(errorHandler(error?.message));
		},
	});

	const handleImageUpload = async (files: FileList) => {
		try {
			const base64String = await fileToBase64(files[0]);
			createImageMutation.mutate({ images: [{ fileName: files[0].name, dataUrl: base64String }] });
		} catch (error) {
			message.error('Image upload failed');
		}
	};

	return (
		<>
			{viewMode ? (
				<>
					<Modal
						open={!getDocument.isFetching}
						onCancel={onClose}
						destroyOnClose
						width={800}
						footer={[
							<div>
								<Tooltip title="Copy to clipboard">
									<CopyToClipboard text={getDocument?.data?.htmlContent}></CopyToClipboard>
								</Tooltip>
								<Button key="cancel" onClick={onClose}>
									Close
								</Button>
							</div>
						]}
					>
						<Title
							style={{ marginTop: "0px", marginBottom: "1.5rem" }}
							level={5}
							className=""
						>
							{getDocument.data?.name}
						</Title>
						{getDocument.error ? (
							<Alert
								message="Error"
								description={errorHandler(getDocument.error.message)}
								type="error"
								showIcon
							/>
						) : (
							<div
								onMouseUp={checkForSelect}
								onMouseDown={clearSelect}
								style={{ cursor: 'text' }}
							>
								{parse(content)}
							</div>
						)}
					</Modal>
				</>
			) : (
				<>
					<Modal
						open={!getDocument.isFetching}
						onCancel={onClose}
						destroyOnClose
						width={800}
						footer={[
							<Button
								key="submit"
								form="edit-doc-pop-form"
								htmlType="submit"
								type="primary"
							>
								Save
							</Button>,
							<Button key="cancel" onClick={onClose}>
								Cancel
							</Button>,
						]}
					>
						<Title
							style={{ marginTop: "0px", marginBottom: "1.5rem" }}
							level={5}
						>
							Edit Document
						</Title>
						{getDocument.error ? (
							<Alert
								message="Error"
								description={errorHandler(getDocument.error.message)}
								type="error"
								showIcon
							/>
						) : (
							<>
								<Form
									onFinish={onFinish}
									id="edit-doc-pop-form"
									onFinishFailed={onFinishFailed}
								>
									<Form.Item<FieldType>
										name="title"
										initialValue={getDocument.data?.name}
										rules={[
											{
												required: true,
												message: "Please fill document title!",
											},
										]}
									>
										<Input />
									</Form.Item>
									<JoditEditor
										ref={editor}
										value={content}
										config={config}
										onChange={handleContentChange}
									/>
								</Form>
							</>
						)}
					</Modal>
				</>
			)}
			{getDocument.isFetching && (
				<Spin className="full-page-spinner" fullscreen />
			)}
			{contextMenu.visible && (
				<ContextMenu
					contextMenu={contextMenu}
					setContextMenu={setContextMenu}
					projectId={projectId}
				/>
			)}
		</>
	);
}