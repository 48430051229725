import { Tooltip, Popconfirm } from "antd";
import { toStorage } from "../../../../../utils/globals";
import { ServiceCatalogResultModel, ServiceCatalogSortColumns } from "../../../../../models/api/service-catalogs";
import { SortOrder } from "antd/es/table/interface";
import { SortInfo, SortTypes } from "../../../../../models/api/shared";

type Props = {
	handleDeleteClick: (id: number) => void;
	handleEditClick: (id: number) => void;
	sortInfo: SortInfo<ServiceCatalogSortColumns>;
};

const TableColumns = ({ handleDeleteClick, handleEditClick, sortInfo }: Props) => {
	return [
		{
			title: "Id",
			dataIndex: "id",
			key: "Id",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Id" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "License (Title)",
			dataIndex: "name",
			key: "Name",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Name" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "Service Type",
			dataIndex: "serviceType",
			key: "ServiceType",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "ServiceType" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: string, record: ServiceCatalogResultModel) => (
				<span>
					{record.serviceType !== undefined && record.serviceType !== null ? record.serviceType : "Upgrade"}
				</span>
			),
		},
		{
			title: "Storage",
			dataIndex: "Storage",
			key: "Storage",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Storage" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: string, record: ServiceCatalogResultModel) => (
				<span style={{ whiteSpace: "nowrap" }}>{toStorage(record.diskSpace)}</span>
			),
		},
		{
			title: "Price(M/Y)",
			dataIndex: "price",
			key: "Price",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Price" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: string, record: ServiceCatalogResultModel) => `${record.monthPrice} $ / ${record.yearPrice} $`,
			width: 150,
		},
		{
			title: "Questions (Per Day)",
			dataIndex: "questions",
			key: "Questions",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Questions" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "Active",
			dataIndex: "isActive",
			key: "IsActive",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "IsActive" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: string, record: ServiceCatalogResultModel) =>
				record.isActive ? <span className="ficon ficon-check"></span> : <span className="ficon ficon-close"></span>,
		},
		{
			title: "",
			dataIndex: "actionColumn",
			key: "actionColumn",
			render: (text: string, record: ServiceCatalogResultModel) => (
				<>
					<Popconfirm title="Delete license" onConfirm={() => handleDeleteClick(record.id)}>
						<span className="ficon ficon-delete" style={{ cursor: "pointer", marginRight: "2rem", fontSize: "1.2rem" }}></span>
					</Popconfirm>
					<Tooltip title="Edit">
						<span className="ficon ficon-edit" style={{ cursor: "pointer", fontSize: "1.2rem" }} onClick={() => handleEditClick(record.id)}></span>
					</Tooltip>
				</>
			),
		},
	];
};

export default TableColumns;
