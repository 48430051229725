import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table/interface";
import ActionColumn from './ActionColumn';
import { toDate } from '../../../../utils/globals';
import { DocumentsGridResultModel, DocumentsGridSortColumns } from '../../../../models/api/documents';
import { SortInfo, SortTypes } from '../../../../models/api/shared';

interface Props {
	handleRead: (docId: number) => void;
	handleEditButtonClick: (docId: number) => void;
	sortInfo: SortInfo<DocumentsGridSortColumns>;
	projectId?: number;
	handleCommentToggle: (itemId: number, title: string) => void;
	handleDelete: (projectId: number, documentId: number) => void;


}

const TableColumns = ({ handleRead, handleEditButtonClick,
	sortInfo,
	projectId,
	handleCommentToggle,
	handleDelete
}: Props): ColumnsType<DocumentsGridResultModel> => {
	return [
		{
			title: "Document",
			dataIndex: "name",
			key: "name",
			width: 1000,
			render: (text: string, record: DocumentsGridResultModel) => {
				return record.isReady ? (
					<>
						<div onClick={() => handleRead(record.id)} className="force-link" style={{ marginBottom: "0rem" }}>
							{text}
						</div>
						<p className="text-light-gray txt-xsmall m-0"> Created By {record.creator.name}</p>
					</>
				) : (
					<>
						<div style={{ marginBottom: "0rem" }}>
							{text}
						</div>
						<p className="text-light-gray txt-xsmall m-0"> Created By {record.creator.name}</p>
					</>
				);
			},
		},
		{
			title: "Type",
			dataIndex: "documentTypeDisplayName",
			key: "documentType",
			width: 1000,
		},
		{
			title: "Last Modified",
			dataIndex: ['modifier', 'modifiedAt'],
			key: "ModifiedAt",
			width: 200,
			sorter: true,
			sortOrder: sortInfo.sortColumn === "ModifiedAt" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' : 'descend') : undefined,
			render: (modifiedAt: string) => {
				return toDate(modifiedAt)
			},
		},
		{
			title: "",
			dataIndex: "actionColumn",
			key: "actionColumn",
			width: 0,
			render: (text: string, record: DocumentsGridResultModel) => {
				if (!record.isReady) {
					return (
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<p style={{ fontSize: "1rem" }}>Creating...</p>
							<Spin style={{ margin: "0 0 0.5rem 1rem" }} indicator={<LoadingOutlined spin />} size="small" />
						</div>
					);
				}
				return <ActionColumn projectId={projectId as number}
					document={record}
					handleEditButtonClick={handleEditButtonClick}
					handleCommentToggle={handleCommentToggle}
					handleDelete={handleDelete}
				/>;
			},
		},
	];
};

export default TableColumns;
