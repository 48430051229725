import { Button, Tooltip } from "antd";
interface Props {
	recordId: number;
	openDeleteModal: (id: number) => void;
}

export default function DeleteButton({ recordId, openDeleteModal }: Props) {
	return (
		<Tooltip title="Delete">
			<Button
				onClick={() => openDeleteModal(recordId)}
				style={{ cursor: "pointer", fontSize: "16px" }}
				className="action-button"
			>
				<span className="ficon ficon-delete"></span>
			</Button>
		</Tooltip>
	);
}
