import { useState } from 'react';
import { Button, Popconfirm, Spin, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import ApiRequests from '../../../../utils/api-requests';
import { errorHandler } from '../../../../utils/globals';
interface NoteDelete {
	myDataId: number,
	projectId: number,
	onDeleteToggle: (id: number) => void;
}

export default function DeleteTavaPick({ myDataId, projectId, onDeleteToggle }: NoteDelete) {
	const [loading, setLoading] = useState(false);

	const deleteMutation = useMutation({
		mutationFn: async () => ApiRequests.MyData.delete(projectId, { items: [myDataId] }),
		onSuccess: async () => {
			try {
				ApiRequests.MyData.stopCrawling(projectId, { items: [myDataId] });
				message.success('Source has been deleted.');
				onDeleteToggle(myDataId)
			} catch (error) {
				message.error(errorHandler(error as Error))
			}
			finally {
				setLoading(false);
			}
		},
		onError: (error) => {
			setLoading(false)
			message.error(errorHandler(error), 15);
		},
	});

	const confirm = () => {
		setLoading(true)
		deleteMutation.mutate();
	}

	return (
		<Popconfirm
			title="Delete Source"
			description="Are you sure you want to delete this source?"
			onConfirm={confirm}
			okButtonProps={{ loading: loading }}
		>

			<Button type="text" className="action-btn">
				<span className='ficon ficon-close'></span>
			</Button>

			{loading && <Spin className='full-page-spinner' fullscreen />}
		</Popconfirm>

	);
};
