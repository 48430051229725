import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { ProjectsArchiveModel } from "../../../models/api/projects/projects";
import ApiRequests from "../../../utils/api-requests";
import { errorHandler } from "../../../utils/globals";
export function useArchvieProjectMutation({
    onSuccess,
}: {
    onSuccess: () => void;
}) {
    return useMutation<void, Error, ProjectsArchiveModel>({
        mutationFn: (model: ProjectsArchiveModel) => ApiRequests.Project.archive(model),
        mutationKey: ["archiveProject"],
        onSuccess: onSuccess,
        onError: (error) => {
            message.error(errorHandler(error));
        },
    });
}
