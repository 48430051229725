import { useState } from 'react';
import { Button, Popconfirm, Spin, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import ApiRequests from '../../../../utils/api-requests';
import { errorHandler } from '../../../../utils/globals';

interface NoteDelete {
	noteId: number,
	projectId: number,
	onDeleteToggle: () => void;
}

export default function DeleteNote({ noteId, projectId, onDeleteToggle }: NoteDelete) {
	const [loading, setLoading] = useState(false);

	const deleteMutation = useMutation({
		mutationFn: async () => ApiRequests.Project.Notes.delete(projectId, noteId),
		onSuccess: () => {
			setLoading(false)
			message.success('Note has been deleted.');
			onDeleteToggle()
		},
		onError: (error) => {
			setLoading(false)
			message.error(errorHandler(error), 15);
		},
	});

	const confirm = () => {
		setLoading(true)
		deleteMutation.mutate();
	}

	return (
		<Popconfirm
			title="Delete Note"
			description="Are you sure you want to delete this note?"
			onConfirm={confirm}
			okButtonProps={{ loading: loading }}
		>

			<Button type="text" className="action-btn">
				<span className='ficon ficon-close'></span>
			</Button>

			{loading && <Spin className='full-page-spinner' fullscreen />}
		</Popconfirm>

	);
};
