import { Button, Tooltip } from "antd";

interface Props {
	recordId: number;
	openRecoverModal: (id: number) => void;
}

export default function RecoverButton({ recordId, openRecoverModal }: Props) {
	return (
		<Tooltip title="Recover">
			<Button
				onClick={() => openRecoverModal(recordId)}
				className="action-button"
			>
				<span className="ficon ficon-revert"></span>
			</Button>
		</Tooltip>
	);
}
