import { ColumnsType } from "antd/es/table";
import { Tooltip } from "antd";
import { PaymentResultModel, PaymentsSortColumns } from "../../../../models/api/payments";
import { toDateTime } from "../../../../utils/globals";
import { SortInfo, SortTypes } from "../../../../models/api/shared";
import { SortOrder } from "antd/es/table/interface";

interface Props {
	handleDetailsClick: (orderId: number) => void;
	sortInfo: SortInfo<PaymentsSortColumns>;
}

const OrdersTableColumns = ({ handleDetailsClick, sortInfo }: Props): ColumnsType<PaymentResultModel> => {




	return [
		{
			title: "Id",
			dataIndex: "id",
			key: "Id",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Id" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "Payment Date",
			dataIndex: "date",
			key: "Date",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Date" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (date: string) => <span>{toDateTime(date)}</span>,
		},
		{
			title: "Order #",
			dataIndex: "orderId",
			key: "OrderId",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "OrderId" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "Check #",
			dataIndex: "check",
			key: "Check",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Check" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "Transaction #",
			dataIndex: "transaction",
			key: "Transaction",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Transaction" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "Amount",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Amount" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "",
			dataIndex: "actionColumn",
			key: "actionColumn",
			render: (text: string, record: PaymentResultModel) => {
				return (
					<Tooltip title="View Details">
						<span
							onClick={() => handleDetailsClick(record.orderId)}
							className="ficon ficon-eye"
							style={{ marginRight: 8, cursor: "pointer", fontSize: "1.5rem" }}
						/>
					</Tooltip>
				);
			},
		},
	];
};

export default OrdersTableColumns;
