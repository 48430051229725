import { useState } from "react";
import { AdminOrdersResultModel, AdminOrdersSortColumns} from "../../../../models/api/orders";
import { SortInfo, SortTypes, TableSorter } from "../../../../models/api/shared";
import AdministrationTabs from "../../components/Tabs";
import { Spin, TableProps, message } from "antd";
import {useQuery, useQueryClient } from "@tanstack/react-query";
import ApiRequests from "../../../../utils/api-requests";
import ApprovalModal from "../subscriptions/tabs/components/ApprovalModal";
import SubscriptionManager from "../../components/SubscriptionManager";
import { errorHandler, handleTableSort } from "../../../../utils/globals";
import OrdersTableColumns from "./components/AdminOrdersTableColumns";
import Orders from "../../../../popups/Orders/Orders";
import Table from "../../../../components/antd-extensions/ExtendedTable";
export default function Payments() {

	const [sortInfo, setSortInfo] = useState<SortInfo<AdminOrdersSortColumns>>({
		sortColumn: "OrderId",
		sortType: SortTypes.Asc,
	});
	const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
	const [selectedOrderDetailId, setSelectedOrderDetailId] = useState<number | null>(null);
	const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string | null>(null);
	const queryClient = useQueryClient();

	const handleTableChange: TableProps<AdminOrdersResultModel>['onChange'] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const administrationOrders = useQuery<AdminOrdersResultModel[]>({
		queryKey: ["administrationOrders", sortInfo],
		queryFn: () => ApiRequests.Orders.administrationOrders({
			sortColumn: sortInfo.sortColumn,
			sortType: sortInfo.sortType,
		}),
	});

	if (administrationOrders.isError) {
		message.error(errorHandler(administrationOrders.error.message));
	}

	const handlePaymentClick = (orderId: number) => {
		setSelectedOrderId(orderId);
	};

	const handleSubscriptionClick = (subscriptionId: string) => {
		setSelectedSubscriptionId(subscriptionId);
	};;

	const handleSuccsesfulPayment = () => {
		queryClient.invalidateQueries({ queryKey: ['administrationOrders'] });
	}
	const handleOrderDetailsClick = (orderId: number) => {
		setSelectedOrderDetailId(orderId);
	};

	const handleDetails = () => {
		setSelectedOrderDetailId(null);
	};
	const columns = OrdersTableColumns({ handlePaymentClick, handleSubscriptionClick, handleOrderDetailsClick, sortInfo });

	if (administrationOrders.isFetching) {
		return <Spin spinning={true} fullscreen />;
	}
	if (administrationOrders.error) {
		message.error(errorHandler(administrationOrders.error));
	}

	return (
		<>
			<AdministrationTabs activePanel={"orders"}></AdministrationTabs>

			<Table
				columns={columns}
				dataSource={administrationOrders.data}
				rowKey="orderId"
				onChange={handleTableChange}
				pagination={false}
			/>

			{selectedOrderId ? <ApprovalModal
				orderId={selectedOrderId}
				memberId={null}
				setSelectedOrderId={setSelectedOrderId}
				onSuccsesfulPayment={handleSuccsesfulPayment}
			/> : null}

			{selectedOrderDetailId ? <Orders model={{ orderId: selectedOrderDetailId }} onClose={handleDetails}></Orders> : null}

			{selectedSubscriptionId ?
				<SubscriptionManager key="subscription-modal" onClose={() => { setSelectedSubscriptionId(null) }}
					subscriptionId={selectedSubscriptionId} memberId={selectedSubscriptionId}></SubscriptionManager> : null}
		</>
	);
}
