import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loginRequest } from '../../../authConfig';
import { Layout, Spin, message } from 'antd';
import ApiRequests from '../../../utils/api-requests';
import { errorHandler } from '../../../utils/globals';
import { PageRoutes } from '../../../utils/pageRoutes';
import SessionStorageMsg from './utils/sessionStorageMsg';


export default function Rejected() {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const navigate = useNavigate();
	const authRequest = {
		...loginRequest,
	};

	if (!token) {
		navigate(PageRoutes.license());
	} else {
		ApiRequests.PayPal.rejected(decodeURIComponent(token)).then((id) => {
			SessionStorageMsg.create(`Order # ${id} canceled. `);
			navigate(PageRoutes.license());
		}).catch((error) => {
			message.error(errorHandler(error), 35);
		})
			
	}

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest as any}
		>
			<Layout >
				<Spin spinning={true} fullscreen />
			</Layout>
		</MsalAuthenticationTemplate>
	);

}  
