import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
    PublicClientApplication,
    EventType,
    AccountInfo,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import App from "./App";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "./styles/index.css";
import "./styles/stlib.css";
import { ConfigProvider } from "antd";

export const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            //Prevents the default settings of garbage collection of cache after 5 minutes of inactivity
            gcTime: 1000 * 60 * 60 * 24,
            refetchOnWindowFocus: false,
            retry: false,
        },
        
    },
});
const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
});

// Default to using the first account if no account is active on page load
//if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//    // Account selection logic is app dependent. Adjust as needed for different use cases.
//    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0] as AccountInfo);
//}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback(async (event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const account = event.payload as AccountInfo;
        msalInstance.setActiveAccount(account);
    }
});

const rootElement = document.getElementById("root") as HTMLElement;
const activeAccount = msalInstance.getActiveAccount();

if (activeAccount) {
    rootElement.setAttribute("userid", activeAccount.localAccountId);
}
const root = createRoot(rootElement);
root.render(
    <BrowserRouter>
        <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister: localStoragePersister }}
        >
            <ConfigProvider theme={{ hashed: false, cssVar: true }}> {/*disable the "css-dev-only-do-not-override"*/}
                <App instance={msalInstance} />
            </ConfigProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorkerRegistration.unregister();