import { Button } from "antd";

interface Props {
	recordId: number;
	openPermanentDeleteModal: (id: number) => void;
}

export default function PermanentDeleteButton({ recordId, openPermanentDeleteModal }: Props) {
	return (
		<Button
			style={{ color: "red" }}
			className="action-button"
			onClick={() => openPermanentDeleteModal(recordId)}
		>
			Permanently Delete
		</Button>
	);
}
