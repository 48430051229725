import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApiRequests from "../../utils/api-requests";
import { loginRequest } from "../../authConfig";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Alert, Button, Col, Collapse, Flex, Progress, ProgressProps, Row, Spin, Table, message } from "antd";
import { errorHandler, toDateTime, toStorage } from "../../utils/globals";
import { InteractionType } from "@azure/msal-browser";
import AccountTabs from "./components/Tabs";
import { useState } from "react";
import UpgradeLincese from "./components/UpgradeLicense";
import BuyLincese from "./components/BuyLicense";
import CancelLincese from "./components/CancelLincese";
import { ServiceInfoModel } from "../../models/api/services";
import SessionStorageMsg from "./provider/utils/sessionStorageMsg";
import Orders from "../../popups/Orders/Orders";
import OrdersTableColumns from "./components/ActiveOrdersColumns";
import { AdminOrdersResultModel } from "../../models/api/orders";
import StickyHelp from "../../components/StyckyHelp";
import { PageRoutes } from "../../utils/pageRoutes";
import { getHelpContent } from "./utils/funcUtils";

interface LicenseModel extends ServiceInfoModel {
	storageActualFormat?: string,
	storageBudgetFormat?: string,
	validFromFormat?: string,
	validToFormat?: string
}

export default function License() {
	const [upgrade, setUpgrade] = useState<boolean>(false);
	const [orderId, setOrderId] = useState<number | null>(null);
	const [buyLicense, setBuyLicense] = useState<number | null>(null);
	const [cancelLicense, setCancelLicense] = useState<string | null>(null);
	const haveApprovedRejectedOrders = SessionStorageMsg.read();
	const queryClient = useQueryClient();
	const authRequest = {
		...loginRequest,
	};
	const licenseDataQuery = useQuery({
		queryKey: ['services-my-license'],
		queryFn: async () => ApiRequests.Services.myLicence(),
		select: (model: LicenseModel) => {
			let data: LicenseModel = { ...model };
			if (data.licenseInfo.validFrom)
				data.validFromFormat = toDateTime(data.licenseInfo.validFrom);

			if (data.licenseInfo.validTo)
				data.validToFormat = toDateTime(data.licenseInfo.validTo);

			data.storageBudgetFormat = toStorage(data.licenseInfo.storageBudget);
			data.storageActualFormat = toStorage(data.licenseInfo.storageActual);
			return data;
		},
		retry: false,
		refetchOnMount: true,
		refetchOnWindowFocus: false,
	});

	const cancelOrder = useMutation({
		mutationKey: ["cancelOrder"],
		mutationFn: async (model: number) => ApiRequests.Orders.cancel(model),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['services-my-license'] })
		},
		onError: (error) => {
			message.error(errorHandler(error.message))
		}
	});

	const conicColors: ProgressProps['strokeColor'] = {
		'0%': '#87d068',
		'50%': '#ffe58f',
		'100%': '#e61905',
	};

	const handleViewOrderClick = (orderId: number) => {
		setOrderId(orderId);
	}
	const handleCancelOrderClick = (orderId: number) => {
		cancelOrder.mutate(orderId);
	}

	const tableColumns = OrdersTableColumns({ handleViewOrderClick, handleCancelOrderClick });


	const openBuyLicense = (catalogId: number) => {
		setUpgrade(false);
		setBuyLicense(catalogId);
	}

	// TODO: check if this is correct
	function CanCancelLicense(orders: AdminOrdersResultModel[]): boolean {
		return orders?.some(x => x.status === "Active") ?? false;
	}

	// TODO: check if this is correct
	function CanUpgradeLicense(orders: AdminOrdersResultModel[]): boolean {
		return ! (orders?.some(x => x.status === "Created") ?? false);
	}


	if (haveApprovedRejectedOrders) {
		message.success(haveApprovedRejectedOrders, 15);
		SessionStorageMsg.remove();
	}

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest as any}
		>
			<Spin className='full-page-spinner' spinning={licenseDataQuery.isFetching}>
				<AccountTabs activePanel="license"></AccountTabs>
				{licenseDataQuery.error ? (
					<Alert
						message="Error"
						description={errorHandler(licenseDataQuery.error)}
						type="error"
						showIcon
					/>
				) : null}

				{licenseDataQuery.data && !licenseDataQuery.isFetching && !licenseDataQuery.error ? (
					<>
						<Collapse
							collapsible="header"
							defaultActiveKey={['1']}
							className="mb-10"
							items={[
								{
									key: '1',
									label: 'Current License',
									children: <div>
										<Row gutter={[16, 16]}>
											<Col span={8}><strong>Your License:</strong>
												<div>{licenseDataQuery.data?.licenseInfo.serviceType}</div>

											</Col>
											<Col span={8}>
												<strong>Status:</strong>
												<div>{licenseDataQuery.data?.licenseInfo.statusDisplay}</div>
											</Col>
											<Col span={8}>
												{licenseDataQuery.data?.licenseInfo.serviceType === 'Professional' ?
													<div>
														<strong>Next Billing Period Starts:</strong>
														<div>{licenseDataQuery.data?.validToFormat}</div>
													</div>
													: null}
											</Col>

											<Col span={8}><strong>Questions Limit per Day:</strong>
												<div>{licenseDataQuery.data?.licenseInfo.quetionsBudget}</div>

											</Col>
											<Col span={8}>
												<strong>Storage Limit:</strong>
												<div>{licenseDataQuery.data?.storageBudgetFormat}</div>
											</Col>
											<Col span={8}>
											</Col>
										</Row>

										<div className="mt-30" style={{ display: 'flex', justifyContent: 'space-between' }}>

											{CanCancelLicense(licenseDataQuery.data?.activeOrders) &&
												<Button type="text" style={{ float: 'left' }} danger onClick={() => setCancelLicense(licenseDataQuery.data?.licenseInfo.serviceType || null)}>
													Cancel License
												</Button>
												}
											{CanUpgradeLicense(licenseDataQuery.data?.activeOrders) &&
												<div className='hbox endbox topbox'>
													<Button type="primary" style={{ float: 'right' }} onClick={() => setUpgrade(true)}>
														Upgrade License
													</Button>
												</div>
												}

										</div>
									</div>,
								},
							]}
						/>

						<Collapse
							collapsible="header"
							defaultActiveKey={['1']}
							items={[
								{
									key: '1',
									label: 'Current usage',
									children: <div>
										<Row>
											<Col span={12}>
												<Flex>
													<Progress type="dashboard" size={50} gapDegree={30} percent={licenseDataQuery.data?.usageInfo.quetionsUsedPercent} strokeColor={conicColors} status="active" />
													<div className="ml-10">
														<strong>Questions Asked Today:</strong>
														<div>{licenseDataQuery.data?.licenseInfo.quetionsActual}/{licenseDataQuery.data?.licenseInfo.quetionsBudget}</div>
													</div>
												</Flex>

											</Col>
											<Col span={12}>
												<Flex>
													<Progress type="dashboard" size={50} gapDegree={30} percent={licenseDataQuery.data?.usageInfo.storageUsedPercent} strokeColor={conicColors} status="active" />
													<div className="ml-10">
														<strong>Storage used:</strong>
														<div>{licenseDataQuery.data?.storageActualFormat}/{licenseDataQuery.data?.storageBudgetFormat}</div>
													</div>
												</Flex>
											</Col>
										</Row>

										{licenseDataQuery.data?.licenseInfo.serviceType === 'Professional' ?
											<div className="mt-10">Current License period: {licenseDataQuery.data?.validFromFormat} - {licenseDataQuery.data?.validToFormat}</div>
											: null}
									</div>,
								},
							]}
						/>
						{licenseDataQuery.data.activeOrders && licenseDataQuery.data.activeOrders.length > 0 && (
							<Collapse
								collapsible="header"
								style={{ marginTop: "10px" }}
								defaultActiveKey={['1']}
								items={[
									{
										key: '1',
										label: 'Activity',
										children: (
											<div>
												<Table
													columns={tableColumns}
													dataSource={licenseDataQuery?.data?.activeOrders}
													pagination={false}
												/>
											</div>
										),
									}
								]}
							/>
						)}
						{orderId && <Orders model={{ orderId: orderId }} onClose={() => { setOrderId(null) }}></Orders>}
					</>
				) : (
					null
				)}

				{upgrade ? <UpgradeLincese handleBuyLicense={(catalogId) => { openBuyLicense(catalogId) }}
					onClose={() => setUpgrade(false)}></UpgradeLincese> : null}
				{buyLicense ? <BuyLincese catalogId={buyLicense} sessionId={crypto.randomUUID()}
					onClose={() => setBuyLicense(null)}></BuyLincese> : null}
				{cancelLicense ? <CancelLincese licenseName={cancelLicense} onClose={() => setCancelLicense(null)} />
					: null
				}
				<StickyHelp goToHelpLink={PageRoutes.AccountSettingsHelp()} popContent={getHelpContent()} title={"Account Settings and Licensing"}></StickyHelp>

			</Spin>
		</MsalAuthenticationTemplate >
	);
}