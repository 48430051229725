import HelpLayout from "../Home";

export default function DiscoveryHelp() {
	return (
		<HelpLayout activeTab="Discovery">
			<div className='help-margin-left'>
				<h3>Discovery Page:</h3>
				<p>
					<b>Discovery Module: </b>
					Use this module to ask TAVA any questions, and TAVA will provide answers along with relevant links and images.
					Each answer includes a <b>"Dig Deeper"</b> section with more comprehensive topics supporting the information presented.
					Clicking on these suggestions will provide further relevant answers. You can also ask TAVA additional questions manually.
				</p>

				<p>
					<b>Highlight and Note Creation: </b>
					Highlight any amount of text - whether it's a few words, a sentence, a paragraph, or more - and create a Note using the
					short Context menu that appears next to the highlighted text. You can also create a note from the entire answer by
					selecting the <b>Create Note</b> button in the upper right corner of each answer. These notes can later be edited or deleted.
					The <b>Notes</b> are crucial for each research project, serving as the building blocks for all future documents related to the project.
				</p>

				<p>
					<b>Additional Questions: </b>
					You can ask TAVA any additional questions regarding specific parts of the answer by using the <b>Ask TAVA</b> option after highlighting a desired word or short text.
				</p><br />


				<h3>Best Sources:</h3>
				<p>
					<b>Best Sources: </b>This section in the Discovery Module uses TAVA's relevancy engine to deliver publicly available sources of information
					relevant to your project's questions and topics. We process multiple options and present only the most suitable information while summarizing each page for you.
				</p>

				<p>
					<b>Summary: </b>
					You can view the summary of a page by clicking on the <b>Summary</b> button in the upper right corner of the window for each listed URL.
					TAVA provides a relevancy score for each page based on all questions and topics in the Discovery Module.
				</p>

				<p>
					<b>Dynamic List: </b>
					This list of URLs is dynamic - each additional question and answer will adjust the topics and relevancy parameters, fetching additional pages relevant to the new content.
				</p>

				<p>
					<b>Notes: </b>
					You can create Notes using the summarized pages and URLs. These notes will be available in your <b>Notes Module</b>.
				</p><br />


				<h3>Boost Insight:</h3>
				<p>
					<b>Boost Insight:</b> This section presents a unique TAVA capability. TAVA evaluates all materials, questions, and answers in the
					Discovery Module to identify the main topics and elements. It then suggests additional topics and ideas based on the project and its
					data that you might have missed or not included in your Q&A information discovery process. Clicking on each of the suggested topics
					will produce an answer in your Discovery Module.
				</p>
			</div>
		</HelpLayout>
	);
}
