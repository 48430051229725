import { Collapse, Typography } from "antd";
import { AiSources } from "../../../../models/api/ask-tava";

interface SourcesModel {
	data: AiSources[]
}

export default function Sources(sources: SourcesModel) {

	return (
		<Collapse className="skin-clean" ghost
			items={[{
				label: `View Sources: (${sources.data.length})`, children: sources.data.map((source, q) => ( //key: '1', 
					<div key={q}>
						<div className="pb-10">
							<span className="mr-05">{source.title}:</span>
							<Typography.Link onClick={() => window.open(`${source.url}`, "_blank")}>{source.domain}</Typography.Link>
						</div>
					</div>
				))
			}]}
		/>
	)
}