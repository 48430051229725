import HelpLayout from "../Home";

export default function DocumentsHelp() {
    return (
        <HelpLayout activeTab="Documents">
            <div className='help-margin-left'>
                <h3>Documents Module:</h3>
                <p>
                    The Documents Section contains all generated documents related to each research project. These documents are either manually created or generated using our templates, based on the notes within the respective research project.
                </p>

                <h3>Creating Documents:</h3>
                <ul>
                    <li>
                        Start creating a document by clicking the <b>Create Document</b> button in the Documents or Notes modules.
                    </li>
                    <li>
                        Documents are created based on all notes available within a project. Select the notes you want to include in a document and use the document creation workflow to choose one of the templates TAVA offers.
                    </li>
                    <li>
                        You can create a document without the help of TAVA AI assistants by using the <b>Your Selected Notes</b> option, which adds your notes into a document without any changes.
                    </li>
                    <li>
                        Use other available templates to enhance your document's content and appearance.
                    </li>
                </ul>

                <h3>Editing and Collaboration:</h3>
                <p>
                    Once generated, you can edit or delete any of the documents. You and your team can comment and collaborate on any of the generated documents. You can also create additional notes based on the generated documents.
                </p>

                <h3>TAVA Document Templates</h3>
                <ul>
                    <li>
                        <b>Your Selected Notes:</b> This template creates a document based on your available notes. It does not involve AI or TAVA machine learning algorithms; it simply arranges the notes in chronological order and displays them as a document.
                    </li>
                    <li>
                        <b>Button Up:</b> This template creates a well-rounded, grammatically correct document. It also suggests a title based on the content and size of the selected topic notes.
                    </li>
                    <li>
                        <b>Summary:</b> This template generates a 2-3 -page summary document based on the content of the selected topic notes.
                    </li>
                    <li>
                        <b>Presentation:</b> This template creates robust presentation material, including a project summary and informative slides, based on the content of the selected topic notes.
                    </li>
                    <li>
                        <b>Key Takeaways:</b> This template generates a list of all key takeaways derived from the content of the selected topic notes.
                    </li>
                    <li>
                        <b>What am I Missing:</b> This template identifies and lists more relevant topics and elements for your consideration, based on the content of the selected topic notes that you have not explored.
                    </li>
                    <li>
                        <b>Blog Post:</b> This template creates a well-rounded blog post, suggesting an article title, example keywords, and industry categories, based on the content of the selected topic notes.
                    </li>
                    <li>
                        <b>Website Page:</b> This template produces a well-organized, SEO-optimized webpage, suggesting a title and SEO-enhancing keywords, based on the content of the selected topic notes.
                    </li>
                    <li>
                        <b>Press Release:</b> This template generates a well-organized press release, including all the attributes of such a document: a strong headline, a relevant title, a boilerplate, and call-to-action statements, based on the content of the selected topic notes.
                    </li>
                    <li>
                        <b>Legal Article:</b> This template creates a well-rounded legal article, incorporating professional legal terminology and document structure. It also suggests an article title, based on the content of the selected topic notes.
                    </li>
                </ul>
            </div>
        </HelpLayout>
    );
}
