import { Alert, Button, Col, Progress, ProgressProps, Row, Spin, message } from "antd";
import Modal from "antd/es/modal/Modal";
import { errorHandler, toDateTime, toStorage } from "../../../utils/globals";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import ApiRequests from "../../../utils/api-requests";
import { useState } from "react";

interface Props {
	subscriptionId: string;
	memberId: string;
	onClose: () => void;
}

export default function SubscriptionManager({
	subscriptionId,
	memberId,
	onClose,
}: Props) {
	const [loading, setLoading] = useState(false);
	const queryClient = useQueryClient();
	const results = useQueries({
		queries: [
			{
				queryKey: ['companies-read', subscriptionId],
				queryFn: () => ApiRequests.Members.read(memberId),
			},
			{
				queryKey: ['services-read', subscriptionId],
				queryFn: () => ApiRequests.Services.read(subscriptionId),
			},
		]
	});

	const [Company, Service] = results;
	const strokeColors: ProgressProps['strokeColor'] = {
		'0%': '#87d068',
		'50%': '#ffe58f',
		'100%': '#e61905',
	};

	const mutation = useMutation({
		mutationFn: (id: string) => ApiRequests.Services.suspend(id),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['services-read', subscriptionId] });
			setLoading(false);
		},
		onError: (err) => {
			setLoading(false);
			message.error(errorHandler(err), 15);
		}
	});

	const fireSuspendUnsuspend = () => {
		setLoading(true);
		mutation.mutate(subscriptionId);


	};

	return (

		<>
			<Modal
				open={!(Company.isFetching && Service.isFetching)}
				onCancel={onClose}
				destroyOnClose
				width={1000}
				title={`Subscription Info`}
				footer={[
					<Button key="cancel" onClick={onClose}>
						Cancel
					</Button>
				]}
			>
				{(Company.isError || Service.isError) ? (
					<Alert
						message="Error"
						description={Company.isError
							? errorHandler(Company.error.message)
							: Service.error
								? errorHandler(Service.error.message)
								: "Unknown error"}
						type="error"
						showIcon
					/>
				) : (
					<>
						<div>
							<strong>Contact: </strong>{Company.data?.name} ({Company.data?.email})
						</div>

						<Row className="mt-20 mb-30">
							<Col span={12} className="txt-xlarge">
								<span>License information</span>
							</Col>
							<Col span={12} className="txt-xlarge">Usage</Col>
						</Row>
						<Row className="mt-20" gutter={[16, 16]}>
							<Col span={12}>

								<Row gutter={[16, 16]}>
									<Col span={12}>
										<strong>License Type</strong>
									</Col>
									<Col span={12}>
										{Service.data?.licenseInfo.serviceType}
									</Col>

									<Col span={12}>
										<strong>License Status</strong>
									</Col>
									<Col span={12}>
										{Service.data?.licenseInfo.statusDisplay}
									</Col>

									<Col span={12}>
										<strong>Storage Limit (GB)</strong>
									</Col>
									<Col span={12}>
										{Service.data?.licenseInfo.storageBudget ?
											toStorage(Service.data?.licenseInfo.storageBudget)
											: null}
									</Col>
									<Col span={12}>
										<strong>Questions Limit per Day</strong>
									</Col>
									<Col span={12}>
										{Service.data?.licenseInfo.quetionsBudget}
									</Col>
									{Service.data?.licenseInfo.validTo && Service.data?.licenseInfo.serviceType === 'Professional' ?
										<>
											< Col span={12}>
												<strong>Expiration date</strong>
											</Col>
											<Col span={12}>
												{toDateTime(Service.data?.licenseInfo.validTo)}
											</Col></>
										: null
									}

								</Row>

							</Col>
							{Service.data ? <>
								<Col span={12}>
									<Row gutter={[16, 16]}>
										<Col span={24}>
											Questions Asked Today
											<div>
												<Progress
													type="line"
													showInfo={true}
													size={[350, 20]}
													strokeColor={strokeColors}
													percent={Service.data.usageInfo.quetionsUsedPercent}
													format={() => `${Service.data.licenseInfo.quetionsActual}/${Service.data.licenseInfo.quetionsBudget}`}
													trailColor="#d9d9d9"

												/>
											</div>
										</Col>
										<Col span={24}>

											Storage (GB)
											<div>
												<Progress
													type="line"
													showInfo={true}
													size={[350, 20]}
													strokeColor={strokeColors}
													percent={Service.data.usageInfo.storageUsedPercent}
													format={() => `${toStorage(Service.data.licenseInfo.storageActual)}/${toStorage(Service.data.licenseInfo.storageBudget)}`}
													trailColor="#d9d9d9"

												/>
											</div>
										</Col>
										<Col>
											<div>
												{Service.data?.licenseInfo.status === 'Suspended' ?
													<Button type="primary" onClick={() => { fireSuspendUnsuspend() }}>
														Unsuspend License
													</Button>
													: Service.data?.licenseInfo.status !== 'NoService' ?
														<Button type="primary" danger onClick={() => { fireSuspendUnsuspend() }}>
															Suspend License
														</Button>
														: null}
											</div>
										</Col>
									</Row>


								</Col>


							</> : null}

						</Row>

					</>
				)}


				{(Company.isFetching || Service.isFetching || loading) && (
					<Spin className="full-page-spinner" fullscreen />
				)}
			</Modal>



		</>
	);
}
