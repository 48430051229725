import { Tooltip } from "antd";
import { PaymentResultModel, PaymentsSortColumns } from "../../../../../models/api/payments";
import { toDate } from "../../../../../utils/globals";
import { ColumnsType } from "antd/es/table";
import { OrdersPopModel } from "../../../../../popups/Orders/Orders";
import { SortInfo, SortTypes } from "../../../../../models/api/shared";
import { SortOrder } from "antd/es/table/interface";

type Props = {
	handleSubscriptionClick: (subscriptionId: string) => void;
	handleOrdersClick: (orderId: OrdersPopModel) => void;
	sortInfo: SortInfo<PaymentsSortColumns>;
};

const PaymentsTableColumns = ({ handleSubscriptionClick, handleOrdersClick, sortInfo }: Props): ColumnsType<PaymentResultModel> => {
	return [
		{
			title: "Id",
			dataIndex: "id",
			key: "Id",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Id" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined, //todo... nikola?
		},
		{
			title: "OrderId",
			dataIndex: "orderId",
			key: "OrderId",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "OrderId" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,//todo... nikola?
			render: (id: string, record: PaymentResultModel) => (
				<Tooltip title="View purchase order details">
					<span
						className="force-link"
						onClick={() => handleOrdersClick({ orderId: record.orderId })}
					>
						{id}
					</span>
				</Tooltip>
			),
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "Date",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Date" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,//todo... nikola?
			render: (text: string, record: PaymentResultModel) => (
				<Tooltip title="View purchase order details">
					<span
						className="force-link"
						onClick={() => handleOrdersClick({ orderId: record.orderId, paymentId: record.id })}
					>
						<span>{toDate(text)}</span>
					</span>
				</Tooltip>
			),
		},
		{
			title: "Payer email",
			dataIndex: ['payer', 'email'],
			key: "PayerEmail",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "PayerEmail" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,//todo... nikola?
			render: (email: string, record: PaymentResultModel) => (
				<Tooltip title="Click here to view subscription info">
					<span
						className="force-link"
						onClick={() => handleSubscriptionClick(record.payer.id)}
					>
						{email}
					</span>
				</Tooltip>
			),
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "Amount",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Amount" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,//todo... nikola?
		},
		{
			title: "Check",
			dataIndex: "check",
			key: "Check",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Check" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,//todo... nikola?
		},
		{
			title: "Transaction",
			dataIndex: "transaction",
			key: "Transaction",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Transaction" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,//todo... nikola?
		},
		{
			title: "Payer Name",
			dataIndex: ['payer', 'name'],
			key: "PayerName",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "PayerName" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,//todo... nikola?
			render: (payer: string, record: PaymentResultModel) => (
				<Tooltip title="Click here to view subscription info">
					<span
						className="force-link"
						onClick={() => handleSubscriptionClick(record.payer.id)}
					>
						{payer}
					</span>
				</Tooltip>
			),
		},
	];
};

export default PaymentsTableColumns;
