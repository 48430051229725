import { Button, Modal, Spin } from "antd";
import Title from "antd/es/typography/Title";
import { useDeleteProjectMutation } from "../../hooks/useDeleteProjectMutation";

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    recordId: number;
    handleMutationSuccess: () => void;
}

export default function DeleteModal({
    modalVisible,
    setModalVisible,
    recordId,
    handleMutationSuccess,
}: Props) {
    const deleteMutation = useDeleteProjectMutation({
        onSuccess: handleMutationSuccess,
    });

    const handleConfirmDelete = () => {
        const mutationModel = { Items: [recordId], Delete: true };
        deleteMutation.mutate(mutationModel);
        setModalVisible(false);
    };

    return (
        <>
            {deleteMutation.isPending && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        zIndex: 1000,
                    }}
                >
                    <Spin size="large" />
                </div>
            )}
                <Modal
                    title=""
                    open={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}
                >
                    <div style={{ textAlign: "center" }}>
                        <Title level={4}>Are you sure you want to delete this project?</Title>
                        <p>
                            Deleting a project will move it to the Trash. It can be recovered for the next 30 days.
                            <span style={{ color: "red", fontWeight: "600" }}>
                                {" "}
                                After 30 days it will be permanently deleted.
                            </span>
                        </p>
                        <Button type="primary" onClick={handleConfirmDelete}>
                            Delete
                        </Button>
                        <Button
                            style={{ marginLeft: "10px" }}
                            onClick={() => setModalVisible(false)}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal>

        </>
    );
}
