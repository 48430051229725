const ProjectSessionStorage = {
	create() {
		sessionStorage.setItem(getSSKey(), "1");
	},
	read() {
		return sessionStorage.hasOwnProperty(getSSKey())
	},
	remove() {
		sessionStorage.removeItem(getSSKey());
	},
	setFollowUpQuestion(question:string) {
		sessionStorage.setItem(getSSFollowUpQKey(), question);
	},
	getFollowUpQuestion() {
		return sessionStorage.hasOwnProperty(getSSFollowUpQKey()) ?
		 sessionStorage.getItem(getSSFollowUpQKey()) : null
	},
	removeFollowUpQuestion() {
		return sessionStorage.removeItem(getSSFollowUpQKey())
	}
}

export default ProjectSessionStorage;

function getSSKey(): string {
    return 'ss-start-stream'
}

function getSSFollowUpQKey(): string {
	return 'ss-start-stream-from-question'
}
