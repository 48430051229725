import { Button, Tooltip } from "antd";

interface Props {
	recordId: number;
	openArchiveModal: (id: number) => void;
}

export default function ArchiveButton({ recordId, openArchiveModal }: Props) {
	return (
		<Tooltip title="Archive">
			<Button
				onClick={() => openArchiveModal(recordId)}
				className="action-button"
			>
				<span className="ficon ficon-archive"></span>
			</Button>
		</Tooltip>
	);
}
