import Sharings from "./sharings/Sharings";
import IsFollowUp from "./discovery/IsFollowUp";
import { ProjectSharingInfo } from "../../../models/api/sharings";
import EditableTitle from "./EditableTitle";

interface Props {
    projectTitle: string;
    sharingInfo: ProjectSharingInfo;
    id: number;
    onTitleUpdated: (title: string) => void;
}
export default function ProjectHeader({ projectTitle, sharingInfo, id, onTitleUpdated}: Props) {
    if (sharingInfo.isFollowUp) {
        return (
            <IsFollowUp
                projectTitle={projectTitle}
                sharingInfo={sharingInfo}
                projectId={id}
                onTitleUpdated={onTitleUpdated}
            />
        );
    }

    return (
        <div className="page-title-wrap">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <EditableTitle
                    projectTitle={projectTitle}
                    projectId={id}
                    isOwner={sharingInfo.isOwner}
                    onTitleUpdated={onTitleUpdated}
                />

                <Sharings
                    projectId={sharingInfo.parentId}
                    accessMode={sharingInfo.accessMode}
                />
            </div>
        </div>
    );
}
