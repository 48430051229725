import { useEffect } from "react";
import { useHistoryProjects } from "../../../components/HistoryProjectsContext";
import { ProjectHeaderModel } from "../../../models/api/projects/projects";
import ProjectLocalStorage from "../utils/local-storage";

const useAddProjectToHistory = (projectData?: ProjectHeaderModel) => {
    const { setProjects } = useHistoryProjects();
    const maxIndex = 5;

    useEffect(() => {
        if (projectData) {
            const projectId = projectData.sharing.id;
            const projectName = projectData.title;

            setProjects((prevProjects) => {
                const oldProjects = [...prevProjects];
                const projectIndex = oldProjects.findIndex(
                    (project) => project.prId === projectId
                );

                if (projectIndex !== -1) {
                    oldProjects.splice(projectIndex, 1);
                }

                const updatedProjects = [
                    { prId: projectId, prName: projectName },
                    ...oldProjects,
                ];

                if (updatedProjects.length > maxIndex) {
                    updatedProjects.splice(maxIndex);
                }

                ProjectLocalStorage.createLastFive(updatedProjects);
                return updatedProjects;
            });
        }
    }, [projectData, setProjects]);
};

export default useAddProjectToHistory;
