import HelpLayout from "../Home";

export default function AccountSettingsHelp() {
	return (
		<HelpLayout activeTab="AccountSettings">
            <div className='help-margin-left' >
                <h3>Account Settings Module:</h3>
                <p>
                    This module allows you to review your account information and resource usage, change your password, get a new license, upgrade or cancel your existing paid license, or view your license and billing history.
                </p>

                <h3>How to change your password:</h3>
                <p>
                    The Sign In screen has a <b>"Forgot/Change password"</b> link. Locate it, click it, and follow the instructions to change your password. If you are signed in and you want to change your password, you must sign out first and then use the <b>"Forgot/Change password"</b> functionality. You can Log Out either from your User menu in the upper right-hand side corner of the TAVA Discovery Platform drop-down menu, or in your Account Settings menu.
                </p>

                <h3>Licensing:</h3>
                <p>
                    Each TAVA registered user automatically receives a <b>Free License</b>, which allows up to 20 free questions and answers per day and 1GB of storage for projects. This free license is perpetual. In case you need additional data storage or a higher number of questions and answers for more serious research work, you can easily upgrade to a higher tier.
                </p>
            </div>
		</HelpLayout>
	);
}