import { useQuery } from "@tanstack/react-query";
import AdministrationTabs from "../../components/Tabs";
import { useState } from "react";
import { PaymentResultModel, PaymentsSortColumns } from "../../../../models/api/payments";
import { Spin, TableProps, message } from "antd";
import { errorHandler, handleTableSort } from "../../../../utils/globals";
import { SortInfo, SortTypes } from "../../../../models/api/shared";
import ApiRequests from "../../../../utils/api-requests";
import PaymentsTableColumns from "./components/PaymentsTableColumns";
import SubscriptionManager from "../../components/SubscriptionManager";
import Orders, { OrdersPopModel } from "../../../../popups/Orders/Orders";
import Table from "../../../../components/antd-extensions/ExtendedTable";

export default function Payments() {
	const [sortInfo, setSortInfo] = useState<SortInfo<PaymentsSortColumns>>({
		sortColumn: "Id",
		sortType: SortTypes.Asc,
	});
	const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string | null>(null);
	const [selectedOrderModel, setSelectedOrderModel] = useState<OrdersPopModel | null>(null);

	const handleTableChange: TableProps<PaymentResultModel>['onChange'] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const administrationPayments = useQuery<PaymentResultModel[]>({
		queryKey: ["administrationPayments", sortInfo, sortInfo.sortColumn, sortInfo.sortType],
		queryFn: () =>
			ApiRequests.Payments.administrationPayments({
				sortColumn: sortInfo.sortColumn,
				sortType: sortInfo.sortType,
			}),
	});


	if (administrationPayments.isError) {
		message.error(errorHandler(administrationPayments.error.message));
	}

	const handleSubscriptionClick = (subscriptionId: string) => {
		setSelectedSubscriptionId(subscriptionId);
	};

	const handleOrdersClick = (ordersModel: OrdersPopModel) => {
		setSelectedOrderModel(ordersModel);
	};

	if (administrationPayments.isFetching) {
		return <Spin spinning={true} fullscreen />;
	}
	if (administrationPayments.error) {
		message.error(errorHandler(administrationPayments.error));
	}

	const columns = PaymentsTableColumns({ handleSubscriptionClick, handleOrdersClick, sortInfo });

	return (
		<>
			<AdministrationTabs activePanel={"payments"}></AdministrationTabs>
			<Table
				columns={columns}
				dataSource={administrationPayments.data}
				rowKey="id"
				onChange={handleTableChange}
				pagination={false}
			/>

			{selectedSubscriptionId ? (
				<SubscriptionManager
					key="subscription-modal"
					onClose={() => { setSelectedSubscriptionId(null); }}
					subscriptionId={selectedSubscriptionId}
					memberId={selectedSubscriptionId}
				/>
			) : null}

			{selectedOrderModel ? (
				<Orders
					key="orders-modal"
					onClose={() => { setSelectedOrderModel(null); }}
					model={selectedOrderModel}
				/>
			) : null}
		</>
	);
}
