import { Button, Flex } from "antd"

interface DigDeeperModel {
	data: string[],
	makeNewQuestion: (question: string) => void;
}

export default function DigDeeper(deeper: DigDeeperModel) {

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {

		const newQuestion = event.currentTarget.textContent;
		if (newQuestion)
			deeper.makeNewQuestion(newQuestion);
	};


	return (
		<>
			<div className="mb-15 text-semibold">Dig Deeper: </div>
			<Flex wrap gap="small">
				{
					deeper.data.map((topic, q) => (
						<span key={q}>
							<Button className="tag-btn btn-text-clip hbox vcbox" shape="round" onClick={handleClick} block>
								<span className='ficon ficon-dig fspace-r txt-large'></span>
								<span className="text-wrap">{topic}</span>
							</Button>
						</span>
					))
				}
			</Flex>
		</>
	)
}