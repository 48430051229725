import { Badge, Button, Popconfirm, Tooltip } from "antd";
import { getFirstNchars } from "../../../../utils/globals";
import { DocumentsGridResultModel } from "../../../../models/api/documents";

interface Props {
	document: DocumentsGridResultModel;
	projectId: number;
	handleEditButtonClick: (docId: number) => void;
	handleCommentToggle: (itemId: number, title: string) => void;
	handleDelete: (projectId: number, documentId: number) => void;
}

export default function ActionColumn({ document,
	projectId,
	handleEditButtonClick,
	handleCommentToggle,
	handleDelete
}: Props) {


	return (
		<div style={{ display: "flex", flexDirection: "row" }}>
			<Tooltip title="Comment">
				<Button
					onClick={() => { handleCommentToggle(document.id, getFirstNchars(document.name, 60)) }}
					className="action-button"
				>
					<Badge count={document.commentsCount}
						size="small" color={document.commentsCount > document.readCommentsCount ? "red" : "var(--extra-dark-gray)"}
						showZero>
						<span className="ficon  ficon-chat"></span>
					</Badge>
				</Button>
			</Tooltip>
			<Tooltip title="Edit">
				<Button onClick={() => handleEditButtonClick(document.id)}
					className="action-button"
				>
					<span className="ficon ficon-edit"></span>
				</Button>
			</Tooltip>
			<Tooltip title="Delete">
				<Popconfirm
					title="Are you sure you want to delete this document?"
					onConfirm={() => handleDelete(projectId, document.id)}
					okText="OK"
				>
					<Button
						className="action-button"
					>
						<span className="ficon ficon-delete"></span>
					</Button>
				</Popconfirm>
			</Tooltip>
		</div>
	);
}
