import { UseQueryResult } from "@tanstack/react-query";
import { PaymentResultModel } from "../../../../models/api/payments";
import { Drawer, List } from "antd";
import { toDate } from "../../../../utils/globals";

interface Props {
	query: UseQueryResult<PaymentResultModel[]>;
	drawerVisible: boolean;
	setDrawerVisible: (value: boolean) => void;
	selectedOrderId: number | null;
	handleDetailsClick: (orderId: number) => void;
}
export default function BillingsList({ query, handleDetailsClick }: Props) {
	return (
		<div>
			<List loading={query.isFetching || query.isLoading}>
				{query.data && query.data.length > 0 ? (
					query.data.map((payment) => (
						<List.Item className="list-item-row" key={payment.id}>
							<div className="list-item-col">
								<div className="f-box gap-05">
									Transaction #:<span className="text-bold">{payment.transaction}</span>
								</div>
								<div className="f-box gap-05">
									Order #:<span className="text-bold">{payment.orderId}</span>
								</div>
								<div className="f-box gap-05">
									Payment Date:<span className="text-bold">{toDate(payment.date)}</span>
								</div>
							</div>
							<div className="list-item-col">
								<div className="f-box gap-05">
									Check #: <span className="text-bold">{payment.check}</span>
								</div>
								<div className="f-box gap-05">
									Amount: <span className="text-bold">{payment.amount}</span>
								</div>

							</div>
							<div className="actions-wrap">
								<span
									onClick={() => handleDetailsClick(payment.orderId)}
									className="ficon ficon-eye link txt-xlarge mr-0"
								/>
							</div>

						</List.Item>
					))
				) : (
					<div style={{ textAlign: "center", padding: "2rem" }}>
						No data
					</div>
				)}
			</List>
		</div>
	);
}