import { ReactNode, createContext } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import NavigationBar from "./NavigationBar";
import { App, Layout } from "antd";
import { useMediaQuery } from "react-responsive";
import HeaderBar from "./HeaderBar";
import { HistoryProjectsProvider } from "./HistoryProjectsContext";

const { Footer, Content } = Layout;

interface PageLayoutProps {
	children: ReactNode;
}
export const MobileContext = createContext(false);

export default function PageLayout(props: PageLayoutProps) {
	const isMobile = useMediaQuery({ maxWidth: 768 });

	return (
		<App>
			<MobileContext.Provider value={isMobile}>
				<div className={`app-wrap ${isMobile ? 'mobile-view-mode' : 'desktop-view-mode'}`}>
					<Layout className="app-wrap-inner">

						{isMobile ? (
							<>
								<AuthenticatedTemplate>
									<Content>
										{props.children}
									</Content>
								</AuthenticatedTemplate>

								<HeaderBar />
							</>
						) : (
							<>

								<HeaderBar />

								<AuthenticatedTemplate>
									<Layout className="app-main">
										<HistoryProjectsProvider>
											<NavigationBar />
											<Content className="content-wrap layout-wrap">
												{props.children}
											</Content>
										</HistoryProjectsProvider>
									</Layout>
									<Footer className="app-footer">
										<p>&copy; Tava Discovery</p>
									</Footer>
								</AuthenticatedTemplate>
							</>
						)}
					</Layout>
				</div>
			</MobileContext.Provider>
		</App>
	);
}
