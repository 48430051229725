import React, { useState } from 'react';
import { Popover, Button } from 'antd';
import { Link } from 'react-router-dom';
export interface StickyHelpBtnProps {
    popContent: React.ReactNode,
    title: string,
    goToHelpLink: string
}

function getSSKey() {
    return 'ss-help-btn-stat';
}
function getBtnState() {
    return localStorage.getItem(getSSKey()) ? true : false;
}

export default function StickyHelpBtn({ popContent, title, goToHelpLink }: StickyHelpBtnProps) {
    const [open, setOpen] = useState<boolean>(getBtnState());

    const handleOpenChange = () => {
        // Do nothing on outside click
    };

    const closePopover = (event: React.MouseEvent) => {
        event.stopPropagation();
        localStorage.removeItem(getSSKey());
        setOpen(false);
    };

    const togglePopover = (event: React.MouseEvent) => {
        event.stopPropagation();
        const newState = !open;
        if (newState) {
            localStorage.setItem(getSSKey(), '1');
        } else {
            localStorage.removeItem(getSSKey());
        }
        setOpen(newState)
    };

    const content = (
        <div className='inner-wrap'>
            <div className='header-wrap'>
                <span>{title}</span>
                <div className={"ficon ficon-close"}
                    onClick={closePopover}
                    style={{ cursor: 'pointer' }}
                />
            </div>
            <div className='middle-wrap'>
                {popContent}
            </div>
            <div className='bottom-wrap'>
                <Link to={goToHelpLink}>Check TAVA Help Files</Link>
            </div>
        </div>
    );

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Popover
                content={content}            
                classNames={{ root: 'help-badge-popover' }}
                className={'help-badge-btn'}                
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
            >
                <Button type="primary" onClick={togglePopover}>
                    <span className='ficon ficon-help-circle'></span>
                </Button>
            </Popover>
        </div>
    );
};
