import { App, Button, Form, Input, Modal, Spin, Image, Avatar, Space } from "antd";
import { PlusOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ApiRequests from "../../../../utils/api-requests";
import { NoteGalleryImageModel, ProjectNoteUpdateModel } from "../../../../models/api/notes";
import { errorHandler } from "../../../../utils/globals";
import AddImageUrlModal from "./AddImageUrlModal";

interface NoteFormProps {
	noteId: number | null;
	projectId: number;
	onClose: () => void;
	onSuccess: () => void;
}

interface CustomGalleryImageModel {
	pageUrl: string;
	imageUrl: string;
}

export default function NoteForm({ noteId, projectId, onClose, onSuccess }: NoteFormProps) {
	const [form] = Form.useForm();
	const { message } = App.useApp();
	const [galleryImages, setGalleryImages] = useState<CustomGalleryImageModel[]>([]);
	const [isUrlModalVisible, setIsUrlModalVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewOpen, setPreviewOpen] = useState(false);

	const noteQuery = useQuery({
		queryKey: ["note", noteId, projectId],
		queryFn: async () => {
			if (noteId) {
				const data = await ApiRequests.Project.Notes.read(projectId, noteId)
				if (!data) return;

				form.setFieldsValue({
					content: data.text,
					url: data.sourceUrl,
				});

				if (data.gallery?.images) {
					const convertedImages: CustomGalleryImageModel[] = data.gallery.images.map((img) => ({
						pageUrl: img.pageUrl || '',
						imageUrl: img.imageUrl || ''
					}));
					setGalleryImages(convertedImages);
				}
				return data;
			}
		},
		enabled: !!noteId,
	});

	const mutation = useMutation({
		mutationFn: (values: { content: string; url: string }) => {
			const imagesToSend: NoteGalleryImageModel[] = galleryImages.map((customImage, index) => ({
				position: index,
				pageUrl: customImage.imageUrl,
				imageUrl: customImage.imageUrl
			}));

			const noteData: ProjectNoteUpdateModel = {
				text: values.content,
				sourceUrl: values.url,
				tags: [],
				images: [],
				topicsWrapper: { topics: [] },
				isOpenAi: false,
				gallery: { images: imagesToSend },
			};

			return noteId
				? ApiRequests.Project.Notes.update(projectId, noteId, noteData)
				: ApiRequests.Project.Notes.create(projectId, noteData);
		},
		onSuccess: () => {
			message.success(`Note ${noteId ? "updated" : "created"} successfully`);
			onSuccess();
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
	});

	const handleImageRemove = (index: number) => {
		setGalleryImages(prev => prev.filter((_, i) => i !== index));
	};

	const handleAddImage = (imageUrl: string) => {
		if (galleryImages.some(img => img.imageUrl === imageUrl)) {
			message.warning("This image is already in the gallery");
			return;
		}

		const newImage: CustomGalleryImageModel = {
			pageUrl: imageUrl,
			imageUrl: imageUrl
		};

		setGalleryImages(prev => [...prev, newImage]);
	};

	const handlePreview = (imageUrl: string) => {
		setPreviewImage(imageUrl);
		setPreviewOpen(true);
	};

	const isLoading = noteQuery.isFetching || mutation.isPending;

	return (
		<>
			<Modal
				className="popup-compact"
				open
				closable={!isLoading}
				onCancel={onClose}
				confirmLoading={isLoading}
				title={noteId ? "Edit Note" : "Create Note"}
				footer={[
					<Button key="cancel" onClick={onClose} disabled={isLoading}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={mutation.isPending}
						disabled={isLoading}
						onClick={() => form.submit()}
					>
						Submit
					</Button>,
				]}
			>
				<Spin spinning={isLoading}>
					<Form form={form} onFinish={mutation.mutate} layout="vertical">
						<Form.Item
							name="content"
							label="Note"
							rules={[{ required: true, message: "Please write a note!" }]}
						>
							<Input.TextArea autoSize={{ minRows: 3, maxRows: 15 }} />
						</Form.Item>

						<Form.Item name="url" label="URL" rules={[{ type: "url" }]}>
							<Input.TextArea autoSize={{ minRows: 1, maxRows: 10 }} />
						</Form.Item>

						<Form.Item label="Images">
							<Space size={16} wrap>
								{galleryImages.map((img, index) => (
									<div key={index} className="note-form-gallery-item">
										<Avatar
											src={img.imageUrl}
											shape="circle"
											className="note-form-gallery-image"
										/>
										<div className="note-form-image-actions">
											<Space>
												<Button
													type="text"
													icon={<EyeOutlined style={{ color: '#fff' }} />}
													onClick={() => handlePreview(img.imageUrl)}
												/>
												<Button
													type="text"
													icon={<DeleteOutlined style={{ color: '#fff' }} />}
													onClick={() => handleImageRemove(index)}
												/>
											</Space>
										</div>
									</div>
								))}


								<div
									className="note-form-add-image"
									onClick={() => setIsUrlModalVisible(true)}
								>
									<PlusOutlined />
									<div>Add Image</div>
								</div>

							</Space>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>

			<AddImageUrlModal
				visible={isUrlModalVisible}
				onCancel={() => setIsUrlModalVisible(false)}
				onAdd={handleAddImage}
			/>

			<Image
				wrapperStyle={{ display: 'none' }}
				preview={{
					visible: previewOpen,
					onVisibleChange: (visible) => setPreviewOpen(visible),
					afterOpenChange: (visible) => !visible && setPreviewImage(''),
				}}
				src={previewImage}
			/>
		</>
	);
}