import { Popconfirm } from "antd";
import { toDateTime } from "../../../../utils/globals";
import CommentForm from "./CommentForm";
import CommentMention from "./CommentMention";
import { CommentActiveModel, CommentComponentModel } from "./Comments";
import { ProjectAccessModes } from "../../../../models/api/shared";
import { useEffect, useRef } from "react";

interface CommentModel extends CommentComponentModel { }

interface CommentProps {
	comment: CommentModel;
	replies: CommentModel[];
	setActiveComment: (comment: CommentActiveModel | null) => void;
	activeComment: CommentActiveModel | null;
	updateComment: (text: string, commentId: number, parentId?: number) => void;
	deleteComment: (commentId: number, parentId?: number) => void;
	addComment: (text: string, parentId: number | null) => void;
	currentUserId: string;
	prAccess: ProjectAccessModes,
	focusedEl:number | null
}

const Comment: React.FC<CommentProps> = ({
	comment,
	replies,
	setActiveComment,
	activeComment,
	updateComment,
	deleteComment,
	addComment,
	currentUserId,
	prAccess,
	focusedEl
}) => {

	const commentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (focusedEl === comment.commentId && commentRef.current) {
			commentRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center"
			});
		}
	}, [focusedEl, comment.commentId]);

	const isActiveEl = activeComment && (
		(!activeComment.parentId && (!comment.parentId &&
			activeComment.id === comment.commentId))
		|| (activeComment.parentId && (comment.parentId &&
			activeComment.id === comment.commentId))
	);
	const isEditing =
		activeComment &&
		isActiveEl &&
		activeComment.type === "editing";
	const isReplying =
		activeComment &&
		isActiveEl &&
		activeComment.type === "replying";
	const canDelete = prAccess === 'Owner' ||
		currentUserId === comment.owner.id
	const canReply = true;
	const canEdit = prAccess === 'Owner' ||
		currentUserId === comment.owner.id;
	const createdAt = toDateTime(comment.owner.createdAt);

	const confirmDelete = () => {
		deleteComment(comment.commentId, comment.parentId)
	}

	return (
		<div key={comment.commentId} className="comment" ref={commentRef}>
			<div className="comment-image-container">
				<div className="comment-image-thumb">
					<span className="ficon ficon-user"></span>
				</div>
			</div>
			<div className="comment-right-part">
				<div className="comment-bubble">
					<span>{comment.owner.name}</span>{" "}
					<span className="text-dark-gray txt-small">
						({comment.owner.email})
					</span>
					<div className="text-dark-gray txt-small">
						{createdAt}
					</div>
					{!isEditing &&
						<CommentMention
							text={comment.text}
							onChangeText={() => { }}
							isReadonly={true}
							fireSubmitAfterShiftPlusEnter={() => {}}
						/>}
					{isEditing && (
						<CommentForm
							submitLabel="Update"
							hasCancelButton
							initialText={comment.text}
							handleSubmit={(text) =>
								updateComment(text, comment.commentId,
									comment.parentId)}
							handleCancel={() => {
								setActiveComment(null);
							}}
						/>
					)}
				</div>

				{!isEditing && 
					<div className="comment-actions">
						{canReply && (
							<div
								className="comment-action"
								onClick={() =>
									setActiveComment({
										id: comment.commentId,
										type: "replying",
										parentId: comment.parentId
									})
								}
							>
								Reply
							</div>
						)}
						{canEdit && (
							<div
								className="comment-action"
								onClick={() =>
									setActiveComment({
										id: comment.commentId,
										type: "editing",
										parentId: comment.parentId
									})
								}
							>
								Edit
							</div>
						)}
						{canDelete && (
							<Popconfirm
								title="Delete Comment"
								description="Are you sure you want to delete this comment?"
								onConfirm={confirmDelete}
							>
								<div
									className="comment-action"
								>
									Delete
								</div>
							</Popconfirm>
						)}
					</div>
				}
				{isReplying && (
					<div className={"mt-10"}>
					<CommentForm
						submitLabel="Reply"
						handleCancel={() => {
							setActiveComment(null);
						}}
						hasCancelButton={true}
							handleSubmit={(text) => addComment(text,
								comment.parentId ?? comment.commentId)}
					/></div>
				)}

				{replies.length > 0 && (
					<div className="replies">
						{replies.map((reply) => (
							<Comment
								comment={reply}
								key={reply.commentId}
								setActiveComment={setActiveComment}
								activeComment={activeComment}
								updateComment={updateComment}
								deleteComment={deleteComment}
								addComment={addComment}
								replies={[]}
								currentUserId={currentUserId}
								prAccess={prAccess}
								focusedEl={focusedEl}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default Comment;