import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Spin, TableProps, message } from "antd";
import { useState } from "react";
import LicenseModal from "./components/LicensesModal";
import TableColumns from "./components/TableColumns";
import ApiRequests from "../../../../utils/api-requests";
import { errorHandler, handleTableSort } from "../../../../utils/globals";
import { ServiceCatalogResultModel, ServiceCatalogSortColumns } from "../../../../models/api/service-catalogs";
import AdministrationTabs from "../../components/Tabs";
import { SortInfo, SortTypes } from "../../../../models/api/shared";
import Table from "../../../../components/antd-extensions/ExtendedTable";

export default function License() {
	const queryClient = useQueryClient();

	const [sortInfo, setSortInfo] = useState<SortInfo<ServiceCatalogSortColumns>>({
		sortColumn: "Id",
		sortType: SortTypes.Asc,
	});
	const [modalVisible, setModalVisible] = useState(false);
	const [editingMode, setEditingMode] = useState(false);
	const [idForEditing, setIdForEditing] = useState<number | null>(null);

	const handleEditClick = (id: number) => {
		setEditingMode(true);
		setModalVisible(true);
		setIdForEditing(id);
	};

	const handleCancelClick = () => {
		setEditingMode(false);
		setModalVisible(false);
		setIdForEditing(null);
	};

	const handleDeleteClick = (id: number) => {
		deleteLicense.mutate(id);
	};

	const handleTableChange: TableProps<ServiceCatalogResultModel>['onChange'] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const licenseGridQuery = useQuery({
		queryKey: ["licenseGridQuery", sortInfo],
		queryFn: () =>
			ApiRequests.ServiceCatalog.grid({
				sortColumn: sortInfo.sortColumn as ServiceCatalogSortColumns,
				sortType: sortInfo.sortType,
			}),
	});

	if (licenseGridQuery.isError) {
		message.error(errorHandler(licenseGridQuery.error.message));
	}

	const deleteLicense = useMutation({
		mutationKey: ["deleteMutation"],
		mutationFn: async (id: number) => {
			return ApiRequests.ServiceCatalog.delete(id);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["licenseGridQuery"] });
		},
		onError: (error) => {
			message.error(errorHandler(error));
		},
	});

	if (licenseGridQuery.isFetching) {
		return <Spin spinning={true} fullscreen />;
	}

	const columns = TableColumns({ handleDeleteClick, handleEditClick, sortInfo });

	return (
		<>
			<AdministrationTabs activePanel={"licenses"}></AdministrationTabs>
			<div
				className="force-link"
				style={{ margin: "1rem 0 1rem 0" }}
				onClick={() => {
					setModalVisible(true);
					setEditingMode(false);
				}}
			>
				<span className="ficon ficon-plus-circle mr-05"></span>
				<span>Create a license</span>
			</div>

			<LicenseModal
				open={modalVisible}
				onCancel={handleCancelClick}
				footer={false}
				afterClose={handleCancelClick}
				editingMode={editingMode}
				idForEditing={idForEditing}
			/>

			<Table
				columns={columns}
				dataSource={licenseGridQuery.data}
				pagination={false}
				onChange={handleTableChange}
				rowKey={"id"}
			/>
		</>
	);
}
