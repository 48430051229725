import { Tooltip, message } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import SharedUser from "./SharedUser";
import { errorHandler } from "../../../../utils/globals";
import { useState } from "react";
import useFetchQueries from "../sharing-manager/fetchQueries";
import SharingManager from "../sharing-manager/ SharingManager";
import { ProjectAccessModes } from "../../../../models/api/shared";

interface Props {
	projectId: number;
	accessMode: ProjectAccessModes;
}

export default function Sharings({ projectId, accessMode }: Props) {
	const [showSharingManager, setShowSharingManager] = useState<boolean>(false);

	const queryClient = useQueryClient();
	const usersQuery = useFetchQueries(projectId);

	const onModalClose = () => {
		setShowSharingManager(false);
		queryClient.invalidateQueries({ queryKey: ['actualAndInvitedUsers'] });
	};

	if (usersQuery.isError) {
		message.error(errorHandler(usersQuery.error.message));
	}

	return (
		(!usersQuery.isError || !usersQuery.isFetching) ? (
			<div style={{ display: "flex" }}>
				{usersQuery.data?.slice(0, 10).map((sharedUser, index) => (
					<SharedUser
						key={sharedUser.email}
						index={index}
						sharedUser={sharedUser}
						accessMode={accessMode}
					/>
				))}
				{accessMode === "Owner" ?
					<Tooltip title="Invite Users">
						<div onClick={() => setShowSharingManager(true)}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								padding: "1rem",
								height: "1.75rem",
								width: "1.75rem",
								borderRadius: "10rem",
								cursor: "pointer",
								backgroundColor: "white",
								borderStyle: "solid",
								borderWidth: "0.15rem",
								borderColor: "green",
								marginLeft: "-0.5rem", //How much each icon of a user overlaps the other
								boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)"
							}}
						>
							<span className="ficon ficon-plus" style={{ color: "green", fontSize: "1.25rem", zIndex: "auto" }}></span>
						</div>
					</Tooltip>
					: null}

				{showSharingManager && <SharingManager prId={projectId} onClose={onModalClose} />}
			</div>
		) : null
	);
}
