import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import ApiRequests from "../../../utils/api-requests";
import { errorHandler } from "../../../utils/globals";

export function usePermamentDeleteProjectMutation({
    onSuccess,
}: {
    onSuccess: () => void;
}) {
    return useMutation({
        mutationFn: (id: number) => ApiRequests.Project.permanentDelete(id),
        mutationKey: ["peramentDeleteProject"],
        onSuccess: onSuccess,
        onError: (error) => {
            message.error(errorHandler(error));
        },
    });
}
