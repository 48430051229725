import { useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, Spin } from 'antd';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from "../../authConfig";
import ApiRequests from '../../utils/api-requests';
import ProjectSessionStorage from './utils/session-storage';
import { AskTavaComponent } from './models/props';
import PanelButtons from './components/PanelButtons';
import ProjectPanelCollapse from './components/discovery/ProjectPanelCollapse';
import { PageRoutes } from '../../utils/pageRoutes';
import { errorHandler } from '../../utils/globals';
import { BoostInsightModel } from '../../models/api/boost_insights';
import { Panel } from '../../models/api/shared';
import ProjectHeader from './components/ProjectHeader';
import useAddProjectToHistory from './hooks/useAddProjectToHistory';
import StickyHelp from '../../components/StyckyHelp';

const Home = () => {
	const navigate = useNavigate();
	const querryClient = useQueryClient();
	const [searchParams] = useSearchParams();
	const params = useParams<{ id: string }>();
	const currentPage = searchParams.get('tab') as Panel;
	const incorrectProject = !params.id || !/^[0-9]*$/.test(params.id);
	const projectId = parseInt(params.id!);
	const startStream = ProjectSessionStorage.read();
	const [boosts, setBoosts] = useState<BoostInsightModel[] | []>([]);
	ProjectSessionStorage.remove();
	const [activePanel, setActivePanel] = useState<Panel>(currentPage ? currentPage : 'Discovery');
	const helpContent: React.ReactNode = (
		<div key="sticky-help-btn-content">
			<b>Ask TAVA</b> questions for answers with links and images. <br/>
			Use <b>"Dig Deeper"</b> for more info or ask more questions manually. <br />
			<b>Best Sources</b> provides top online resources and summaries. <br />
			Highlight text or use the entire answer to create <b>Notes</b>, crucial for research projects and document generation. <br />
			<b>Boost Insight</b> suggests additional topics based on project data.
		</div>
	);
	const querryKey = ['api-project-view', params.id];

	const fetchProjectData = useCallback(async () => {
		return ApiRequests.Project.view(projectId);
	}, [projectId]);

	const authRequest = {
		...loginRequest,
	};

	const projectDataQuery = useQuery({
		queryKey: querryKey,
		queryFn: async () => {
			const data = await fetchProjectData();
			const userData: AskTavaComponent = {
				...data,
				id: projectId,
				startStream: startStream
			};
			setBoosts(userData.boostInsightsWrapper.boostInsights);

			return userData;
		},
		enabled: !incorrectProject,
		refetchOnMount: true,
		refetchOnWindowFocus: false
	});

	const ProjectToHistoryDataModel = useMemo(() => {
		if (projectDataQuery.data) {
			return {
				sharing: projectDataQuery.data.sharing,
				title: projectDataQuery.data.name
			};
		}
		return undefined;
	}, [projectDataQuery.data]); 

	useAddProjectToHistory(ProjectToHistoryDataModel);

	const handlePanelChange = useCallback((key: Panel) => {

		if (key === "Documents") {
			navigate(PageRoutes.goToProjectDocs(projectId));
		} else if (key === "Notes") {
			navigate(PageRoutes.goToProjectNotes(projectId));
		} else setActivePanel(key);

	}, [navigate, projectId]);

	if (incorrectProject) {
		return (
			<Alert
				message="Error"
				description="This project does not exist!"
				type="error"
				showIcon
			/>
		);
	}

	function returnBoosts(boosts: BoostInsightModel[]) {
		setBoosts(boosts);
	}

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest as any}
		>
			<Spin className='full-page-spinner' spinning={projectDataQuery.isFetching}>
				{projectDataQuery.error ? (
					<Alert
						message="Error"
						description={errorHandler(projectDataQuery.error)}
						type="error"
						showIcon
					/>
				) : null}
				{!incorrectProject && projectDataQuery.data && !projectDataQuery.isFetching ? (
					<>
						<ProjectHeader
							projectTitle={projectDataQuery.data.name}
							sharingInfo={projectDataQuery.data.sharing}
							id={projectId}
							onTitleUpdated={(newTitle) => {
								const newData = { ...projectDataQuery.data, name: newTitle };
								querryClient.setQueryData(querryKey, newData);
							}}
						/>

						<PanelButtons activePanel={activePanel} onPanelChange={handlePanelChange} />
						{activePanel !== 'Notes' ? (
							<ProjectPanelCollapse
								userData={projectDataQuery.data}
								activePanel={activePanel}
								onPanelChange={handlePanelChange}
								boosts={boosts}
								returnBoosts={returnBoosts}
							/>
						) : ""}
						<StickyHelp goToHelpLink={PageRoutes.DiscoveryHelp()} popContent={helpContent} title={"Discoveries"}></StickyHelp>
					</>
				) : null}
			</Spin>
		</MsalAuthenticationTemplate>
	);
};

export default Home;
