import { TableProps, message } from "antd";
import { useContext, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ApiRequests from "../../../../../utils/api-requests";
import ArchivedGridTableColumns from "./TableColumns";
import DeleteModal from "../../../components/Modals/DeleteModal";
import RecoverModal from "../../../components/Modals/RecoverModal";
import { ProjectsGridResultModel, ProjectsGridSortColumns } from "../../../../../models/api/projects/projects";
import { SortInfo, SortTypes } from "../../../../../models/api/shared";
import Table from "../../../../../components/antd-extensions/ExtendedTable";
import { errorHandler, handleTableSort } from "../../../../../utils/globals";
import { MobileContext } from "../../../../../components/PageLayout";
import ProjectsTabs from "../ProjectsTabs";
import ArchivedList from "./ArchivedList";
import StickyHelp from "../../../../../components/StyckyHelp";
import { PageRoutes } from "../../../../../utils/pageRoutes";
export default function Archived() {
	const [sortInfo, setSortInfo] = useState<SortInfo<ProjectsGridSortColumns>>({
		sortColumn: "ModifiedAt",
		sortType: SortTypes.Desc
	});

	const [modalVisible, setModalVisible] = useState(false);
	const [recoverModalVisible, setRecoverModalVisible] = useState(false);
	const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);

	const isMobile = useContext(MobileContext);
	const [drawerVisible, setDrawerVisible] = useState(false);
	const helpContent: React.ReactNode = (
		<div key="sticky-help-btn-content">
			<b>Archived projects</b> are those you do not wish or need to be active. There are no automatic or scheduled processes and activities on them, and users you invited to collaborate have no access to these projects.
		</div>
	);

	const queryClient = useQueryClient();

	const projectsListQuery = useQuery({
		queryKey: ["archivedProjects", sortInfo],
		queryFn: () =>
			ApiRequests.Project.personalList({
				SortColumn: sortInfo.sortColumn,
				SortType: sortInfo.sortType,
				GridType: "Archived",
			}),
	});

	if (projectsListQuery.isError) {
		message.error(errorHandler(projectsListQuery.error.message))
	}

	const handleTableChange: TableProps<ProjectsGridResultModel>['onChange'] = (_, __, sorter) => {
		handleTableSort(sortInfo, sorter, setSortInfo);
	};

	const openDeleteModal = (id: number) => {
		setSelectedRecordId(id);
		setModalVisible(true);
	};

	const openRecoverModal = (id: number) => {
		setSelectedRecordId(id);
		setRecoverModalVisible(true);
	};

	const handleMutationSuccess = () => {
		queryClient.invalidateQueries({ queryKey: ['archivedProjects'] });
		setDrawerVisible(false);
		setSelectedRecordId(null);
	};

	const columns = ArchivedGridTableColumns({ openDeleteModal, openRecoverModal, sortInfo });

	return (
		<>
			<ProjectsTabs activeTab={"Archived"}></ProjectsTabs>
			{isMobile ?
				<ArchivedList
					query={projectsListQuery}
					openDeleteModal={openDeleteModal}
					openRecoverModal={openRecoverModal}
					drawerVisible={drawerVisible}
					setDrawerVisible={setDrawerVisible}
					setSelectedRecordId={setSelectedRecordId}
					selectedRecordId={selectedRecordId}
				/>
				: (
					<Table
						columns={columns}
						dataSource={projectsListQuery.data?.records}
						pagination={false}
						onChange={handleTableChange}
						loading={projectsListQuery.isFetching}
						rowKey="id"
					/>)}

			{selectedRecordId && (
				<>
					<DeleteModal
						modalVisible={modalVisible}
						setModalVisible={setModalVisible}
						recordId={selectedRecordId}
						handleMutationSuccess={handleMutationSuccess}
					/>
					<RecoverModal
						modalVisible={recoverModalVisible}
						setModalVisible={setRecoverModalVisible}
						recordId={selectedRecordId}
						handleMutationSuccess={handleMutationSuccess}
						activeTab={"Archived"}
					/>
				</>
			)}
			<StickyHelp goToHelpLink={PageRoutes.Overview()} popContent={helpContent} title={"Archived Projects"}></StickyHelp>
		</>
	);
}