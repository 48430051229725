import HelpLayout from "../Home";

export default function OverviewHelp() {
	return (
		<HelpLayout activeTab="Overview">
			<div className='help-margin-left'>
				<h3>Overview</h3>
				<p>
					<b>Active (or Personal) Projects:</b> All research projects that a user creates are kept in the Projects List,
						arranged chronologically.
					Each project can be edited, expanded, or deleted as needed.
				</p>
				<p>
					<b>Shared Projects:</b> These are projects shared with you by other TAVA users or team members,
					allowing for collaboration in a team environment.
					Additionally, you can share your own projects with others to foster teamwork.
				</p>
				<p>
					<b>Archived Projects:</b> Archived projects save space from your allotted storage based on your license type.
					Archived projects are dormant and will not auto-update.
					You have the option to recover archived projects back to your active list or delete them.

				</p>
				<p>
					<b>Trash:</b> Projects can't be permanently deleted without a 30-day safety period.
					During this period, you can recover a deleted project before it is permanently erased.

				</p>
			</div>
			
		</HelpLayout>
	);
}