import { CommentCreateModel, CommentMarkAsReadModel, CommentsWrapperModel, ICommentsController } from "../../../../models/api/comments";
import ApiRequests from "../../../../utils/api-requests";

export const getComments = async (type: ICommentsController,
	prId: number, itemId: number): Promise<CommentsWrapperModel> => {
	return type.list(prId, itemId)
};

export const createComment = async (type: ICommentsController,
	prId: number, itemId: number, model: CommentCreateModel): Promise<number> => {
	return type.create(prId, itemId, model);
};

export const createReply = async (type: ICommentsController,
	prId: number, itemId: number, commentId: number, model: CommentCreateModel): Promise<number> => {
	return type.Replies.create(prId, itemId, commentId, model);
};

export const updateComment = async (type: ICommentsController,
	prId: number, itemId: number, commentId: number, model: CommentCreateModel): Promise<number> => {
	return type.update(prId, itemId, commentId, model);
};

export const updateReply = async (type: ICommentsController,
	prId: number, itemId: number, commentId: number, replyId: number, model: CommentCreateModel): Promise<number> => {
	return type.Replies.update(prId, itemId, commentId, replyId, model);
};

export const deleteComment = async (type: ICommentsController,
	prId: number, itemId: number, commentId: number): Promise<number> => {
	return type.delete(prId, itemId, commentId);
};

export const deleteCommentReply = async (type: ICommentsController,
	prId: number, itemId: number, commentId: number, replyId:number): Promise<number> => {
	return type.Replies.delete(prId, itemId, commentId, replyId);
};

export const apiReturnNoteType = (): ICommentsController => {
	return ApiRequests.Comments.Notes;
}

export const apiReturnDocType = (): ICommentsController => {
	return ApiRequests.Comments.Notes;
}

export const apiMarkAsRead = async (type: ICommentsController,
	prId: number, itemId: number, model: CommentMarkAsReadModel): Promise<CommentMarkAsReadModel> => {
	return type.markAsRead(prId, itemId, model);
};