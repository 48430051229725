import { Flex, Image } from "antd";

interface RelatedImagesModel {
	data: {
		position: number,
		pageUrl?: string,
		imageUrl?: string,
	}[]
}

export default function Sources(images: RelatedImagesModel) {

	return (
		<>
			
			<Flex wrap gap="small">
				<Image.PreviewGroup
					preview={{
						onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
					}}
				>
					{images.data.map((img, q) => (

						<Image key={q}
							height={100}
							src={img.imageUrl}
						/>

					))}
				</Image.PreviewGroup>
			</Flex>
		</>
	)
}