import { useState } from "react";
import Title from "antd/es/typography/Title";
import { Modal, Input, message, Form } from "antd";
import { EditOutlined } from "@ant-design/icons";
import ApiRequests from "../../../utils/api-requests";
import { useMutation } from "@tanstack/react-query";
import { errorHandler } from "../../../utils/globals";

interface EditableTitleProps {
    projectTitle: string;
    projectId: number;
    isOwner: boolean;
    onTitleUpdated: (title: string) => void;
}
export default function EditableTitle({ projectTitle, projectId, isOwner, onTitleUpdated }: EditableTitleProps) {
    const [form] = Form.useForm();
    const [isHovering, setIsHovering] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        form.setFieldValue("title", projectTitle);
        setIsModalOpen(true);
    };

    const handleRaname = useMutation({
        mutationFn: async (title: string) => {
            return ApiRequests.Project.renamePut(projectId, { title });
        },
        onSuccess: (_, newTitle) => {
            onTitleUpdated(newTitle);
            setIsModalOpen(false);
        },
        onError: (error) => {
            message.error(errorHandler(error), 15);
        },
    })

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            handleRaname.mutate(values.title);
        } catch (err) {
            message.error("Error changing the title");
        }
    };

    return (
        <div
            style={{ display: "flex", alignItems: "center", flex: 1 }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <Title className="page-title" level={1} style={{ margin: "0.5rem 0 0 0" }}>
                {projectTitle}
            </Title>

            {isOwner && isHovering && (
                <EditOutlined
                    style={{ marginLeft: 8, cursor: "pointer" }}
                    onClick={showModal}
                />
            )}

            <Modal
                title="Rename Project"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={() => setIsModalOpen(false)}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="renameProjectForm"
                    initialValues={{ title: projectTitle }}
                >
                    <Form.Item
                        label="Project Title"
                        name="title"
                        rules={[
                            { required: true, message: "Please input a project title!" },
                            { min: 5, message: "Title must be at least 5 characters long" },
                            { max: 256, message: "Title must be no longer than 256 characters" },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
