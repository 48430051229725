import { Button, Modal, Spin } from "antd";
import { usePermamentDeleteProjectMutation } from "../../hooks/usePermamentDeleteProjectMutation";

interface Props {
	modalVisible: boolean;
	setModalVisible: (value: boolean) => void;
	recordId: number;
	handleMutationSuccess: () => void;
}

export default function PermanentlyDeleteModal({
	modalVisible,
	setModalVisible,
	recordId,
	handleMutationSuccess,
}: Props) {

	const permanentDeleteMutation = usePermamentDeleteProjectMutation({
		onSuccess: handleMutationSuccess,
	});

	const handleConfirmDelete = () => {
		permanentDeleteMutation.mutate(recordId);
		setModalVisible(false);
	};

	return (
		<>
			{permanentDeleteMutation.isPending && (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(255, 255, 255, 0.8)",
						zIndex: 1000,
					}}
				>
					<Spin size="large" />
				</div>
			)}
			<Modal
				title="Permanently Delete"
				open={modalVisible}
				onCancel={() => setModalVisible(false)}
				footer={null}
			>
				<div style={{ textAlign: "center" }}>
					<p>Are you sure you want to permanently delete this project?</p>
					<Button type="primary" onClick={handleConfirmDelete}>
						Permanently Delete
					</Button>
					<Button style={{ marginLeft: "10px" }} onClick={() => setModalVisible(false)}>
						Cancel
					</Button>
				</div>
			</Modal>
		</>

	);
}
