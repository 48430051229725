import { Button } from "antd";
import { copyToClipboard, stripHTMLTags } from "../utils/globals";

interface Props {
	text: string | undefined;
}

export default function CopyToClipboard({ text }: Props) {

	return (
		<Button onClick={() => {
			if (text) {
				copyToClipboard(stripHTMLTags(text))
			}
		}} style={{ marginRight: "1rem", border: "none" }}>
			<span className="ficon ficon-copy" style={{ marginRight: "0.5rem" }}></span>
			Copy to clipboard
		</Button>
	);
}
