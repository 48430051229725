import { useNavigate } from "react-router-dom";
import Title from "antd/es/typography/Title";
import { Tabs, Button } from "antd";
import { ProjectTabsType } from "../../models/models";
import { PageRoutes } from "../../../../utils/pageRoutes";

interface TableProps {
	activeTab?: ProjectTabsType;
}

export default function ProjectsTabs({ activeTab }: TableProps) {
	const navigate = useNavigate();

	const items: { key: ProjectTabsType; label: React.ReactNode }[] = [
		{
			key: "Personal",
			label: "Personal",
		},
		{
			key: "Shared",
			label: "Shared",
		},
		{
			key: "Archived",
			label: "Archived",
		},
		{
			key: "Trash",
			label: "",
		},
	];

	const handleTabChange = (key: ProjectTabsType) => {
		switch (key) {
			case "Personal":
				navigate(PageRoutes.PersonalProjects());
				break;
			case "Shared":
				navigate(PageRoutes.SharedProjects());
				break;
			case "Archived":
				navigate(PageRoutes.ArchivedProjects());
				break;
			case "Trash":
				navigate(PageRoutes.TrashedProjects());
				break;
		}
	};

	return (
		<>
			<div>
				<Title className="page-title no-sep" level={1}>
					Projects
				</Title>
			</div>
			<div>
				<Tabs
					items={items}
					defaultActiveKey={activeTab}
					onChange={(key) => handleTabChange(key as ProjectTabsType)}
					tabBarExtraContent={{
						right: (
							<Button
								className={`trash-tab ${activeTab === "Trash" ? "ant-tabs-tab-active" : ""}`}
								onClick={() => handleTabChange("Trash")}
							>
								Trash
							</Button>
						),
					}}
				/>
			</div>
		</>
	);
}
