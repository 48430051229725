import { Routes, Route, Navigate } from "react-router-dom";
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import PageLayout from "./components/PageLayout";
import Home from "./pages/Home";
//import Projects from "./pages/projects/Home";
import Project from "./pages/project/Home";
import ProjectDocs from "../src/pages/project/docs/Home";
import ProjectNotes from "./pages/project/notes/Home";
import { PageRoutes } from "./utils/pageRoutes";
import License from "./pages/settings/License";
import Licenses from "./pages/administraion/tabs/licenses/Licenses";
import Payments from "./pages/administraion/tabs/payments/Payments";
import Subscriptions from "./pages/administraion/tabs/subscriptions/Subscriptions";
import AccountBilling from "./pages/settings/billing/AccountBilling";
import Orders from "./pages/administraion/tabs/orders/Orders";
import Rejected from "./pages/settings/provider/Rejected";
import Approved from "./pages/settings/provider/Approved";
import Shared from "./pages/projects/components/tabs/shared/Shared";
import Archived from "./pages/projects/components/tabs/archived/Archived";
import Trashed from "./pages/projects/components/tabs/trash/Trashed";
import Personal from "./pages/projects/components/tabs/personal/Personal";
import AccountHome from "./pages/settings/account/Home";
import GettingStarted from "./pages/help/tabs/GettingStarted.tsx";
import AccountSettingsHelp from "./pages/help/tabs/AccountSettingsHelp";
import DiscoveryHelp from "./pages/help/tabs/DiscoveryHelp";
import NotesHelp from "./pages/help/tabs/NotesHelp";
import DocumentsHelp from "./pages/help/tabs/DocumentsHelp";
import ShareProjectHelp from "./pages/help/tabs/ShareProject";
import OverviewHelp from "./pages/help/tabs/Overview";

function Pages() {
	const isAuthenticated = useIsAuthenticated();

	return (
		<Routes>
			{isAuthenticated ? (
				<>
					<Route path={PageRoutes.Home()} element={<Home />} />
					<Route path={PageRoutes.PersonalProjects()} element={<Personal />} />
					<Route path={PageRoutes.Project()} element={<Project />} />
					<Route path={PageRoutes.ProjectNotes()} element={<ProjectNotes />} />
					<Route path={PageRoutes.ProjectDocs()} element={<ProjectDocs />} />
					<Route path={PageRoutes.account()} element={<AccountHome />} />
					<Route path={PageRoutes.license()} element={<License />} />
					<Route path={PageRoutes.subscriptions()} element={<Subscriptions />} />
					<Route path={PageRoutes.licenses()} element={<Licenses />} />
					<Route path={PageRoutes.payments()} element={<Payments />} />
					<Route path={PageRoutes.accountBilling()} element={<AccountBilling />} />
					<Route path={PageRoutes.ProviderRejected()} element={<Rejected />} />
					<Route path={PageRoutes.ProviderApproved()} element={<Approved />} />
					<Route path={PageRoutes.orders()} element={<Orders />} />
					<Route path={PageRoutes.SharedProjects()} element={<Shared />} />
					<Route path={PageRoutes.ArchivedProjects()} element={<Archived />} />
					<Route path={PageRoutes.TrashedProjects()} element={<Trashed />} />
					<Route path={PageRoutes.HelpHome()} element={<GettingStarted />} />
					<Route path={PageRoutes.AccountSettingsHelp()} element={<AccountSettingsHelp />} />
					<Route path={PageRoutes.DiscoveryHelp()} element={<DiscoveryHelp />} />
					<Route path={PageRoutes.NotesHelp()} element={<NotesHelp />} />
					<Route path={PageRoutes.DocumentsHelp()} element={<DocumentsHelp />} />
					<Route path={PageRoutes.ShareProjectHelp()} element={<ShareProjectHelp />} />
					<Route path={PageRoutes.Overview()} element={<OverviewHelp />} />

				</>
			) : (
				<Route path="*" element={<Navigate to={PageRoutes.Home()} replace />} />
			)}
		</Routes>
	);
}

interface AppProps {
	instance: PublicClientApplication;
}

export default function App({ instance }: AppProps) {
	return (
		<MsalProvider instance={instance}>
			<PageLayout>
				<Pages />
			</PageLayout>
		</MsalProvider>
	);
}
