import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from "../authConfig";
import { Button, Form, FormProps, Input, Spin, message } from 'antd';
import ApiRequests from '../utils/api-requests';
import { useMutation } from '@tanstack/react-query';
import Layout from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '.././utils/pageRoutes'
import { ProjectCreateModel } from '../models/api/projects/projects';
import ProjectSessionStorage from './project/utils/session-storage';
import { errorHandler } from "../utils/globals";
import { MobileContext } from "../components/PageLayout";
import { useContext } from 'react';
import StickyHelp from '../components/StyckyHelp';


export default function Home() {
	const isMobile = useContext(MobileContext);
	const navigate = useNavigate();
	const helpContent: React.ReactNode = (
		<div key="sticky-help-btn-content">
			Enter a topic and click "Go." <b>TAVA Discovery</b> is an AI-powered research platform that automates research and content generation. After the project starts, use Q&A Discovery and <b>Dig Deeper</b> modules to expand research. Note-taking, data tagging, and efficient document generation features are included for all projects.
		</div>
	);

	const authRequest = {
		...loginRequest,
	};

	type FieldType = {
		question: string;
	};

	const { mutate, isPending } = useMutation({
		mutationFn: (model: ProjectCreateModel) => {
			return ApiRequests.Project.create(model)
		},
		onError: (error) => {
			message.error(errorHandler(error.message));
		}
	});

	const onFinish: FormProps<FieldType>['onFinish'] = (values) => {

		let model: ProjectCreateModel = {
			title: values.question
		}

		mutate(model, {
			onSuccess: (id) => {
				ProjectSessionStorage.create();
				navigate(PageRoutes.goToProject(id));
			},
			onError: (error) => {
				message.error(errorHandler(error), 15);
			}
		})

	};

	const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest as any}
		>
			<Layout className={`${isMobile ? 'content-wrap-inner vbox cbox vcbox gap-60' : 'content-wrap-inner compact-xs vbox cbox vcbox' }`}>
			{isMobile &&
				<div className="app-logo-landing ani-active ani-fadein-up">
					<img src="/logo-vertical-landing.svg" alt="Tava Discovery Application Logo" />
				</div>
			}
				<section className={`${isMobile ? 'mobile-width ask-tava-box ani-active ani-fadein-up ani-delay-1s' : 'full-width main-search pan-gray'} text-center`}>
					<div className='compact-wrap compact-xxs vbox cbox'>
						<h1 className='accent-title title-h1 txt-ani-entrance fbox gap-10'>
							<span className='ani-el'>Start</span> 
							<span className='ani-el'>Your</span> 
							<span className='ani-el'>Research</span>
							<span className='ani-el'>Project</span> 
						</h1>
						<Spin className='full-page-spinner' spinning={isPending}>
							<Form
								name="basic"
								initialValues={{ remember: true }}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								autoComplete="off"
							>
								<Form.Item<FieldType>
									name="question"
									rules={[{ required: true, message: 'Please fill the question!' },
									{ min: 4, message: 'The question should contain at least 4 characters.' }]}
								>
									<Input size="large" placeholder="Enter Topic Here..." />
								</Form.Item>

								<Form.Item className='hbox endbox'>
									<Button className='btn-h-large' type="primary" htmlType="submit">
										GO
										<span className='ficon ficon-tava-go fspace-l'></span>
									</Button>
								</Form.Item>
							</Form>
							<StickyHelp goToHelpLink={PageRoutes.HelpHome()} popContent={helpContent} title={"Start Research Project"}></StickyHelp>
						</Spin>
					</div>
				</section>
			</Layout>
		</MsalAuthenticationTemplate>
	);

}  
