import { useState, useCallback } from "react";
import {
	Spin,
	message,
	Divider,
	Button,
	Tooltip,
	Avatar,
	Row,
	Col,
	Badge,
	Typography,
} from "antd";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from 'react-router-dom';
import ApiRequests from "../../../../utils/api-requests";
import NoteContent from "./NoteContent";
import ExportToDocument from "./ExportToDocument";
import { errorHandler, getFirstNchars, getInitials, newLineToBr, toDateTime } from "../../../../utils/globals";
import DeleteNoteButton from "./DeleteNoteButton";
import { PageRoutes } from "../../../../utils/pageRoutes";
import { ModifierModel, ProjectAccessModes, ReplyReadModel } from "../../../../models/api/shared";
import { NoteGalleryModel, ProjectNoteCommentReadModel } from "../../../../models/api/notes";
import CommentsPopup from "../../components/comments/CommentsPopup";
import Filter from "./Filter";
import TagsModal from "./TagsModal";
import TagsList from "./TagsList";
import RelatedImages from "../../components/discovery/related-images";
import CreateOrUpdateNote from "./CreateOrUpdateNote";
import ContentSourceGallery from "./ContentSourceGallery";

interface PropsNotes {
	data: {
		id: number,
		prTitle: string,
		goToPrId: number,
		projAccess: ProjectAccessModes
	}
}

export interface ProjectNoteComponentListModel {
	id: number;
	modifier: ModifierModel;
	text: string;
	ownerId: string;
	commentsCount: number;
	readCommentsCount: number;
	checked?: boolean;
	readingMore?: boolean;
	initials?: string;
	tags: string[],
	sourceUrl?: string,
	gallery: NoteGalleryModel,
	visible?: boolean,
}

interface ProjectNotesGridModel {
	notes: ProjectNoteComponentListModel[];
	comments: ProjectNoteCommentReadModel[];
	replies: ReplyReadModel[];
}

interface commentsModel {
	id: number,
	title: string
}

export default function ProjectNotes(props: PropsNotes) {
	const navigate = useNavigate();

	const queryClient = useQueryClient();
	const [notesForExport, setNotesForExport] = useState<ProjectNoteComponentListModel[] | null>(null);
	const [comment, setCommentNote] = useState<commentsModel | null>(null);
	const [filterValues, setFilterValues] = useState<string[]>([]);
	const [filterAtocomplValues, setFilterAtocomplValues] = useState<string[]>([]);
	const params = useParams<{ id: string }>();
	const [projectId] = useState(parseInt(params.id!));
	const [noteToUpdateTags, setNoteToUpdateTags] = useState<number | null>(null);
	const [noteToUpdate, setNoteToUpdate] = useState<number | null>(null);
	const [createOrUpdateNote, setCreateOrUpdateNote] = useState<boolean>(false);

	const fetchProjectNotes = useCallback(async (): Promise<ProjectNotesGridModel> => {
		return ApiRequests.Project.Notes.grid(props.data.id);
	}, [props.data.id]);

	const checkTagsFilterVisible = (tags: string[], filter: string[]): boolean => {
		if (!filter.length)
			return true;

		return filter.some(value => tags.includes(value));
	};

	const getUniqueTags = (notes: ProjectNoteComponentListModel[]): string[] => {
		const uniqueTags: string[] = [];
		const tagsSet = new Set<string>();

		for (const note of notes) {
			for (const tag of note.tags) {
				if (!tagsSet.has(tag)) {
					tagsSet.add(tag);
					uniqueTags.push(tag);
				}
			}
		}

		return uniqueTags;
	};

	const projectNotesQuery = useQuery({
		queryKey: ["projectNotes", props.data.id],
		queryFn: async () => {
			const data = await fetchProjectNotes();
			data.notes.forEach((n) => {
				n.modifier.modifiedAt = toDateTime(n.modifier.modifiedAt);
				n.initials = getInitials(n.modifier.name);
				n.checked = false;
				n.readingMore = false;
				n.text = newLineToBr(n.text);
				n.visible = checkTagsFilterVisible(n.tags, []);
			});

			setFilterValues([]);
			setFilterAtocomplValues(getUniqueTags([...data.notes]));
			return data;
		},
		refetchOnMount: true,
		refetchOnWindowFocus: false,
	});

	const refetchNotes = () => {
		queryClient.invalidateQueries({
			queryKey: ["projectNotes", props.data.id],
		});
	};

	const handleCommentToggle = (noteId: number, title: string) => {
		setCommentNote({ id: noteId, title: title });
	};

	const UpdateReadingMode = useMutation({
		mutationFn: async ({ noteId }: { noteId: number }) => {
			const updatedNotes = projectNotesQuery.data?.notes.map((note) =>
				note.id === noteId ? { ...note, readingMore: note.readingMore ? false : true } : note
			);
			return updatedNotes;
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
		onSuccess: (data) => {
			if (projectNotesQuery.data?.notes && data) {
				projectNotesQuery.data.notes = data;
			}
		},
	});

	const handleUpdateReading = (noteId: number) => {
		UpdateReadingMode.mutate({ noteId: noteId });
	};


	const handleCreateUpdateSuccess = () => {
		refetchNotes();
		setCreateOrUpdateNote(false);
		setNoteToUpdate(null);
	};

	const handleDelete = () => {
		refetchNotes();
	};

	const handleExportShow = () => {
		projectNotesQuery.data?.notes ?
			setNotesForExport([...projectNotesQuery.data?.notes].filter((note) => note.visible))
			: setNotesForExport([]);
	};

	const handleCloseExport = () => {
		setNotesForExport(null);
	};

	const handleGoToDocumentsTab = () => {
		navigate(PageRoutes.goToProjectDocs(props.data.goToPrId));
	};

	const resetCommentCounter = useMutation({
		mutationFn: async ({ noteId, readCount }: { noteId: number, readCount: number }) => {
			const updatedNotes = projectNotesQuery.data?.notes.map((note) =>
				note.id === noteId ? {
					...note,
					commentsCount: note.commentsCount = readCount,
					readCommentsCount: readCount
				} : note
			);
			return updatedNotes;
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
		onSuccess: (data) => {
			if (projectNotesQuery.data?.notes && data) {
				projectNotesQuery.data.notes = data;
			}
		},
	});

	const filterNotesMutate = useMutation({
		mutationFn: async ({ filter }: { filter: string[] }) => {
			const updatedNotes = projectNotesQuery.data?.notes.map((note) =>
			({
				...note,
				visible: checkTagsFilterVisible(note.tags, filter),
			}));

			return updatedNotes;
		},
		onError: (error) => {
			message.error(errorHandler(error), 15);
		},
		onSuccess: (data) => {
			if (projectNotesQuery.data?.notes && data) {
				projectNotesQuery.data.notes = data;
			}
		},
	});

	const handleUpdateCounter = (noteId: number, counter: number) => {
		resetCommentCounter.mutate({
			noteId: noteId,
			readCount: counter
		});
	};

	const handleFilter = (values: string[]) => {
		if (projectNotesQuery.data?.notes) {
			setFilterValues(values);
			filterNotesMutate.mutate({
				filter: values
			});
		}
	};

	const handleCreateUpdateNote = (noteId: number | null) => {
		setCreateOrUpdateNote(true);
		setNoteToUpdate(noteId);
	}

	const handleClose = () => {
		setCreateOrUpdateNote(false);
		setNoteToUpdate(null);
	}

	return (
		<>
			<Spin spinning={projectNotesQuery.isFetching}>

				{notesForExport && projectNotesQuery.data ?
					<ExportToDocument
						key={"export-doc-comp"}
						data={{
							notes: notesForExport,
							prId: props.data.id,
							prName: props.data.prTitle
						}}
						handleClose={handleCloseExport}
						handleCreateDocument={handleGoToDocumentsTab}
						selectedTags={filterValues}
					/> : null}

				{!notesForExport && !projectNotesQuery.isFetching ? (
					<>
						<div key={'note-actions-row'}
							style={{ display: "flex" }}
							className="gap-20"
						>
							<div className="force-link" onClick={() => handleCreateUpdateNote(null)}>
								<span className="ficon ficon-plus-circle mr-05"></span>
								<span>Add a Note</span>
							</div>
							<div className="force-link" onClick={() => handleExportShow()} >
								<span className="ficon ficon-plus-circle mr-05"></span>
								<span>Create Document</span>
							</div>
						</div>
						<Divider />
						<Filter autocomplValues={filterAtocomplValues}
							returnValues={(val) => { handleFilter(val) }}
							selectedTags={filterValues}
							key={'filter-ctrl'} />
						<Divider />
						{projectNotesQuery.data?.notes.map((note, i) => (
							<div key={'note-row-comp' + i} className={note.visible ? '' : 'hidden-row'}>
								<div
									className={"note-unit"}
									key={'note-unit-' + i}
								>
									<Row gutter={16} wrap={false} className="pb-10">
										<Col className="gutter-row" flex="none">
											<Avatar
												style={{
													backgroundColor: "green",
													verticalAlign: "middle",
												}}
												size={30}
												gap={1}
											>
												{note.initials}
											</Avatar>
										</Col>
										<Col className="gutter-row" flex="auto">
											<span>{note.modifier.name}</span>{" "}
											<span className="text-light-gray txt-small">
												({note.modifier.email})
											</span>
											<div className="text-light-gray txt-small">
												{note.modifier.modifiedAt}
											</div>
										</Col>
										<Col className="gutter-row" flex="none">
											<div className='hbox endbox topbox'>
												<Tooltip title="Comment">
													<Button
														type="text"
														className="action-btn"
														onClick={() => handleCommentToggle(note.id, getFirstNchars(note.text, 60))}
													>
														<Badge count={note.commentsCount}
															size="small" color={note.commentsCount > note.readCommentsCount ? "red" : "var(--extra-dark-gray)"}
															showZero>
															<span className="ficon  ficon-chat"></span>
														</Badge>
													</Button>
												</Tooltip>
												<Tooltip title="Tags">
													<Button
														type="text"
														className="action-btn"
														onClick={() => setNoteToUpdateTags(note.id)}
													>
														<span className="ficon  ficon-tag"></span>
													</Button>
												</Tooltip>
												<Tooltip title="Edit">
													<Button
														type="text"
														className="action-btn"
														onClick={() => handleCreateUpdateNote(note.id)}
													>
														<span className="ficon ficon-edit"></span>
													</Button>
												</Tooltip>
												<DeleteNoteButton
													noteId={note.id}
													projectId={props.data.id}
													onDeleteToggle={() => handleDelete()}
												></DeleteNoteButton>
											</div>
										</Col>
									</Row>

									<ContentSourceGallery key={"CSG-prNotes-component"} note={note} handleUpdateReading={handleUpdateReading}></ContentSourceGallery>

									<TagsList title={"Tags:"} tags={note.tags} />
								</div>
								<Divider />
							</div>
						))}
					</>
				) :
					null
				}

				{createOrUpdateNote ? (
					<CreateOrUpdateNote
						onClose={handleClose}
						noteId={noteToUpdate}
						onSuccess={handleCreateUpdateSuccess}
						projectId={projectId}
					/>
				) : null}

				{comment ?
					<CommentsPopup
						onClose={(noteId, counter) => {
							setCommentNote(null);
							if (counter) {
								handleUpdateCounter(noteId, counter);
							}
						}}
						key={"comment-pop"}
						props={{
							type: ApiRequests.Comments.Notes,
							itemId: comment.id,
							prId: props.data.id,
							title: comment.title,
							prAccess: props.data.projAccess
						}}></CommentsPopup> : null}
				{noteToUpdateTags &&
					<TagsModal
						noteToUpdateTags={noteToUpdateTags}
						setNoteToUpdateTags={setNoteToUpdateTags}
						projectId={projectId}
					/>
				}
			</Spin>
		</>
	);
}
