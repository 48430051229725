import { useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, Spin } from 'antd';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from "../../../authConfig";
import ApiRequests from '../../../utils/api-requests';
import PanelButtons from '../components/PanelButtons';
import { PageRoutes } from '../../../utils/pageRoutes';
import ProjectNotes from './components/ProjectNotes';
import { errorHandler } from '../../../utils/globals';
import { Panel } from '../../../models/api/shared';
import ProjectHeader from '../components/ProjectHeader';
import useAddProjectToHistory from '../hooks/useAddProjectToHistory';
import StickyHelp from '../../../components/StyckyHelp';

const Notes = () => {
	const authRequest = {
		...loginRequest,
	};
	const navigate = useNavigate();
	const querryClient = useQueryClient();
	const params = useParams<{ id: string }>();
	const incorrectProject = !params.id || !/^[0-9]*$/.test(params.id);
	const [projectId] = useState(parseInt(params.id!));
	const [activePanel] = useState<Panel>('Notes');
	const helpContent: React.ReactNode = (
		<div key="sticky-help-btn-content">
			Create, maintain, and manage <b>Notes</b> collaboratively. Highlight text or use the entire answer in Discovery Module's Q&A or the page summarization in Best Sources to create notes. Click Add a Note to create notes manually. Notes are vital for each project and the document generation workflow.
		</div>
	);
	const querryKey = ['api-project-note', params.id];

	const projectDataQuery = useQuery({
		queryKey: querryKey,
		queryFn: async () => ApiRequests.Project.header(projectId),
		enabled: !incorrectProject,
		retry: false,
		refetchOnMount: true,
		refetchOnWindowFocus: false
	});

	useAddProjectToHistory(projectDataQuery.data);

	const handlePanelChange = useCallback((key: Panel) => {
		if (key === "Documents") {
			navigate(PageRoutes.goToProjectDocs(projectId));
		} else if (key !== "Notes") navigate(PageRoutes.goToProject(projectId, key));

	}, [navigate, projectId]);

	if (incorrectProject) {
		return (
			<Alert
				message="Error"
				description="This project does not exist!"
				type="error"
				showIcon
			/>
		);
	}

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest as any}
		>
			<Spin className='full-page-spinner' spinning={projectDataQuery.isFetching}>
				{projectDataQuery.error ? (
					<Alert
						message="Error"
						description={errorHandler(projectDataQuery.error)}
						type="error"
						showIcon
					/>
				) : null}
				{!incorrectProject && projectDataQuery.data && !projectDataQuery.isFetching ? (
					<>
						<ProjectHeader key={"proj-header-comp"}
							projectTitle={projectDataQuery.data.title}
							sharingInfo={projectDataQuery.data.sharing}
							id={projectId}
							onTitleUpdated={(newTitle) => {
								const newData = { ...projectDataQuery.data, title: newTitle };
								querryClient.setQueryData(querryKey, newData);
							}}
						/>

						<PanelButtons key={"proj-pan-button-comp"}
							activePanel={activePanel}
							onPanelChange={handlePanelChange} />


						<ProjectNotes key={"proj-notes-comp"} data={{
							id: projectDataQuery.data.sharing.parentId,
							prTitle: projectDataQuery.data.title,
							goToPrId: projectId,
							projAccess: projectDataQuery.data.sharing.accessMode
						}} />

						<StickyHelp goToHelpLink={PageRoutes.NotesHelp()} popContent={helpContent} title={"Notes"}></StickyHelp>
					</>
				) : null}
			</Spin>
		</MsalAuthenticationTemplate>
	);
};

export default Notes;
