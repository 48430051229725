import HelpLayout from "../Home";

export default function ShareProjectHelp() {
	return (
		<HelpLayout activeTab="ShareProjects">
			<div className='help-margin-left'>
				<h3>Share Project:</h3>

				<p>
					<b>Team Collaboration: </b>
					TAVA offers unparalleled team collaboration capabilities. You can share any project with an unlimited number of team members or other TAVA registered users.
				</p>

				<p>
					<b>Inviting Team Members: </b>
					Add team members to collaborate by creating an invitation email in the <b>Share this Project</b> module. Each project can have a unique team based on its research needs and team members' qualifications. Invited team members must confirm the invitation email to join the team.
				</p>

				<h4>Types of Collaborators: </h4>
				<ul>
					<li>
						<b>Contributors:</b> Can ask questions, create notes, documents, and comment on project materials.
					</li>
					<li>
						<b>Readers:</b> Can only read and observe the materials available in the project.
					</li><br />
				</ul>

				<p>
					<b>Project Access: </b>
					Projects can be restricted to the author or assigned team members. To reach a wider audience, change the <b>General Access</b> rights from "Restricted" to "Anyone with Link." This option makes the project link available to any TAVA user with access to the link.
				</p><br />
			</div>
		</HelpLayout>
	);
}