import { useEffect, useRef } from "react";
import { Mention, MentionsInput } from "react-mentions";

interface Props {
    text: string;
    onChangeText: (text: string) => void;
    isReadonly?: boolean;
    fireSubmitAfterShiftPlusEnter: () => void; // Add this prop
}

interface Users {
    id: string;
    display: string;
}

const users: Users[] = [];

const filterUser = (query: string) => {
    const filteredUsers = users.filter((user) =>
        user.display.toLowerCase().includes(query)
    );
    return filteredUsers;
};

const displayTransform = (id: string, display: string) => {
    return `${display}`;
};

const CommentMention = ({ text, onChangeText, isReadonly, fireSubmitAfterShiftPlusEnter }: Props) => {
    const inputRef = useRef<string>(text);

    useEffect(() => {
        inputRef.current = text;
    }, [text]);

    const onChange = (e: { target: { value: string } }) => {
        inputRef.current = e.target.value;
        onChangeText(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.shiftKey && e.key === 'Enter') {
            e.preventDefault();
            onChangeText(inputRef.current); // Update the value
            fireSubmitAfterShiftPlusEnter(); // Submit the form
        }
    };

    const mentionStyle = {

        "&multiLine": {
            input: {                
                border: "none",
            },
        },
        suggestions: {
            list: {
                backgroundColor: "white",
                border: "1px solid rgba(0,0,0,0.15)",
                fontSize: 14,
            },
            item: {
                padding: "5px 15px",
                borderBottom: "1px solid rgba(0,0,0,0.15)",
                "&focused": {
                    backgroundColor: "var(--comment-el-mention-bgr)",
                },
            },
        },
    };

    return (
        <div className="text-area-comment">
            <MentionsInput
                onKeyDown={handleKeyDown}
                readOnly={isReadonly}
                value={text}
                onChange={onChange}
                placeholder={"Write comment here..."}
                a11ySuggestionsListLabel={"Suggested mentions"}
                style={mentionStyle}
            >
                <Mention
                    displayTransform={displayTransform}
                    trigger="@"
                    data={filterUser}
                    className={'comment-el-mention'}
                />
            </MentionsInput>
        </div>
    );
};


export default CommentMention;
