import { Form, FormProps, Input, Spin, message, Affix, Divider, Tooltip, Button, Col, Row, Typography, Modal } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { parse as jsonParse } from 'best-effort-json-parser';
import parse from 'html-react-parser';
import RelatedImages from './related-images';
import DigDeeper from './dig-deeper';
import { AskTavaComponent } from '../../models/props';
import { AskQuestionModel } from '../../../../models/api/projects/projects';
import FormatData from '../../utils/format-data';
import { OpenAIRequestBuilder } from '../../../../utils/openAi';
import ApiRequests from '../../../../utils/api-requests';
import Sources from '../discovery/sources';
import { errorHandler, newLineToBr } from '../../../../utils/globals';
import DeleteQuestion from './delete-question';
import { BoostInsightModel } from '../../../../models/api/boost_insights';
import { AiModel } from '../../../../models/api/ask-tava';
import { AskQuestionCreateModel, AskQuestionGalleryImageModel, SearchResult } from '../../../../models/api/shared';
import { GPTChatMessage, OpenAiRequestModel } from '../../../../models/api/open-ai';
import { ProjectNoteCreateModel } from '../../../../models/api/notes';
import ProjectSessionStorage from '../../utils/session-storage';
import { PageRoutes } from '../../../../utils/pageRoutes';
import useContextMenu from '../../../../hooks/useContextMenu';
import ContextMenu from '../../../../components/ContextMenu';

const { Text } = Typography;
interface PropsAskTava {
	data: AskTavaComponent
	returnBoosts: (boosts: BoostInsightModel[]) => void;
	newQuestion: string | null
}

interface QuestionsModel extends AiModel {
	question: string,
	id?: number,
	images?: AskQuestionGalleryImageModel[]

}


type FormFieldType = {
	question: string,

};


function formatViewModel(model: AskQuestionModel[]): QuestionsModel[] {
	let newModel: QuestionsModel[] = [];

	model.map(q => {
		let answer: QuestionsModel = {
			aiResponse: q.description,
			five_sources: FormatData.formatToAskTavaSources(q.sources),
			five_tags: q.tags,
			five_topics: q.digDeeperWrapper.topics.map(t => t.topic),
			question: q.question,
			images: q.gallery?.images ? q.gallery.images : [],
			id: q.id
		}
		newModel.push(answer);

	})
	return newModel;
}

export default function AskTava(props: PropsAskTava) {
	const [rId] = useState(props.data.id);
	const [askTava, setAskTava] = useState<QuestionsModel[] | []>(formatViewModel(props.data.askQuestions));
	const [isLoading, setIsLoading] = useState(false)
	const [createQuestion, setCreateQuestion] = useState(false)
	const [formQuestion, setFormQuestion] = useState("")
	const [form] = Form.useForm<FormFieldType>();
	const [scrollTo, setScrollTo] = useState(false)
	const scrollRef = useRef<HTMLDivElement[]>([])
	const { contextMenu, checkForSelect, clearSelect, setContextMenu } = useContextMenu();
	const { confirm } = Modal;

	const { mutate: startStream } = useMutation({
		mutationFn: async (messageContent: string) => {
			let model: OpenAiRequestModel = OpenAIRequestBuilder.askTava(),
				msIndex = 0;
			askTava.forEach((item) => {
				msIndex = msIndex + 1;
				let msgUser: GPTChatMessage = {
					id: msIndex,
					content: item.question,
					role: 'User'
				}
				let answer: AiModel = {
					aiResponse: item.aiResponse,
					five_tags: item.five_tags ? item.five_tags.map(t => t) : [],
					five_topics: item.five_topics ? item.five_topics.map(t => t) : [],
					five_sources: item.five_sources ? item.five_sources.map(s => {
						return {
							title: s.title,
							url: s.url,
							domain: s.domain
						}
					}) : []
				};

				msIndex = msIndex + 1;
				let msgAssistant: GPTChatMessage = {
					id: msIndex,
					content: JSON.stringify(answer),
					role: 'Assistant'
				}
				model.messages.push(msgUser, msgAssistant);
			});
			let newMsg: GPTChatMessage = {
				id: model.messages.length,
				content: messageContent,
				role: 'User'
			}
			model.messages.push(newMsg);

			return ApiRequests.OpenAi.projectLicenceStream(rId, model);
		},
		onSuccess: (reader) => {
			setIsLoading(true)
			readStream(reader)
		},
		onError: (error) => {
			setIsLoading(false)
			message.error(errorHandler(error), 15);
		},
	})

	async function readStream(reader: ReadableStreamDefaultReader) {
		let buffer = "";
		let tokenCounter = 0;
		let hasTokensCounter = 0;
		let allModel = '';
		let unicodeCounter = 0;
		async function read() {
			const { done, value } = await reader.read();
			if (done) {
				console.log("Done")
				setScrollTo(false);
				setCreateQuestion(true);
				return
			}
			const text = new TextDecoder().decode(value);

			const objects = text;//.split(`{"token":"`); // ("\",\"");; ///.split(/\\r|\\n|\\t/); //.split("\",\"");

			for (const symbol of objects) {

				let formated = symbol;

				while (formated.includes('\\\\')) {
					formated = formated.replace(/\\\\/g, '\\');
				}
				formated = formated.replace(/\\\"/g, '\"');

				buffer += formated;
				try {
					const responseModel = jsonParse(buffer);

					if (responseModel && Array.isArray(responseModel)) {

						for (var i = tokenCounter; i < responseModel.length; i++) {

							if (responseModel[hasTokensCounter].hasOwnProperty('token') && responseModel[hasTokensCounter].token !== undefined) {

								allModel += responseModel[hasTokensCounter].token;

								if (unicodeCounter && unicodeCounter >= allModel.length) {
									hasTokensCounter++
									continue;
								} else if (responseModel[hasTokensCounter].token === '\\u' || responseModel[hasTokensCounter].token === ' \\u') {
									unicodeCounter = allModel.length + 5;
									hasTokensCounter++
									continue;
								}

								unicodeCounter = 0;

								try {
									const NewModelReady = jsonParse(allModel);
									if (NewModelReady.hasOwnProperty('error')) {
										message.error(NewModelReady.error, 60);
										setIsLoading(false);
										return;
									}

									setScrollTo(true)
									setAskTava([...askTava, {
										...NewModelReady,
										question: formQuestion
									}]);
									hasTokensCounter++
									continue
								} catch (e) {
									console.log(allModel);
									hasTokensCounter++
									++tokenCounter
									continue
								}

							}
							++tokenCounter
						}

					}

				} catch (e) {
					setScrollTo(false);
					setIsLoading(false);
					console.log(e);
					message.error(`Oops! TAVA minions could not deliver the best answer. Let's try again! Please, refresh the page and ask the question again. 
Sorry for the inconvenience.`, 60);
					return
				}

			}

			read()
		}
		read()
	}

	function formatBingImagesToGallery(bingImages: SearchResult[]): AskQuestionGalleryImageModel[] {
		return bingImages.map((i, index) => {
			return {
				position: index,
				pageUrl: i.url,
				imageUrl: i.imageUrl
			}
		});
	}

	const handleFireNewQuestion = (digDeeper: string) => {
		form.setFieldsValue({
			question: digDeeper,
		});

		form.submit();
	};


	const onFinish: FormProps<FormFieldType>['onFinish'] = (values) => {
		setIsLoading(true);
		setScrollTo(false);
		setFormQuestion(values.question);
		if (!props.data.sharing.canAskTava) {
			setIsLoading(false);
			return message.error("Access Denied!", 15);
		}
		if (props.data.sharing.isOwner) {
			startStream(values.question);
		} else {
			confirm({
				icon: "",
				okText: "Yes",
				cancelText: "No",
				content: <div>{`The system will redirect you to your personal discovery page for project "${props.data.name}"`}</div>,
				onOk() {
					if (props.data.sharing.accessMode === 'Owner') {
						ProjectSessionStorage.create();
						ProjectSessionStorage.setFollowUpQuestion(values.question)
						window.location.href = PageRoutes.goToProject(props.data.sharing.parentId);
					} else {
						ApiRequests.FollowUps.create(props.data.sharing.parentId).then((responseId) => {
							ProjectSessionStorage.create();
							ProjectSessionStorage.setFollowUpQuestion(values.question)
							window.location.href = PageRoutes.goToProject(responseId);

						}).catch((e) => {
							setIsLoading(false);
							message.error(errorHandler(e), 10);
						})
					}

				},
				onCancel() {
					setIsLoading(false);
				},
			});

		}

	}


	useEffect(() => {
		if (scrollTo && scrollRef.current)
			scrollRef.current[askTava.length - 1].scrollIntoView(false)

		if (createQuestion) {
			setCreateQuestion(false);

			let newAnswer = askTava[askTava.length - 1];
			let createModel: AskQuestionCreateModel = {
				description: newAnswer.aiResponse,
				sources: newAnswer.five_sources ? newAnswer.five_sources : [],
				tags: newAnswer.five_tags ? FormatData.formatTags(newAnswer.five_tags) : [],
				digDeeperWrapper: newAnswer.five_topics ? FormatData.formatToTipicWrapper(newAnswer.five_topics) : { topics: [] },
				gallery: { images: [] },
				question: formQuestion
			};


			(!createModel.digDeeperWrapper.topics.length ? Promise.resolve([])
				: ApiRequests.Searches.results({
					engine: "BingImages",
					words: `${newAnswer.five_topics.slice(0, 1).join(', ')}, ${props.data.name} ${createModel.question}`
				}))
				.then(function (bingResponse) {
					return Promise.resolve(formatBingImagesToGallery(bingResponse.slice(0, 4)));
				}).catch(() => {
					return Promise.resolve([]);
				}).then(bingResponse => {
					createModel.gallery = { images: bingResponse }
					return ApiRequests.AskQuestions.create(rId, createModel)
				}).then((id) => {
					const updatedTasks = askTava.map((item) => {
						return item.id ? item : { ...item, images: createModel.gallery?.images ?? [], id }
					});


					if (createModel.digDeeperWrapper.topics.length) { //ask for boosts
						let openAiBoosts = OpenAIRequestBuilder.relatedTopicQuestionsModel(
							updatedTasks.map(model => model.five_topics).flat().join(', ') //get all topics
						);

						let newBoosts: BoostInsightModel[] = [];

						return ApiRequests.OpenAi.boosts(rId, openAiBoosts).then((response) => { //openai get boosts
							let boostsAiString = OpenAIRequestBuilder.resultToString(response);
							if (boostsAiString) {
								console.log(boostsAiString);
								let boostModel = OpenAIRequestBuilder.formatBoosts(boostsAiString);
								newBoosts = boostModel.map((item, index) => ({
									position: index,
									question: item
								}));
								return ApiRequests.Project.BoostInsights.update(rId, { boostInsights: newBoosts });
							} else return Promise.resolve(1)
						}).then(() => { //done and show boosts
							setAskTava(updatedTasks);
							form.setFieldsValue({ question: "" });
							setIsLoading(false);
							props.returnBoosts(newBoosts)
						}).catch((e) => { //done
							setAskTava(updatedTasks);
							form.setFieldsValue({ question: "" });
							setIsLoading(false);

						})
					} else { //done
						setAskTava(updatedTasks);
						form.setFieldsValue({ question: "" });
						setIsLoading(false);
					}


				}).catch((e) => { //errror
					setAskTava(askTava.slice(0, -1));
					setIsLoading(false);
					message.error(errorHandler(e), 10);
				})

		}


	}, [askTava, askTava.length, createQuestion, form, formQuestion, props, rId, scrollTo])

	useEffect(() => {

		if (props.data.startStream) {
			props.data.startStream = false;
			form.resetFields();
			form.setFieldsValue({
				question: props.newQuestion ? props.newQuestion :
					(ProjectSessionStorage.getFollowUpQuestion() ?? props.data.name),
			});

			ProjectSessionStorage.removeFollowUpQuestion()

			form.submit();
		}
	}, [form, props]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (contextMenu.visible) {
				let n = event.target as Element;
				if (!n.closest(".ask-tava-response-wrap")) {
					window.getSelection()?.empty();
					setContextMenu({ ...contextMenu, visible: false });
				}
			}
		};

		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [contextMenu]);

	const createNoteMutation = useMutation({
		mutationFn: (model: ProjectNoteCreateModel) => {
			return ApiRequests.Project.Notes.create(props.data.sharing.parentId, model)
		},
		onError: (error) => {
			message.error(error.message)
		}
	});
	const handleStartQuestion = () => {
		form.resetFields();
		form.setFieldsValue({
			question: contextMenu.text,
		});

		form.submit();

	}

	function emptyFunct(event: any) {
		event.preventDefault();
	};

	function onDeleteQuestion(questionId: number) {
		setIsLoading(true);
		setAskTava(askTava.filter(item => item.id !== questionId));
		setIsLoading(false);
	}

	const replaceHtmlTagsWithNewline = (htmlString: string): string => {
		return htmlString.replace(/<\/?[^>]+(>|$)/g, '\n');
	};

	function createNote(answer: QuestionsModel) {
		const { aiResponse, five_sources, images } = answer;
		const txt = replaceHtmlTagsWithNewline(aiResponse);
		const url = five_sources.length ? five_sources[0].url : "";

		const model: ProjectNoteCreateModel = {
			text: txt,
			images: [],
			topicsWrapper: {
				topics: []
			},
			isOpenAi: false,
			gallery: {
				images: images?.map(item => ({
					position: item.position,
					imageUrl: item.imageUrl,
					pageUrl: item.imageUrl,
				})) || []
			},
			sourceUrl: url,
			tags: []
		};
		createNoteMutation.mutate(model, {
			onSuccess: () => {
				message.success('Note created successfully');
				setContextMenu({
					...contextMenu,
					visible: false
				});
			},
			onError: (error) => {
				message.error(errorHandler(error), 15);
			},
		});
	}

	return (
		<>
			<div>
				{askTava.map((answer, i) => (
					<div key={i} ref={(el) => (scrollRef.current[i] = el!)}>

						{answer.question ? (
							<Row gutter={16} wrap={false} align="middle">

								<Col className="gutter-row" flex="auto">
									<Text strong className="txt-large" >{answer.question}</Text>
								</Col>
								<Col className="gutter-row" flex="none">
									<div className='hbox endbox topbox'>
										<Tooltip title="Create Note">
											<Button
												type="text"
												className="action-btn"
												onClick={() => createNote(answer)}
											>
												<span className="ficon ficon-note"></span>
											</Button>
										</Tooltip>
										{(i > 0 && answer.id) ? (
											<DeleteQuestion
												questionId={answer.id}
												projectId={rId}
												onDeleteToggle={(questionId) => { onDeleteQuestion(questionId) }}
											></DeleteQuestion>) : ""}
									</div>
								</Col>
							</Row>) : ""}
						<div className="ask-tava-response-wrap pb-40">
							{answer.aiResponse ? <div className="ask-tava-response-wrap">
								<div
									onMouseUp={checkForSelect}
									onMouseDown={clearSelect}
									className="p-10 ask-tava-response-wrap"
									onChange={emptyFunct}
								>
									{parse(newLineToBr(answer.aiResponse))}
								</div>
							</div>
								: ""}
						</div>
						<div className="pb-40">
							{answer.five_sources?.length > 0 ? <Sources data={answer.five_sources}></Sources> : ""}
						</div>

						<div className="pb-40">
							{answer.images?.length ?
								<>
									<div className="mb-15 text-semibold">Related images: </div>
									<RelatedImages data={answer.images}></RelatedImages>
								</> : null}
						</div>

						<div className="pb-40">
							{answer.five_topics?.length ? <DigDeeper data={answer.five_topics} makeNewQuestion={handleFireNewQuestion}></DigDeeper> : ""}
						</div>

						<Divider />
					</div>

				))}
			</div>

			<Spin className='full-page-spinner spinner-transparent' spinning={isLoading}>
				<Affix
					className='fix-to-bottom-wrap'
					style={{ bottom: 'var(--sticky-to-bottom-bar)' }}
					offsetBottom={0}
				>
					<Form className='p-10 h-offset-10' form={form}
						name="basic"
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						initialValues={{ remember: false }}
						onFinish={onFinish}
						autoComplete="off"
					>
						<Form.Item<FormFieldType>
							name="question"
							rules={[{ required: true, message: 'Please fill Ask TAVA question!' },
							{ min: 4, message: 'Question must be minimum 4 characters.' }]}
						>

							<Input size="large" maxLength={256} placeholder="Ask TAVA another question..." suffix={<SearchOutlined onClick={() => { form.submit(); }} />} />

						</Form.Item>

					</Form>
				</Affix>
			</Spin>
			{contextMenu.visible ?
				<ContextMenu
					setContextMenu={setContextMenu}
					contextMenu={contextMenu}
					handleStartQuestion={handleStartQuestion}
					projectId={rId}
				/>

				:
				null
			}


		</>
	);
}