import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import Title from "antd/es/typography/Title";
import { Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import Paragraph from "antd/es/typography/Paragraph";
import AccountTabs from "../components/Tabs";
import { MobileContext } from "../../../components/PageLayout";
import StickyHelp from "../../../components/StyckyHelp";
import { PageRoutes } from "../../../utils/pageRoutes";
import { getHelpContent } from "../utils/funcUtils";

interface PersonalInfoProps {
	name: string;
	email: string;
	isMobile: boolean;
	onLogout: () => void;
}

const ProfilePicture = () => (
	<div className="profile-picture">
		<div
			className="ficon ficon-user"
			style={{ fontSize: "3rem" }}
		/>
	</div>
);

const PersonalInfo = ({ name, email, isMobile, onLogout }: PersonalInfoProps) => (
	<div className={`personal-info ${isMobile ? "mobile-layout" : "desktop-layout"}`}>
		{!isMobile && (
			<div className="personal-info-header">
					<Title level={5} className="mt-0">
						Personal Information
					</Title>
				<ProfilePicture />
			</div>
		)}
		<div className="personal-info-details">
			{isMobile && (
				<Title level={5} className="mt-0">
					Personal Information
				</Title>
			)}
			<h1 className="txt-small mb-02">Name</h1>
			<Paragraph>{name}</Paragraph>
			<h1 className="txt-small mb-02">Email address</h1>
			<Paragraph>{email}</Paragraph>
			<h1 className="txt-small mb-02">Change Password</h1>
			<Paragraph className="txt-small">
				If you wish to change your password <br />
				please
				<Tooltip title={"Log out to change password"}>
					<strong>
						<span
							className="link ml-05 mr-05"
							onClick={onLogout}
						>
							Log Out
						</span>
					</strong>
				</Tooltip>
				and follow the steps to the "Forgot/Change password?" link.
			</Paragraph>
		</div>
	</div>
);

export default function AccountHome() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const { instance, inProgress } = useMsal();
	const activeAccount = instance.getActiveAccount();
	const isMobile = useContext(MobileContext);

	useEffect(() => {
		if (inProgress === InteractionStatus.None && activeAccount) {
			setName(activeAccount.name as string);
			setEmail(activeAccount.username as string);
		}
	}, [activeAccount, inProgress]);

	if (!activeAccount && inProgress === InteractionStatus.None) {
		instance.loginRedirect(loginRequest).catch(console.error);
	}

	const handleLogoutRedirect = () => {
		instance.logoutRedirect({
			account: instance.getActiveAccount(),
		});
	};

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={loginRequest}
		>
			<AccountTabs activePanel="account" />
			<PersonalInfo
				name={name}
				email={email}
				isMobile={isMobile}
				onLogout={handleLogoutRedirect}
			/>
			<StickyHelp goToHelpLink={PageRoutes.AccountSettingsHelp()} popContent={getHelpContent()} title={"Account Settings and Licensing"}></StickyHelp>
		</MsalAuthenticationTemplate>
	);
}
