import { Button, Flex } from "antd"
import { BoostInsightModel } from "../../../../models/api/boost_insights";

interface BoostsModel {
	data: BoostInsightModel[],
	makeNewQuestion: (question: string) => void;
}

export default function Boosts(boosts: BoostsModel) {

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {

		const newQuestion = event.currentTarget.textContent;
		if (newQuestion)
			boosts.makeNewQuestion(newQuestion);
	};


	return (
		<>
			<Flex wrap gap="small">
				{
					boosts.data.map((boost, q) => (
						<span key={q}>
							<Button className="tag-btn ant-btn btn-multiline hbox vcbox" shape="round" onClick={handleClick} block>
								<span className='ficon ficon-dig fspace-r txt-large'></span>
								<span className="text-wrap">{boost.question}</span>
							</Button>
						</span>
					))
				}
			</Flex>
		</>
	)
}