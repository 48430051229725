export type DocumentTypes = 'SelectedNotes' | 'ButtonsUp' | 'Summary' |
	'Presentation' | 'KeyTakeaways' | 'WhatAmIMissing' |
	'BlogPost' | 'PressRelease' | 'WebsitePage';
export enum SortTypes {
	Desc = "Desc",
	Asc = "Asc",
}

export type Panel = 'Discovery' | 'BestSources' | 'BoostInsight' | 'Notes' | 'Documents';

export type ContactTypes = 'Employee' | 'Affiliate';
export type LayoutTypes = 'OneColumn' | 'TwoColumns';
export type JobTypes = 'Search' | 'Bookmark' | 'RssFeeds';
export type RepeatTypes = 'Weekly' | 'BiWeekly' | 'Monthly' | 'Quaterly' | 'SemiAnnually' | 'Annually' | 'Once';
export type ExportStateTypes = 'Pending' | 'Accepted' | 'Declined';
export type SocialNetworks = 'Myspace' | 'Facebook' | 'Twitter' | 'LinkedIn' | 'Instagram';
export type SearchEngines = 'GoogleImages' | 'Google' | 'Bing' | 'Yahoo' | 'Facebook' | 'Twitter' | 'LinkedIn' |
	'TavaDiscovery' | 'Instagram' | 'Bloomberg' | 'ThomsonReuters' | 'YahooFinance' | 'Edgar' | 'BingImages' |
	'Yandex' | 'Wikipedia' | 'Pixabay' | 'Rss';
export type InvitationTypes = 'Employee' | 'Affiliate';
export type MemberRoleTypes = 'Owner' | 'Employee' | 'Affiliate';
export type ServiceStatuses = 'NoService' | 'NotActived' | 'Active' | 'Expired' | 'Suspended';
export type ServiceTypes = 'Free' | 'Professional';
export type PaymentMethods = 'PayPal' | 'BankTransfer';
export type PaymentPeriodTypes = 'Month' | 'Year';
export type OrderStatuses = 'Created' | 'Active' | 'Closed';
export type SearchEngineSections = 'Search' | 'Social' | 'Financial' | 'Images';
export type FolderNodeTypes = 'Root' | 'Reqular';
export type SectionTypes = 'Attachments' | 'Bookmarks' | 'Snippets' | 'Text' | 'ToC' | 'Pinboard' | 'Notes';
export type AdvancedSearchWordsModes = 'AllTheseWords' | 'ThisExactWordingOrPhrase' | 'OneOrMoreOfTheseWords';
export type ResearchRuleActions = 'MarkImportant' | 'StopCrawling' | 'Delete';
export type UIViewTypes = "List" | "Card";
export type ResearchTaskUIViewTypes = "List" | "Card" | "Calendar";
export type PinnedTypes = "Bookmark" | "Snippet" | "File" | "Note";
export type FilterIntervalTypes = 'Today' | 'Week' | 'Month' | 'Quaterly' | 'SemiAnnually' | 'Annually';
export type CommentTargets = 'Section' | 'Bookmark' | 'Attachment' | 'Snippet' | 'Note' | 'Task' | 'AskThread';
export type AdmistratorRoles = 'ManageUsers' | 'ManageLicenses' | 'ManageWebSites' | 'ManageNews' | 'ReportMonitoring'
export type TagTargets = 'Bookmark' | 'Note' | 'Snippet' | 'Attachment';
export type TaskStatuses = 'New' | 'InProgress' | 'Done';
export type GPTChatRoles = 'System' | 'User' | 'Assistant';
export type HelpTabs = "GettingStarted" | "Discovery" | "Notes" | "Documents" | "AccountSettings" | 'Overview' | 'ShareProjects';
export type ProjectAccessModes = 'Owner' | 'Contributor' | 'Reader' | 'Restricted';


export interface ProjectDigDeepersWrapperModel {
	topics: ProjectDigDeeperModel[],
}

export interface ProjectDigDeeperModel {
	position: number,
	topic: string
}

export interface ProjectTagModel {
	tags: string[],
}


export interface ViewResultsViewModel {
	id: number;
	pageTitle: string | null;
	url: string | null;
	content: string | null;
	date: string;
	imageId: number;
	imageDate: string;
	jobType: JobTypes;
	jobTitle: string | null;
	jobTypeName: string;
	projectId: number;
	projectName: string | null;
}

export interface AskQuestionGalleryImageModel {
	position: number,
	pageUrl: string,
	imageUrl: string,
}

export interface AskQuestionGalleryModel {
	images?: AskQuestionGalleryImageModel[],
}

export interface AskQuestionSourceModel {
	title: string,
	url: string,
}


export interface SearchRequestModel {
	engine: SearchEngines,
	words: string,
}

export interface AskQuestionCreateModel extends ProjectTagModel {
	//isProjectTrail: boolean,
	question: string,
	description: string,
	gallery: AskQuestionGalleryModel | undefined,
	digDeeperWrapper: ProjectDigDeepersWrapperModel,
	sources: AskQuestionSourceModel[],

}

export interface SearchResult {
	title: string,
	url: string,
	content?: string,
	searchEngine: SearchEngines,
	imageUrl: string,
	date: string,
}

export interface MemberModel {
	id: string;
	name: string;
	email: string;
}

export interface ModifierModel extends MemberModel {
	modifiedAt: string;
}

export interface OwnerModel extends MemberModel {
	createdAt: string;
}

export interface CommentReadBaseModel {
	commentId: number;
	owner: OwnerModel;
	text: string;
	modifier: ModifierModel;
}

export interface CommentReadModel extends CommentReadBaseModel {

}


export interface ReplyReadModel extends CommentReadBaseModel {
	replyId: number;
}

export interface SelectItemBase {
	disabled: boolean;
	text: string;
}

export interface ImageUploadModel {
	fileName: string,
	dataUrl: string,
}

export interface ImageLinkDataModel {
	id: number,
	date: string,
}



export interface LicenseInfoViewModel {
	serviceType?: ServiceTypes;
	status: ServiceStatuses;
	statusDisplay: string;
	storageBudget: number;
	validFrom?: string;
	validTo?: string;
	storageActual: number;
	quetionsActual: number;
	quetionsBudget: number;
}


export interface AutoCompleteFilterModel {
	term?: string;
}

export interface AutoCompleteBaseModel {
	label?: string;
}

export interface AutoCompleteIntModel extends AutoCompleteBaseModel {
	value: number;
}

export interface PaymentCreateServiceModel {
	serviceId: string;
	rowVersion: string;
	validTo: string;
	serviceType: ServiceTypes;
}
export interface TableSorter {
	field: string;
	columnKey: string,
	order: 'ascend' | 'descend';
}
export interface SortInfo<T> {
	sortColumn: T;
	sortType: SortTypes;
}

export interface ContextMenuType {
	visible: boolean;
	x: number | null;
	y: number | null;
	text: string
}