import { ColumnsType } from "antd/es/table";
import { Tooltip } from 'antd';
import { SubscriptionsResultModel, SubscriptionsSortColumns } from "../../../../../../models/api/services";
import { toDateTime } from "../../../../../../utils/globals";
import { SortInfo, SortTypes } from "../../../../../../models/api/shared";
import { SortOrder } from "antd/es/table/interface";

interface Props {
	handlePaymentClick: (memberId: string) => void;
	handleSubscriptionClick: (subscriptionId: string) => void;
	sortInfo: SortInfo<SubscriptionsSortColumns>;
}

const ExpiredSubscriptionsTableColumns = ({ handleSubscriptionClick, handlePaymentClick, sortInfo }: Props): ColumnsType<SubscriptionsResultModel> => {
	return [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Id" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "Administrative Contact",
			dataIndex: ['administrativeContact', "email"],
			key: "AdministrativeContact",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "AdministrativeContact" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (email: string, record: SubscriptionsResultModel) => (
				<Tooltip title="Click here to view subscription info">
					<span
						className="force-link"
						onClick={() => handleSubscriptionClick(record.administrativeContact.id)}
					>
						{email}
					</span>
				</Tooltip>
			),
		},
		{
			title: "Customer Since",
			dataIndex: "customerSince",
			key: "CustomerSince",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "CustomerSince" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: string) => <span>{toDateTime(text)}</span>,
		},
		{
			title: "Service Type",
			dataIndex: "serviceType",
			key: "ServiceType",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "ServiceType" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
		},
		{
			title: "Expiration Date",
			dataIndex: "expirationDate",
			key: "ExpirationDate",
			sorter: true,
			sortOrder: sortInfo.sortColumn === "ExpirationDate" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' as SortOrder : 'descend' as SortOrder) : undefined,
			render: (text: string) => <span>{toDateTime(text)}</span>,
		},
		{
			title: "",
			dataIndex: "canEnterPayments",
			key: "canEnterPayments",
			render: (canEnterPayments: boolean, record: SubscriptionsResultModel) =>
				canEnterPayments ? (
					<Tooltip title="Click to confirm payment of order">
						<span
							className="force-link"
							onClick={() => handlePaymentClick(record.administrativeContact.id)}
						>
							Payment
						</span>
					</Tooltip>
				) : null,
		},
	];
};

export default ExpiredSubscriptionsTableColumns;
