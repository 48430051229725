import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal, Row, Col, Table, Form, DatePicker, InputNumber, Input, Button, message, Typography } from "antd";
import dayjs from "dayjs";
import Title from "antd/es/typography/Title";
import ApiRequests from "../../../../../../utils/api-requests";
import { errorHandler, toCurrency, toDate, toDateTime, toStorage } from "../../../../../../utils/globals";
import { PaymentCreateModel } from "../../../../../../models/api/payments";
import PaymentDetails from "../waiting-for-approval/components/PaymentDetails";
import { AxiosError } from "axios";
import { OrderViewModel } from "../../../../../../models/api/orders";
import { PaymentCreateServiceModel } from "../../../../../../models/api/shared";

const { Paragraph } = Typography;

interface ApprovalModalProps {
	orderId: number | null;
	memberId: string| null;
	//needsToFindOrder: boolean;
	setSelectedOrderId: (orderId: number | null) => void;
	onSuccsesfulPayment: () => void;
}

interface FormValues {
	paymentDate: string;
	amount: number;
	transaction?: string;
	check?: string;
	date: string;
}

const ApprovalModal = ({ orderId, memberId, setSelectedOrderId, onSuccsesfulPayment }: ApprovalModalProps) => { //needsToFindOrder
	const [form] = Form.useForm();
	const queryClient = useQueryClient();

	const findOrder = useQuery<number>({
		queryKey: ["findOrder", orderId],
		queryFn: () => (memberId ? ApiRequests.Members.findOrder(memberId) : Promise.reject("No orderID")),
		enabled: memberId ? true: false,
	});

	const actualOrderId = memberId ? findOrder.data : orderId;//needsToFindOrder ? findOrder.data : orderId;

	const orderDetailsQuery = useQuery<OrderViewModel>({
		queryKey: ["orderDetails", actualOrderId],
		queryFn: () => {
			if (actualOrderId) {
				return ApiRequests.Orders.read(actualOrderId);
			}
			return Promise.reject("No orderID");
		},
		enabled: !!actualOrderId,
	});

	const serviceInfoQuery = useQuery<PaymentCreateServiceModel | null>({
		queryKey: ["serviceInfo", actualOrderId],
		queryFn: () => {
			if (actualOrderId) {
				return ApiRequests.Orders.serviceInfo(actualOrderId);
			}
			return Promise.reject("No orderID");
		},
		enabled: !!actualOrderId,
	});

	const createPaymentMutation = useMutation<number, AxiosError, PaymentCreateModel>({
		mutationFn: (paymentData: PaymentCreateModel) => ApiRequests.Payments.create(paymentData),
		onSuccess: () => {
			form.resetFields();
			queryClient.invalidateQueries({ queryKey: ['orderDetails', actualOrderId] });
			onSuccsesfulPayment();
			setSelectedOrderId(null);
		},
		onError: (error) => {
			message.error(errorHandler(error), 40);
			console.error(error);
		},
	});

	const handleFinish = async (values: FormValues) => {
		if (!actualOrderId) {
			message.error("Order ID is not valid.");
			return;
		}

		const model: PaymentCreateModel = {
			orderId: actualOrderId,
			rowVersion: orderDetailsQuery.data?.rowVersion || "",
			date: dayjs(values.paymentDate).toISOString(),
			amount: values.amount,
			transaction: values.transaction,
			check: values.check,
			validTo: dayjs(values.date).toISOString(),
		};

		if (serviceInfoQuery.data?.serviceId) {
			model.service = {
				serviceId: serviceInfoQuery.data.serviceId,
				rowVersion: serviceInfoQuery.data.rowVersion,
				validTo: serviceInfoQuery.data.validTo,
				serviceType: serviceInfoQuery.data.serviceType,
			};
		}

		createPaymentMutation.mutate(model);
	};

	const handleModalClose = () => {
		form.resetFields();
		setSelectedOrderId(null);
	};

	const modalColumns = [
		{
			title: "Type",
			dataIndex: "serviceCatalogName",
			key: "serviceCatalogName",
			width: 1000,
			render: (name: string) => <span>{name}</span>,
		},
		{
			title: "Storage",
			dataIndex: "storage",
			key: "storage",
			width: 200,
			render: (storage: number) => toStorage(storage),
		},
		{
			title: "Questions",
			dataIndex: "questions",
			key: "questions",
			width: 200,
			render: (questions: number) => <>{questions}</>,
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
			width: 200,
			render: (price: number) => toCurrency(price),
		},
	];

	return (
		<Modal
			open={!!actualOrderId}
			onCancel={handleModalClose}
			footer={[
				<Button key="submit" form="set-payment-popup" htmlType="submit" type="primary">
					Submit
				</Button>,
				<Button key="cancel" onClick={handleModalClose}>
					Cancel
				</Button>,
			]}
			title="Approve Client License"
			width={800}
		>
			{orderDetailsQuery.data && (
				<div>
					<div>
						<Row>
							<Col span={12}>
								<b>Order #:</b> {orderDetailsQuery.data.orderId}
							</Col>
							<Col span={12}>
								<b>Status:</b> {orderDetailsQuery.data.statusName}
							</Col>
							
						</Row>
						<Row>
							<Col span={12}>
								<b>Order Date:</b> {toDateTime(orderDetailsQuery.data.orderDate)}
							</Col>
							<Col span={12}>
								<b>Payment Method:</b> {orderDetailsQuery.data.payWithName}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<b>Contact:</b> {orderDetailsQuery.data.member.name}
							</Col>
							<Col span={12}>
								<b>Email:</b> {orderDetailsQuery.data.member.email}
							</Col>
						</Row>

						<Table className="mt-10"
							columns={modalColumns}
							dataSource={orderDetailsQuery.data.items}
							rowKey="position"
							pagination={false}
						/>
					</div>
					<div>
						<PaymentDetails
							initialOrderDate={orderDetailsQuery.data.orderDate}
							instantPaymentAsText={orderDetailsQuery.data.conditions?.instantPayment}
							priceAsText={orderDetailsQuery.data.price}
							recurrency={orderDetailsQuery.data.recurrency}
							startDate={orderDetailsQuery.data.conditions?.startDate}
						/>
					</div>
					<div>
						<Title level={4}>Payment: </Title>
						<Form layout="vertical" form={form} id="set-payment-popup" onFinish={handleFinish}>
							<Row>
								<Col span={10}>
									<Form.Item
										name="paymentDate"
										label="Payment date"
										rules={[{ required: true }]}
										style={{ marginRight: "2rem" }}
										initialValue={dayjs()}
									>
										<DatePicker style={{ width: "100%" }} defaultValue={dayjs()} />
									</Form.Item>
								</Col>
								<Col span={10}>
									<Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
										<InputNumber
											step={0.01}
											min={0}
											defaultValue={0}
											style={{ width: "100%" }} />
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={10}>
									<Form.Item
										name="transaction"
										label="Transaction ID"
										rules={[{ required: true }]}
										style={{ marginRight: "2rem" }}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={10}>
									<Form.Item name="check" label="Check Number" rules={[{ required: true }]}>
										<Input style={{ width: "100%" }} />
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={10}>
									{(serviceInfoQuery.data && serviceInfoQuery.data.serviceType !== "Free") ? (
										<>
											<Paragraph>Current License Expiration Date</Paragraph>
											<Paragraph>{toDate(serviceInfoQuery.data.validTo)}</Paragraph>
										</>
									) : null}
								</Col>
								<Col span={10}>
									<Form.Item
										name="date"
										label="Set License Expiration Date"
										rules={[{ required: true }]}
									>
										<DatePicker style={{ width: "100%" }} />
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default ApprovalModal;
