import { useNavigate } from "react-router-dom";
import Title from "antd/es/typography/Title";
import { Tabs } from "antd";
import { PageRoutes } from "../../../utils/pageRoutes";
import { AdministrationTabsType } from "../models/tabs";

interface Props {
	activePanel: AdministrationTabsType;
}

export default function AdministrationTabs({ activePanel }: Props) {
	const navigate = useNavigate();
	const items: { key: AdministrationTabsType; label: React.ReactNode }[] = [
		{
			key: "subscriptions",
			label: "Subscriptions",
		},
		{
			key: "licenses",
			label: "Licenses",
		},
		{
			key: "payments",
			label: "Payments",
		},
		{
			key: "orders",
			label: "Orders",
		},
	];
	const handleTabChange = (key: AdministrationTabsType) => {
		switch (key) {
			case 'subscriptions':
				navigate(PageRoutes.subscriptions());
				break;
			case 'licenses':
				navigate(PageRoutes.licenses());
				break;
			case 'payments':
				navigate(PageRoutes.payments());
				break;
			case 'orders':
				navigate(PageRoutes.orders());
				break;
		}
	};
	return (
		<>
			<div>
				<Title className='page-title no-sep' level={1}>
					<span className="ficon ficon-setting" style={{ marginRight: "1rem" }}></span>
					Administration
				</Title>
			</div>
			<div>
				<Tabs items={items} defaultActiveKey={activePanel} onChange={(key) => handleTabChange(key as AdministrationTabsType)} />
			</div>
		</>
	);
}