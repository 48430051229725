import { useEffect, useState } from "react";
import { ContextMenuType } from "../models/api/shared";

export default function useContextMenu() {
	const [contextMenu, setContextMenu] = useState<ContextMenuType>({
		visible: false,
		x: 0,
		y: 0,
		text: "",
	});

	const checkForSelect = (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		setTimeout(() => {
			const selectedText = window.getSelection()?.toString().trim();
			if (selectedText) {
				setContextMenu({
					visible: true,
					x: event.nativeEvent.offsetX,
					y: event.pageY - 105,
					text: selectedText,
				});
			}
		});
	};

	const clearSelect = () => {
		setContextMenu((prev) => ({ ...prev, visible: false }));
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (contextMenu.visible) {
				const target = event.target as Element;
				if (!target.closest(".ask-tava-response-wrap")) {
					window.getSelection()?.empty();
					setContextMenu((prev) => ({ ...prev, visible: false }));
				}
			}
		};

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [contextMenu]);

	return {
		contextMenu,
		checkForSelect,
		clearSelect,
		setContextMenu,
	};
}