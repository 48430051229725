import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Typography } from 'antd';
import parse from 'html-react-parser';

interface NoteContentModel {
	content: string;
	noteId: number;
	readingMore: boolean;
	onReadingToggle: (noteId: number) => void;
}

export default function NoteContent({ content, noteId, readingMore, onReadingToggle }: NoteContentModel) {
	const { Text } = Typography;
	const ref = useRef<HTMLParagraphElement>(null);
	const [isTruncated, setIsTruncated] = useState(false);

	const handleResize = () => {
		const { offsetHeight, scrollHeight } = ref.current || {};
		if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
			setIsTruncated(true);
		} else {
			setIsTruncated(false);
		}
	};

	useLayoutEffect(() => {
		handleResize();
	}, [content, readingMore]);


	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleOnReadingMore = () => {
		onReadingToggle(noteId)
	}

	return (
		<div>
			<Text strong ref={ref} className={`${(!readingMore ? 'note-text-expandable' : '')} `}>
				{parse(content)}
			</Text>
			<div className="text-right pt-10 pb-05">
				{isTruncated && !readingMore ? (
					<span className="force-link" onClick={handleOnReadingMore}>Read More</span>
				) : (!isTruncated && readingMore) ? (
						<span className="force-link" onClick={handleOnReadingMore}>Read Less</span>
				) : null}
			</div>
		</div>
	);

};