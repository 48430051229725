import { Alert, Button, Spin } from "antd";
import Modal from "antd/es/modal/Modal";
import { useState } from "react";
import { errorHandler, toCurrency, toDate, toDateTime, toStorage } from "../../utils/globals";
import { useQuery } from "@tanstack/react-query";
import ApiRequests from "../../utils/api-requests";
import { IPopupModel } from "./models/orders";
import OrdersTabs from "./components/Tabs";
interface Props {
	model: OrdersPopModel
	onClose: () => void;
}


export interface OrdersPopModel {
	orderId: number;
	paymentId?: number;
}

function formatData(data: IPopupModel) {
	var totalStorage = 0, totalQuestions = 0, hasTasks = "";

	data.orderDateText = toDateTime(data.orderDate);
	data.orderConditionInitialDateText = toDate(data.orderDate);
	if (data.conditions) {
		data.orderConditionDateAsText = toDate(data.conditions.startDate);
		data.orderConditionInstantPaymentAsText = toCurrency(data.conditions.instantPayment);
	}

	data.priceAsText = toCurrency(data.price);

	data.items.forEach(x => {
		totalStorage += x.storage;
		totalQuestions += x.questions;

		x.priceAsText = toCurrency(x.price);
		x.storageAsText = toStorage(x.storage);
		x.type = x.isUpgrade ? "Upgrade" : data.serviceType;
	});

	data.total = {
		storage: toStorage(totalStorage),
		questions: totalQuestions,
		price: data.priceAsText,
		tasks: hasTasks,
	}

	data.payments.forEach(x => {
		x.visible = true;
		x.paymentDateAsText = toDateTime(x.date);
		x.ammountAsText = toCurrency(x.amount);
	});

	return data;
}

export default function Orders({
	model,
	onClose,
}: Props) {
	const [loading, setLoading] = useState(false);


	const OrdersData = useQuery({
		queryKey: ['purchase-catalogue'],
		queryFn: () => ApiRequests.Orders.read(model.orderId),
		select: (data) => {
			let newData: IPopupModel = formatData(data)

			return newData;
		},
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
	});

	return (

		<>
			<Modal
				open={true}
				onCancel={onClose}
				maskClosable={false}
				destroyOnClose
				width={800}
				onOk={onClose}
				footer={[
					<Button key="ok" type="primary" onClick={onClose}>
						OK
					</Button>,
				]}
				title={`Purchase Order Details`}

			>
				{OrdersData.error ? (
					<Alert
						message="Error"
						description={errorHandler(OrdersData.error.message)}
						type="error"
						showIcon
					/>
				) : (
					<Spin className='full-page-spinner' spinning={OrdersData.isFetching || loading}>
						{!OrdersData.isFetching && OrdersData.data ? (
							<OrdersTabs activePanel={model.paymentId ? 'payments' : 'details'} data={OrdersData.data} paymentId={model.paymentId}></OrdersTabs>
						) :
							null
						}
					</Spin>
				)}

			</Modal >

		</>
	);
}