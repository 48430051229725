import { Table as AntTable, TableProps } from "antd";

const Table = <T extends object>(props: TableProps<T>) => {
    return <AntTable {...props}
        sortDirections={["ascend", "descend"]}
        showSorterTooltip={{ title: "Click to change sort type" }}
    />;
};

export default Table;
