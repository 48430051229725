import { Typography } from "antd";
import RelatedImages from "../../components/discovery/related-images";
import NoteContent from "./NoteContent";
import { ProjectNoteComponentListModel } from "./ProjectNotes";

interface ContentSourceGalleryModel {
	note: ProjectNoteComponentListModel;
	handleUpdateReading: (id: number) => void;
}

export default function ContentSourceGallery({ note, handleUpdateReading }: ContentSourceGalleryModel): JSX.Element {

	return (
		<>
			{note.gallery.images.length > 0 ?
				<div>
					<RelatedImages data={note.gallery.images}></RelatedImages>
				</div> : null
			}

			<NoteContent
				content={note.text}
				noteId={note.id}
				readingMore={note.readingMore ? true : false}
				onReadingToggle={() => { handleUpdateReading(note.id) }}
			/>

			{note.sourceUrl ?
				<div className="pb-10">
					<span className="txt-small">Source url: </span>
					<Typography.Link className="txt-small" onClick={() => window.open(`${note.sourceUrl}`, "_blank")}>{note.sourceUrl}</Typography.Link>
				</div> : null
			}

		</>
	);
}