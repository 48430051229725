import { useState, FormEvent } from "react";
import CommentMention from "./CommentMention";
import { Button } from "antd";

interface CommentFormProps {
	handleSubmit: (text: string) => void;
	submitLabel: string;
	hasCancelButton?: boolean;
	hasClearButton?: boolean;
	handleCancel?: () => void;
	initialText?: string;
}

const CommentForm: React.FC<CommentFormProps> = ({
	handleSubmit,
	submitLabel,
	hasCancelButton = false,
	hasClearButton = false,
	handleCancel,
	initialText = "",
}) => {
	const [text, setText] = useState(initialText);
	const isTextareaDisabled = text.length === 0;

	const onSubmit = (event?: FormEvent) => {
		if (event) event.preventDefault();
		handleSubmit(text);
		setText("");
	};

	const onChange = (text: string) => {
		setText(text);
	};

	return (
		<form onSubmit={onSubmit}>
			<CommentMention
				text={text}
				onChangeText={onChange}
				fireSubmitAfterShiftPlusEnter={onSubmit}
			/>
			<div className="f-box mt-05">
				<span className="text-light-gray txt-small hbox">
					Tip: Press Shift + Enter to post.
				</span>
				<div className='hbox endbox topbox'>
					<Button htmlType="submit" disabled={isTextareaDisabled}>
						{submitLabel}
					</Button>
					{hasClearButton && !isTextareaDisabled && (
						<Button className={"comment-action"} onClick={() => setText("")}>
							Clear
						</Button>
					)}
					{hasCancelButton && (
						<Button className={"comment-action"} onClick={handleCancel}>
							Cancel
						</Button>
					)}
				</div>
			</div>
		</form>
	);
};

export default CommentForm;