import { Drawer, List } from "antd";
import { UseQueryResult } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { PageRoutes } from "../../../../../utils/pageRoutes";
import { toDate } from "../../../../../utils/globals";
import { ProjectsGridWrapperModel, SharedProjectsGridWrapperModel } from "../../../../../models/api/projects/projects";

interface Props {
	query: UseQueryResult<ProjectsGridWrapperModel | SharedProjectsGridWrapperModel>;
	openRecoverModal: (id: number) => void;
	openDeleteModal: (id: number) => void;
	drawerVisible: boolean;
	setDrawerVisible: (value: boolean) => void;
	setSelectedRecordId: (value: number | null) => void;
	selectedRecordId: number | null;
}

export default function ArchivedList({ query, openRecoverModal, openDeleteModal, drawerVisible, setDrawerVisible, setSelectedRecordId, selectedRecordId }: Props) {

	return (

		<div>
			<List loading={query.isLoading || query.isFetching}>
				{query.data?.records && query.data.records.length > 0 ? (
					query.data?.records.map((project) => (
						<List.Item className="list-item-row" key={project.id}>
							<div className="list-item-col">
								<Link to={PageRoutes.goToProject(project.id)}>
									<span className="force-link">{project.name}</span>
								</Link>
								<div>{toDate(project.modifiedAt)}</div>
							</div>
							<div className="actions-wrap">
								<span
									onClick={() => {
										setDrawerVisible(true);
										setSelectedRecordId(project.id);
									}}
									className="ficon ficon-more-vertical txt-large link text-gray"
								></span>
							</div>
						</List.Item>
					))
				) : (
					<div style={{ textAlign: "center", padding: "2rem" }}>
						No data
					</div>
				)}
			</List>
			{selectedRecordId &&
				<Drawer 
					open={drawerVisible} 
					onClose={() => setDrawerVisible(false)} 
					placement="bottom" 
					className="mobile-context-wrap"
				>
					<Link className="link" to={PageRoutes.goToProjectDocs(selectedRecordId)}>						
						<span className="ficon ficon-data"></span>Documents						
					</Link>
					<div className="link" onClick={() => openRecoverModal(selectedRecordId)} style={{ cursor: "pointer" }}>
						<span className="ficon ficon-revert"></span>Recover
					</div>
					<div className="link" onClick={() => openDeleteModal(selectedRecordId)}>
						<span className="ficon ficon-delete"></span>Delete
					</div>

				</Drawer>
			}
		</div>
	);
}
