import { useQuery } from "@tanstack/react-query";
import ApiRequests from "../../../../utils/api-requests";
import { ProjectSharingViewModel } from "../../../../models/api/sharings";
import { User } from "../../models/sharings";

const putOwnerFirst = (users: User[]): User[] => {
	const owner = users.find(user => user.accessMode === "Owner");
	const nonOwners = users.filter(user => user.accessMode !== "Owner");
	return owner ? [owner, ...nonOwners] : nonOwners;
}

function mergeQueries(actual: ProjectSharingViewModel[], invitations: ProjectSharingViewModel[]): User[] {

	const formattedUsers = [
		...actual.map(user => ({ ...user, isActual: true })),
		...invitations.map(user => ({ ...user, isActual: false }))
	];

	formattedUsers.sort((a, b) => a.name.localeCompare(b.name));

	return putOwnerFirst(formattedUsers);
}

const fetchActualAndInvitations = async (prId: number) => {
	const actualUsers = await ApiRequests.Project.Sharings.Actual.list(prId);
	const invitedUsers = await ApiRequests.Project.Sharings.Invitations.list(prId);
	return mergeQueries(actualUsers, invitedUsers);
};

const useFetchQueries = (prId: number) => {
	return useQuery<User[]>({
		queryKey: ['actualAndInvitedUsers', prId],
		queryFn: () => fetchActualAndInvitations(prId),
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
	});
};

export default useFetchQueries;
