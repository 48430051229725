import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";

interface AddImageUrlModalProps {
    visible: boolean;
    onCancel: () => void;
    onAdd: (url: string) => void;
}

export default function AddImageUrlModal({ visible, onCancel, onAdd }: AddImageUrlModalProps) {
    const [form] = Form.useForm();
    const [imageUrlInput, setImageUrlInput] = useState("");

    const handleOk = async () => {
        try {
            // Validate the form
            await form.validateFields();

            // If validation passes, proceed
            if (imageUrlInput) {
                onAdd(imageUrlInput);
                setImageUrlInput("");
                form.resetFields();
                onCancel();
            }
        } catch (error) {
            // Validation will automatically show errors
            console.log('Validation failed:', error);
        }
    };

    return (
        <Modal
            title="Add Image by URL"
            open={visible}
            onOk={handleOk}
            onCancel={onCancel}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="imageUrl"
                    label="Image URL"
                    rules={[
                        { required: true, message: 'Please enter an image URL' },
                        {
                            type: 'url',
                            message: 'Please enter a valid URL',
                            warningOnly: false,
                            validateTrigger: 'onChange'
                        }
                    ]}
                >
                    <Input
                        value={imageUrlInput}
                        onChange={(e) => setImageUrlInput(e.target.value)}
                        placeholder="Enter the image URL"
                        type="url"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}