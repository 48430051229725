import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";
import axios, { AxiosRequestConfig } from 'axios';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { OpenAiRequestModel } from "../models/api/open-ai";

type axiosRequestMethods = "GET" | "POST" | "DELETE" | "PUT";

function getAccount() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    return account;
}

async function acquireToken() {
    try {
        return await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: getAccount(),
        });
    } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
            // fallback to interactive method
            msalInstance.acquireTokenRedirect(loginRequest);
        }
        console.error("Non-interactive error occurred:", e);
        throw e;
    }
}

export async function callAxios(method: axiosRequestMethods, endpoint: string, data: any | null = null) {
    try {
        const response = await acquireToken();

        let options: AxiosRequestConfig = {
            method: method,
            headers: {
                Authorization: `Bearer ${response.accessToken}`,
                'Content-Type': 'application/json',
            },
            data: data
        };
        if (data && options.headers) options.headers['Content-Type'] = 'application/json';

        const axiosResponse = await axios(endpoint, options);
        return axiosResponse.data;
    } catch (err) {
        return Promise.reject(err);
    }
}

export async function CallOpenAiStream(method: axiosRequestMethods, endpoint: string, model: OpenAiRequestModel) {
    try {
        const response = await acquireToken();

        const fetchResponse = await fetch(endpoint, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${response.accessToken}`,
            },
            body: JSON.stringify(model),
        })

        if (!fetchResponse.body) {
            throw new Error("ReadableStream not supported in this browser.")
        }

        return fetchResponse.body.getReader();
    } catch (e) {
        return Promise.reject(e);
    }
}

export async function callTestBlob(method: axiosRequestMethods, endpoint: string, data: any | null = null) {

        const response = await acquireToken();

   return fetch(endpoint, {
       method: method,
       //cache: 'only-if-cached',
       headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${response.accessToken}`,
        },
    })


}