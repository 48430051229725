import { Link } from "react-router-dom";
import { ProjectsGridResultModel, ProjectsGridSortColumns } from "../../../../../models/api/projects/projects";
import { PageRoutes } from "../../../../../utils/pageRoutes";
import { toDate } from "../../../../../utils/globals";
import DocumentsButton from "../../../components/ActionButtons/DocumentsButton";
import RecoverButton from "../../../components/ActionButtons/RecoverButton";
import PermanentDeleteButton from "../../../components/ActionButtons/PermanentDelete";
import { SortInfo, SortTypes } from "../../../../../models/api/shared";
import { ColumnsType } from "antd/es/table/interface";

interface Props {
	openRecoverModal: (id: number) => void;
	openPermanentDeleteModal: (id: number) => void;
	sortInfo: SortInfo<ProjectsGridSortColumns>;
}

const TrashedGridTableColumns = ({ openRecoverModal, openPermanentDeleteModal, sortInfo }: Props): ColumnsType<ProjectsGridResultModel> => {
	return [
		{
			title: "Project Name",
			dataIndex: "name",
			key: "Name",
			width: 1000,
			sorter: true,
			sortOrder: sortInfo.sortColumn === "Name" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' : 'descend') : undefined,
			render: (text: string, record: ProjectsGridResultModel) => (
				<Link to={PageRoutes.goToProject(record.id)} style={{ textDecoration: "none" }}>
					{text}
				</Link>
			),
		},
		{
			title: "Modified At",
			dataIndex: "modifiedAt",
			key: "ModifiedAt",
			sorter: true,
			width: 200,
			sortOrder: sortInfo.sortColumn === "ModifiedAt" ? (sortInfo.sortType === SortTypes.Asc ? 'ascend' : 'descend') : undefined,
			render: (date: Date | string) => toDate(date),
		},
		{
			width: 0,
			key: "actionColumn",
			render: (record: ProjectsGridResultModel) => (
				<div style={{ display: "flex", flexDirection: "row" }}>
					<DocumentsButton recordId={record.id} />
					<RecoverButton recordId={record.id} openRecoverModal={openRecoverModal} />
					<PermanentDeleteButton recordId={record.id} openPermanentDeleteModal={openPermanentDeleteModal} />
				</div>
			),
		},
	];
};

export default TrashedGridTableColumns;
